import React from 'react';

export default function ExitRow({ highlight = false, side }) {
  const left = side === 'left' ? (highlight ? '' : '-50%') : '';
  const right = side === 'right' ? (highlight ? '' : '-50%') : '';
  return (
    <div
      style={{ writingMode: 'vertical-rl', left, right }}
      className={`${side}-exit-rows gr-z-60 gr-absolute ${
        highlight
          ? side === 'right'
            ? 'gr--right-9 sm:gr--right-18'
            : 'gr--left-9 sm:gr--left-18'
          : ''
      } gr-border gr-top-1 gr-py-1 gr-text-xxs sm:gr-text-xs gr-h-8 sm:gr-h-10 md:gr-h-12 gr-border-red-100 gr-text-red-100 gr-leading-none`}
    >
      EXIT
    </div>
  );
}
