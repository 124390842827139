import { UpsellInterfaces } from './CommonInterfaces';
import isEqual from 'lodash.isequal';

/**
 * For a list of upsell_option objects, returns the first element with elem.selected=true.
 *
 * If upsell_options is empty, or no element has selected=true, then the null object is returned.
 * @param upsell_options
 */
export function getSelectedCompartment(
  upsell_options: UpsellInterfaces.UpsellOption[]
) {
  for (const upsell_option of upsell_options) {
    if (upsell_option.selected) return upsell_option;
  }

  return null;
}

export function checkEquality(
  optionA: UpsellInterfaces.UpsellOption,
  optionB: UpsellInterfaces.UpsellOption
) {
  return isEqual(optionA, optionB);
}

/**
 * Returns true if we should show the upsell modal for this seat.
 *
 * @param selectedCompartment the selected compartment. Undefined if the compartment does not exist
 *  in upsellOptionsMap[currentSeatmap] (i.e., a malformed upsellOption parameter was passed in for a given segment.
 * @param otherCompartment The current selected compartment that we are upselling from.
 */
export function doOpen(
  selectedCompartment: UpsellInterfaces.UpsellOption | undefined,
  otherCompartment: UpsellInterfaces.UpsellOption
) {
  return (
    selectedCompartment && !checkEquality(selectedCompartment, otherCompartment)
  );
}

/**
 * Returns true if the given compartment option is different from the initially selected compartment.
 * @param option
 */
export function didUpsell(option: UpsellInterfaces.UpsellOption | null) {
  return !!option && option.hasOwnProperty('selected') && !option.selected;
}

export function buildUpsellCallbackElement(
  upsoldCabin: UpsellInterfaces.UpsellOption,
  initialCabin: UpsellInterfaces.UpsellOption | null
): UpsellInterfaces.UpsellTicketCallbackParameterElement {
  let initial_cabin = null;
  if (initialCabin) {
    initial_cabin = {
      upsell_parameters: initialCabin.upsell_parameters,
      price: initialCabin.pricing
    };
  }
  const upsold_cabin = {
    upsell_parameters: upsoldCabin.upsell_parameters,
    price: upsoldCabin.pricing
  };

  return {
    initial_cabin,
    upsold_cabin
  };
}
