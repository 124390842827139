import React from 'react';
import { ReactComponent as ThreeDots } from '../../svg/three-dots.svg';

function DefaultLoader(props) {
  return (
    <>
      <div
        id="default-loader"
        className="gr-flex gr-flex-col gr-justify-center gr-w-full gr-items-center gr-relative gr-mb-auto"
        style={{
          height: '100%',
          zIndex: 101,
          opacity: props.modal ? 1 : 0.5,
          backgroundColor: '#e8f2ff'
        }}
      >
        <ThreeDots
          key="1"
          className="gr-m-3 gr-h-4"
          style={{ fill: 'var(--color-gordian-primary)', position: 'relative' }}
          id="gordian-intro-spinner"
        />
      </div>
    </>
  );
}

export default DefaultLoader;
