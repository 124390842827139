import React from 'react';

type ISeatmapContainer = {
  fontFamily: string;
  modal: boolean;
  modalStyle: string;
  children: React.ReactNode;
  brand?: string;
};

const GenericSeatmapContainer = ({
  fontFamily,
  modal,
  modalStyle,
  children
}: ISeatmapContainer) => {
  return (
    <div
      style={{ fontFamily: fontFamily }}
      role="dialog"
      tabIndex={-1}
      aria-label={modal ? 'Seat Selection Dialog' : 'Seat Selection'}
      id="gordian-seatmap-dialog"
      className={`gordian-seatmap gr-text-primary-text ${
        modal
          ? `${modalStyle} gr-w-full sm:gr-w-11/12`
          : 'gordian-embedded gr-w-full'
      }`}
    >
      {children}
    </div>
  );
};

const UberSeatmapContainer = ({
  fontFamily,
  modal,
  modalStyle,
  children
}: ISeatmapContainer) => {
  return (
    <div
      style={{ fontFamily: fontFamily }}
      role="dialog"
      tabIndex={-1}
      aria-label={modal ? 'Seat Selection Dialog' : 'Seat Selection'}
      id="gordian-seatmap-dialog"
      className={`gordian-seatmap gr-text-primary-text ${
        modal
          ? `${modalStyle} gr-w-full sm:gr-w-11/12`
          : 'gordian-embedded gr-w-full'
      }`}
    >
      {children}
    </div>
  );
};

const SeatmapContainer = ({
  fontFamily,
  modal,
  modalStyle,
  children,
  brand
}: ISeatmapContainer) => {
  switch (brand) {
    case 'uber':
      return (
        <UberSeatmapContainer
          fontFamily={fontFamily}
          modal={modal}
          modalStyle={modalStyle}
        >
          {children}
        </UberSeatmapContainer>
      );
    default:
      return (
        <GenericSeatmapContainer
          fontFamily={fontFamily}
          modal={modal}
          modalStyle={modalStyle}
        >
          {children}
        </GenericSeatmapContainer>
      );
  }
};

export default SeatmapContainer;
