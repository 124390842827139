import React, { Component } from 'react';
import { connect } from 'react-redux';

import {
  nextSeatmap,
  showModal,
  updateSelectedSeat,
  setCurrentPassenger,
  clearBasket
} from '../../redux/actions';
import Button from '../shared/Button';

export class SkipFlight extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false
    };
  }

  handleClick = async () => {
    const { clearBasket, updateSelectedSeat } = this.props;
    updateSelectedSeat(null);
    clearBasket();
  };

  atleastOneSeatSelected = () => {
    const { basket } = this.props;
    return basket.seats.length > 0;
  };

  isFirstSeatmap = () => {
    const { itinerary } = this.props;
    return itinerary.currentSeatmap === itinerary.flights[0]?.segment_id;
  };

  render() {
    return (
      <div className="gr-w-40 sm:gr-w-56">
        <Button
          id="skip-button"
          primary={false}
          hovergrBg="blue-primary"
          hoverTextColor="gr-text-white"
          handleClick={() => this.handleClick()}
          disabled={this.props.basketChanging}
          style={{
            flexDirection: 'row',
            display: 'flex',
            justifyContent: 'space-around',
            padding: 4
          }}
        >
          <span style={{ margin: 'auto' }}>
            {this.props.basketChanging ? 'Please Wait' : 'Skip to Checkout'}
          </span>
        </Button>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  basket: state.basket,
  itinerary: state.itinerary,
  passengers: state.passengers.listOfPassenger,
  basketChanging: state.session.basketChanging
});

const mapDispatchToProps = {
  nextSeatmap,
  updateSelectedSeat,
  setCurrentPassenger,
  showModal,
  clearBasket
};

export default connect(mapStateToProps, mapDispatchToProps)(SkipFlight);
