import React from 'react';
import { formatPrice } from '../../utils/formatPrice';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { useDispatch } from 'react-redux';
import { setCurrentBaggageJourney } from '../../redux/actions';
import { parseAcceptedBags } from '../../utils/parseAcceptedBags';
import { Basket, Journey, Theme } from './CommonTypes';
import { Translate } from 'react-localize-redux';

const PriceTotal = ({ total }: { total: string }) => {
  return (
    <div
      className="gr-block gr-h-10 gr-flex gr-flex-col"
      id="footer-total-price"
    >
      <div className="gr-text-black">{total}</div>
      <div className="gr-text-gray-900 gr-mt-auto">
        <Translate id={'total'}>Total price</Translate>
      </div>
    </div>
  );
};

function BaggageFooter({
  basket,
  journeys,
  activeJourney,
  onNextClick,
  setCurrentBaggageJourney,
  isMobile,
  currency,
  theme,
}: {
  basket: Basket;
  journeys: Array<Journey>;
  activeJourney: Journey;
  onNextClick: () => void;
  setCurrentBaggageJourney: any;
  isMobile: boolean;
  currency: string;
  theme: Theme;
}) {
  const dispatch: Dispatch = useDispatch();
  const totalPrice = Object.values(basket.bags)
    .flatMap((passengerSelections) => Object.values(passengerSelections))
    .map((bag) => bag.totalPrice)
    .reduce(
      (totalPrice, bagPrice) => {
        return {
          amount: (totalPrice?.amount ?? 0) + (bagPrice?.amount ?? 0),
          currency: bagPrice?.currency ?? '',
          decimal_places: bagPrice?.decimal_places ?? 0,
        };
      },
      { amount: 0, currency: currency ?? 'USD', decimal_places: 2 },
    );

  const total = formatPrice(
    totalPrice?.amount ?? 0,
    totalPrice?.currency ?? '',
    totalPrice?.decimal_places,
  );

  const activeJourneyIndex = journeys.findIndex(
    (j) => j.from === activeJourney.from && j.to === activeJourney.to,
  );

  const onPreviousClick = () => {
    dispatch(setCurrentBaggageJourney(journeys[activeJourneyIndex - 1]));
  };

  return (
    <>
      <hr
        className="gr-bg-gray-400 gr-border-gray-200 gr-border-0 gr-ml-0 gr-mr-0"
        style={{ minHeight: '1px', marginBlockStart: '0' }}
      />
      <div className="gr-mt-4 gr-mb-4" id="bag-footer">
        <div
          className={`gr-flex gr-flex-row gr-gap-2 ${
            isMobile ? 'gr-mx-4' : 'gr-mx-8'
          } gr-text-base`}
        >
          <PriceTotal total={total}></PriceTotal>
          <div className={'gr-ml-auto'}>
            {!isMobile && activeJourneyIndex > 0 ? (
              <button
                className={`gr-cursor-pointer gr-font-medium gr-text-center gr-h-12 ${
                  isMobile ? 'gr-w-48' : 'gr-min-w-200'
                } gr-text-base gr-border-0 gr-mr-4`}
                style={{
                  background: theme?.bags?.secondaryButtonColor ?? '#8d8d8d',
                  color: theme?.bags?.secondaryButtonText ?? '#FFFFFF',
                  borderRadius: theme?.bags?.secondaryButtonRounding ?? '8px',
                  fontFamily: 'inherit',
                }}
                onClick={onPreviousClick}
              >
                <Translate id={'previous_flight'}>Previous flight</Translate>
              </button>
            ) : null}
            <button
              className={`gr-next-btn gr-cursor-pointer gr-font-medium gr-text-center gr-h-12 ${
                isMobile ? 'gr-w-48' : 'gr-min-w-200'
              } gr-text-base gr-border-0`}
              onClick={onNextClick}
              style={{
                background: theme?.bags?.primaryButtonColor ?? '#4CA7DE',
                color: theme?.bags?.primaryButtonText ?? '#FFFFFF',
                borderRadius: theme?.bags?.primaryButtonRounding ?? '8px',
                fontFamily: 'inherit',
              }}
            >
              {activeJourney.isLastJourney ? (
                <Translate id={'confirm'}>Confirm</Translate>
              ) : (
                <Translate id={'next_flight'}>Next flight</Translate>
              )}
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

const mapStateToProps = (state: any) => ({
  activeJourney: state.itinerary.currentBaggageJourney,
  journeys: state.itinerary.baggageJourneys,
  basket: parseAcceptedBags(state.basket, state.baggageJSON.data),
  currency: state.products.currency,
  theme: state.session.theme,
});

export default connect(mapStateToProps, {
  setCurrentBaggageJourney,
})(BaggageFooter);
