import React, { Component } from 'react';
import MediaQuery from 'react-responsive';

interface FooterTextInterface {
  variants: { [key: string]: string };
  relativeWidthRatio: number;
}

export class FooterText extends Component<FooterTextInterface> {
  doShow(variant: string) {
    return variant !== 'default';
  }

  render() {
    const { convenience_fee_display_variant = 'default' } = this.props.variants;
    const { relativeWidthRatio } = this.props;

    const desktopText =
      "Conveniently select your seat now for a fee or choose on the airline's website later";

    if (!this.doShow(convenience_fee_display_variant)) {
      return null;
    }

    return (
      <MediaQuery maxWidth={1119 / relativeWidthRatio}>
        {(isReducedSize) => (
          <div className="gr-footer-text gr-bg-white gr-w-3/6' gr-h-full gr-relative">
            <div
              className={`gr-text-xs gr-w-full gr-h-full gr-text-center ${
                isReducedSize ? 'gr-p-3' : 'gr-pl-6 gr-pr-12 sm:gr-pr-4 gr-py-6'
              }`}
            >
              {desktopText}
            </div>
          </div>
        )}
      </MediaQuery>
    );
  }
}
