import React from 'react';
import { ReactComponent as DimensionsIcon } from '../../svg/dimensions-icon.svg';
import { BagDetails } from './CommonTypes';
import { Translate } from 'react-localize-redux';

export function DimensionsText({
  bagDetails,
  className,
}: {
  bagDetails: BagDetails;
  className: string;
}) {
  return (
    <p className={className} style={{ marginTop: '-10px' }}>
      <DimensionsIcon className="gr-pr-1" /> <Translate id={'l'}>l</Translate> +{' '}
      <Translate id={'w'}>w</Translate> + <Translate id={'h'}>h</Translate>
      {' < ' + bagDetails.total_dimensions + ' ' + bagDetails.dimension_unit}
    </p>
  );
}
