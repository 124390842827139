import React from 'react';
import { connect } from 'react-redux';
import { Theme } from './CommonTypes';

function CheckedBag({ theme }: { theme?: Theme }) {
  const bagColor = theme?.bags?.checkedBagColor ?? '#76B761';
  const linearGradient1 = theme?.bags?.checkedBagLinearGradient1 ?? '#9AC96C';
  const linearGradient2 = theme?.bags?.checkedBagLinearGradient2 ?? '#9DEB6C';
  const handleAndWheels = theme?.bags?.checkedBagHandleAndWheels ?? '#3C6D2C';

  return (
    <svg viewBox="0 0 96 138" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect
        x="29.25"
        y="132.25"
        width="5.5"
        height="5.5"
        rx="1.75"
        fill={handleAndWheels}
        stroke="#235313"
        strokeWidth="0.5"
      />
      <rect
        x="63.25"
        y="132.25"
        width="5.5"
        height="5.5"
        rx="1.75"
        fill={handleAndWheels}
        stroke="#235313"
        strokeWidth="0.5"
      />
      <rect y="10" width="96" height="124" rx="9" fill={bagColor} />
      <rect
        x="12"
        y="24"
        width="6"
        height="96"
        rx="3"
        fill="url(#paint0_linear_64_823)"
      />
      <rect
        x="29"
        y="24"
        width="6"
        height="96"
        rx="3"
        fill="url(#paint1_linear_64_823)"
      />
      <rect
        x="46"
        y="24"
        width="6"
        height="96"
        rx="3"
        fill="url(#paint2_linear_64_823)"
      />
      <rect
        x="63"
        y="24"
        width="6"
        height="96"
        rx="3"
        fill="url(#paint3_linear_64_823)"
      />
      <rect
        x="80"
        y="24"
        width="6"
        height="96"
        rx="3"
        fill="url(#paint4_linear_64_823)"
      />
      <path
        d="M32 4C32 1.79086 33.7909 0 36 0V10H32V4Z"
        fill={handleAndWheels}
      />
      <path
        d="M59 0C61.2091 0 63 1.79086 63 4V10H59V0Z"
        fill={handleAndWheels}
      />
      <rect x="34" width="27" height="4" rx="2" fill={handleAndWheels} />
      <defs>
        <linearGradient
          id="paint0_linear_64_823"
          x1="15"
          y1="24"
          x2="15"
          y2="120"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={linearGradient1} />
          <stop offset="1" stopColor={linearGradient2} stopOpacity="0.08" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_64_823"
          x1="32"
          y1="24"
          x2="32"
          y2="120"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={linearGradient1} />
          <stop offset="1" stopColor={linearGradient2} stopOpacity="0.08" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_64_823"
          x1="49"
          y1="24"
          x2="49"
          y2="120"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={linearGradient1} />
          <stop offset="1" stopColor={linearGradient2} stopOpacity="0.08" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_64_823"
          x1="66"
          y1="24"
          x2="66"
          y2="120"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={linearGradient1} />
          <stop offset="1" stopColor={linearGradient2} stopOpacity="0.08" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_64_823"
          x1="83"
          y1="24"
          x2="83"
          y2="120"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={linearGradient1} />
          <stop offset="1" stopColor={linearGradient2} stopOpacity="0.08" />
        </linearGradient>
      </defs>
    </svg>
  );
}

const mapStateToProps = (state: any) => ({
  theme: state.session.theme,
});

export default connect(mapStateToProps)(CheckedBag);
