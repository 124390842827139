export const mapTheme = (variables) => {
  return {
    // colors
    '--color-gordian-primary': variables.primary || '',
    '--color-gordian-secondary': variables.secondary || '',
    '--color-gordian-text-primary': variables.textPrimary || '',
    '--color-gordian-button-text': variables.buttonText || '',
    '--color-gordian-text-secondary': variables.textSecondary || '',
    '--color-gordian-link': variables.link || '',
    '--color-gordian-title': variables.title || '',
    '--color-gordian-alert': variables.alert || '',
    '--color-gordian-gray_10': variables.gray_10 || '',
    '--color-gordian-gray_15': variables.gray_15 || '',
    '--color-gordian-gray_20': variables.gray_20 || '',
    '--color-gordian-gray_50': variables.gray_50 || '',
    '--color-gordian-gray_70': variables.gray_70 || '',
    '--color-gordian-gray_100': variables.gray_100 || '',
    // elements
    '--action-button-border-radius': variables.actionButtonBorderRadius || '', // this applies to <Button /> React elements
    '--button-border-radius': variables.buttonBorderRadius || '', // this applies to _all_ <button> elements
    '--detail-container-desktop-border':
      variables.detailContainerDesktopBorder || '',
    '--detail-container-desktop-border-radius':
      variables.detailContainerDesktopBorderRadius || '',
    '--detail-container-mobile-border':
      variables.detailContainerMobileBorder || '',
    '--detail-container-mobile-border-radius':
      variables.detailContainerMobileBorderRadius || '',
    '--legend-border': variables.legendBorder || '',
    '--legend-border-radius': variables.legendBorderRadius || '',
  };
};

export const applyTheme = (theme) => {
  const themeObject = mapTheme(theme);
  if (!themeObject) return;

  const root = document.documentElement;

  Object.keys(themeObject).forEach((property) => {
    if (property === 'name') {
      return;
    }
    root.style.setProperty(property, themeObject[property]);
  });
};

export const extend = (extending, newTheme) => {
  const seats = { ...extending.seats, ...newTheme.seats };
  const bags = { ...extending.bags, ...newTheme.bags };
  return { ...extending, ...newTheme, seats, bags };
};
