import { renderToStaticMarkup } from 'react-dom/server';

import enUS from './en-US.json';
import frFR from './fr-FR.json';
import esES from './es-ES.json';
import viVN from './vi-VN.json';
import thTH from './th-TH.json';
import trTR from './tr-TR.json';
import svSE from './sv-SE.json';
import ptPT from './pt-PT.json';
import idID from './id-ID.json';
import noNB from './no-NB.json';
import nlNL from './nl-NL.json';
import elGR from './el-GR.json';
import fiFI from './fi-FI.json';
import jaJP from './ja-JP.json';
import deDE from './de-DE.json';
import daDK from './da-DK.json';
import itIT from './it-IT.json';
import koKR from './ko-KR.json';
import plPL from './pl-PL.json';
import zhHK from './zh-HK.json';
import roRO from './ro-RO.json';
import etEE from './et-EE.json';
import ltLT from './lt-LT.json';
import lvLV from './lv-LV.json';
import arSA from './ar-SA.json';

interface Translation {
  seat: string;
  unavailable: string;
  operated_by: string;
  select_seats: string;
  next_flight: string;
  total: string;
  select_seat: string;
  remove_seat: string;
  seat_legend: string;
  confirming_seats: string;
  close: string;
  seat_selection: string;
  card_select_your_seats: string;
  seats_at_check_in: string;
  exit_regulations_title: string;
  exit_regulations_modal_title: string;
  exit_regulations_criteria: string;
  exit_regulations_accept: string;
  exit_regulations_decline: string;
  exit_regulations_reason_1: string;
  exit_regulations_reason_2: string;
  exit_regulations_reason_3: string;
  exit_regulations_reason_4: string;
  exit_regulations_reason_5: string;
  exit_regulations_reason_6: string;
  selecting: string;
  outbound: string;
  inbound: string;
  infants_next_to_adults: string;
  infants_validation: string;
  change_seats: string;
  infant_on_lap_header: string;
  infant_on_lap_content: string;
  request: string;
  yes: string;
  no_thanks: string;
  continue_selecting: string;
  selection_not_completed: string;
  previous_flight: string;
  roundtrip: string;
  from: string;
  up_to: string;
  l: string;
  w: string;
  h: string;
  remove: string;
  select: string;
  confirm: string;
  add: string;
}

interface LanguageConfiguration {
  name: string;
  code: string;
  translation: Translation;
}

const defaultLanguageCode: string = 'en-US';

const languageConfigurations: LanguageConfiguration[] = [
  { name: 'English', code: 'en-US', translation: enUS },
  { name: 'English-GB', code: 'en-GB', translation: enUS },
  { name: 'French', code: 'fr-FR', translation: frFR },
  { name: 'Spanish', code: 'es-ES', translation: esES },
  { name: 'Vietnamese', code: 'vi-VN', translation: viVN },
  { name: 'Thai', code: 'th-TH', translation: thTH },
  { name: 'Turkish', code: 'tr-TR', translation: trTR },
  { name: 'Swedish', code: 'sv-SE', translation: svSE },
  { name: 'Portuguese', code: 'pt-PT', translation: ptPT },
  { name: 'Indonesian', code: 'id-ID', translation: idID },
  { name: 'Norwegian', code: 'no-NB', translation: noNB },
  { name: 'Dutch', code: 'nl-NL', translation: nlNL },
  { name: 'Greek', code: 'el-GR', translation: elGR },
  { name: 'Finnish', code: 'fi-FI', translation: fiFI },
  { name: 'Japanese', code: 'ja-JP', translation: jaJP },
  { name: 'German', code: 'de-DE', translation: deDE },
  { name: 'Danish', code: 'da-DK', translation: daDK },
  { name: 'Italian', code: 'it-IT', translation: itIT },
  { name: 'Korean', code: 'ko-KR', translation: koKR },
  { name: 'Polish', code: 'pl-PL', translation: plPL },
  { name: 'Chinese', code: 'zh-HK', translation: zhHK },
  { name: 'Romanian', code: 'ro-RO', translation: roRO },
  { name: 'Estonian', code: 'et-EE', translation: etEE },
  { name: 'Lithuanian', code: 'lt-LT', translation: ltLT },
  { name: 'Latvian', code: 'lv-LV', translation: lvLV },
  { name: 'Arabic', code: 'ar-SA', translation: arSA },
];

const initializeLocalization = (
  initialize: (p: {}) => {},
  addTranslationForLanguage: (translation: Translation, code: string) => {}
) => {
  const languages = languageConfigurations.map(({ name, code }) => ({
    name,
    code,
  }));

  initialize({
    languages: languages,
    options: { renderToStaticMarkup, defaultLanguage: defaultLanguageCode },
  });
  languageConfigurations.forEach(({ code, translation }) => {
    addTranslationForLanguage(translation, code);
  });
};

export { languageConfigurations, defaultLanguageCode, initializeLocalization };
