import React, { useState } from 'react';
import { Transition } from 'react-transition-group';

import { Translate } from 'react-localize-redux';
function HopperLoader({ active }) {
  const [ready, setReady] = useState(false);

  const duration = 400;

  const defaultStyle = {
    transition: `opacity ${duration}ms ease-out`,
    opacity: 0,
  };

  const transitionStyles = {
    entering: { opacity: 1 },
    entered: { opacity: 1 },
    exiting: { opacity: 0 },
    exited: { opacity: 0 },
  };

  const REACT_APP_STATIC_URL = process.env
    ? process.env?.REACT_APP_STATIC_URL ?? 'https://static.gordiansoftware.com'
    : 'https://static.gordiansoftware.com';
  return (
    <div
      style={{
        opacity: 0.85,
        zIndex: 9999,
        visibility: active ? 'visible' : 'hidden',
      }}
      className="gr-flex gr-fixed"
    >
      <Transition in={active && ready} timeout={duration}>
        {(state) => (
          <div
            id="hopper-loader"
            key="hopper-loader-container"
            className="gr-flex gr-flex-col gr-justify-center gr-w-full gr-items-center gr-fixed"
            style={{
              top: 0,
              left: 0,
              height: '100%',
              backgroundColor: '#FB807D',
              ...defaultStyle,
              ...transitionStyles[state],
            }}
          >
            <img
              key="hopper-loader-bunny"
              onLoad={() => setReady(true)}
              src={`${REACT_APP_STATIC_URL}/images/HopperRunningBunny.gif`}
              width="40%"
              alt=""
            />
            <span
              key="hopper-loader-text"
              className="gr-text-primary gr-text-white"
            >
              <Translate id="confirming_seats" />
            </span>
          </div>
        )}
      </Transition>
    </div>
  );
}

export default HopperLoader;
