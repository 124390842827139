import { handleActions } from 'redux-actions';

export const seatDetails = handleActions(
  {
    UPDATE_SEAT_DETAILS_POSITION: (state, action) => ({
      ...state,
      seat_details_position: action.payload,
    }),
    SEAT_MAP_SCROLLED: (state, action) => ({
      ...state,
      bottom: action.payload,
    }),
  },
  {
    seat_details_position: { x: 0, y: 0 },
    bottom: false,
  },
);
