import React, { Component } from 'react';
import { Translate } from 'react-localize-redux';
import { connect } from 'react-redux';
import { incrBagForPassenger, decrBagForPassenger } from '../../redux/actions';
import { rtlLangs } from '../../translations/rtl-langs';

class BaggageQuantitySelector extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const {
      name = 'Bag',
      product_id,
      max_quantity = 1,
      passenger_id,
      basket = {},
      price,
      locale
    } = this.props;
    const { incrBagForPassenger, decrBagForPassenger } = this.props;
    const quantity = basket?.[passenger_id]?.[product_id] ?? 0;
    const { descriptionId, weight } = localizeBagName(name, locale);
    return (
      <div className="gr-flex gr-flex-row gr-w-full gr-justify-between">
        <div className="gr-flex gr-flex-col">
          <div
            className={`gr-flex gr-px-4 gr-font-bold ${
              rtlLangs.includes(locale) ? 'gr-text-right' : 'gr-text-left'
            } gr-w-full`}
          >
            {descriptionId ? (
              <Translate id={descriptionId} data={{ weight }} />
            ) : (
              name
            )}
          </div>
          <div className="gr-flex gr-px-4 gr-py-2 gr-text-secondary-text gr-w-full">
            {price}
          </div>
        </div>

        <div className="gr-flex gr-justify-center gr-items-center">
          <div className="gr-flex gr-px-2">
            {quantity > 0 ? (
              <button
                id={`decrement-${product_id}-${passenger_id}`}
                className="gr-rounded gr-cursor-pointer gr-text-2xl gr-h-10 gr-w-10 sm:gr-h-12 sm:gr-w-12 gr-font-bold gr-text-gray-800 gr-border-none"
                onClick={e => {
                  e.preventDefault();
                  decrBagForPassenger({
                    passenger_id,
                    product_id
                  });
                }}
              >
                -
              </button>
            ) : (
              <div className="gr-h-10 gr-w-10 sm:gr-h-12 sm:gr-w-12"> </div>
            )}
          </div>
          <div className="gr-flex">
            <input
              id={`quantity-${product_id}-${passenger_id}`}
              type="text"
              readOnly={true}
              className="gr-rounded gr-text-sm sm:gr-text-base gr-h-10 gr-w-10 sm:gr-h-12 sm:gr-w-12 gr-text-center gr-border-solid"
              value={Intl.NumberFormat(locale).format(quantity)}
            />
          </div>
          <div className="gr-flex gr-px-2">
            {quantity < max_quantity ? (
              <button
                id={`increment-${product_id}-${passenger_id}`}
                className="gr-cursor-pointer gr-rounded gr-text-2xl gr-h-10 gr-w-10 sm:gr-h-12 sm:gr-w-12 gr-bg-blue-primary gr-border-blue-primary gr-text-button-text gr-border-none"
                onClick={() => {
                  incrBagForPassenger({
                    passenger_id,
                    product_id,
                    max_quantity
                  });
                }}
              >
                +
              </button>
            ) : (
              <div className="gr-h-10 gr-w-10 sm:gr-h-12 sm:gr-w-12"> </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

const localizeBagName = (name, locale) => {
  const localizedName = {};
  localizedName.weight = Intl.NumberFormat(locale).format(
    name.match(/^\d*\.?\d*/)[0]
  );
  if (name.includes('kg Cabin Bag')) {
    localizedName.descriptionId = 'cabin_bag';
  } else if (name.includes('kg Checked Bag')) {
    localizedName.descriptionId = 'checked_bag';
  }
  return localizedName;
};

const mapStateToProps = state => ({
  basket: state.basket.bags,
  locale: state.session.locale
});
export default connect(mapStateToProps, {
  incrBagForPassenger,
  decrBagForPassenger
})(BaggageQuantitySelector);
