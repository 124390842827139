import React, { Component } from 'react';
import { connect } from 'react-redux';

import Header from '../Header';
import SeatmapFooter from './SeatmapFooter';
import { SeatingChoiceCard } from './SeatingChoiceCard';

import {
  addSeatingChoiceToBasket,
  removeSeatingChoiceFromBasket,
  nextPassenger,
} from '../../../redux/actions';

export class SeatingChoice extends Component {
  state = {
    clicked: false,
    productsBySegments: {},
  };

  filterProducts = (products) => {
    return products.filter((product) => product.display_name !== 'Exact Seat');
  };

  seatingChoiceOrderFunc = (product1, product2) => {
    const getProductHierarchy = (product) => {
      switch (product.display_name) {
        case 'Window Seat':
          return 1;
        case 'Aisle Seat':
          return 2;
        case 'Aisle Or Window Seat':
          return 3;
        case 'Together Seat':
          return 4;
        default:
          return 5;
      }
    };
    const productOneOrder = getProductHierarchy(product1);
    const productTwoOrder = getProductHierarchy(product2);

    return productOneOrder > productTwoOrder ? 1 : -1;
  };

  sortProducts = (products) => {
    return products.sort(this.seatingChoiceOrderFunc);
  };

  reformatProducts = () => {
    const productsBySegments = {};
    if (this.props.products?.products?.seating_choice) {
      Object.values(this.props.products?.products?.seating_choice).forEach(
        (product) => {
          if (!Object.keys(productsBySegments).includes(product.segment_id)) {
            productsBySegments[product.segment_id] = [];
          }
          productsBySegments[product.segment_id].push(product);
        },
      );
    }
    return productsBySegments;
  };

  getCurrentSegmentProducts = (productsBySegments) => {
    return this.props.currentSeatmap
      ? productsBySegments[this.props.currentSeatmap]
      : [];
  };

  handleCardClick = (
    selectedProduct,
    currentSeatmap,
    currentPassenger,
    selected,
  ) => {
    if (selected) {
      this.props.removeSeatingChoiceFromBasket({
        currentSeatmap,
        currentPassenger,
        selectedProduct,
      });
    } else {
      this.props.addSeatingChoiceToBasket({
        currentSeatmap,
        currentPassenger,
        selectedProduct,
      });
    }
  };

  generateSeatingChoiceCardUI = (products) => {
    const currentSeatmap = this.props.currentSeatmap;
    const currentPassenger = this.props.currentPassenger;
    const seatingChoiceBasket = this.props.basket.seatingChoice;

    const Cards = this.props.currentSeatmap ? (
      products.map((currentProduct) => {
        const isCardSelected =
          seatingChoiceBasket.filter((product) => {
            const { product_id, passenger_id, segment_id } = product;
            const { passenger_id: passenger_id_s } =
              this.props.currentPassenger;
            const { product_id: product_id_s, segment_id: segment_id_s } =
              currentProduct;
            return (
              product_id === product_id_s &&
              passenger_id === passenger_id_s &&
              segment_id === segment_id_s
            );
          }).length > 0;

        return (
          <SeatingChoiceCard
            cardClick={() => {
              this.handleCardClick(
                currentProduct,
                currentSeatmap,
                currentPassenger,
                isCardSelected,
              );
            }}
            selected={isCardSelected}
            key={currentProduct.product_id}
            product={currentProduct}
          />
        );
      })
    ) : (
      <></>
    );

    return Cards;
  };

  generateSeatingChoiceCards = () => {
    const productsBySegments = this.reformatProducts();
    const currentSegmentProducts =
      this.getCurrentSegmentProducts(productsBySegments);
    const filteredProducts = this.filterProducts(currentSegmentProducts);
    const sortedProducts = this.sortProducts(filteredProducts);
    const Cards = this.generateSeatingChoiceCardUI(sortedProducts);

    return Cards;
  };

  render() {
    const Cards = this.generateSeatingChoiceCards();

    return (
      <>
        <Header />

        <div className="gr-overflow-y-scroll gr-h-modal">
          <div className="gr-text-center gr-mt-5 gr-min-h-50">
            <h1 id="seating-choice-header" className="gr-font-mono gr-text-xl">
              Seats are running out fast!
            </h1>
          </div>

          <div className="gr-flex gr-items-end gr-py-2 gr-px-2">
            <div className="gr-m-auto">
              <p
                id="seating-choice-description"
                className="gr-text-center gr-font-mono gr-text-l"
              >
                Please select your prefered choice of seats and travel in
                comfort
              </p>
            </div>
          </div>

          <div
            id="seating-choice-card-container"
            className="gr-sc-card-container"
          >
            {Cards}
          </div>
        </div>

        <SeatmapFooter
          displayTextInFooter={'displayTextInFooter'}
          variants={'variants'}
          test_seatmap_show_skip={false}
          test_seatmap_no_close={false}
          brand={''}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  products: state.products,
  basket: state.basket,
  loading: state.session.loading,
  categories: state.categories,
  mobile: state.session.mobile,
  theme: state.session.theme,
  variants: state.session.variants,
  passengers: state.passengers,
  currentPassenger: state.passengers.currentPassenger,
  currentSeatmap: state.itinerary.currentSeatmap,
});

const mapDispatchToProps = {
  addSeatingChoiceToBasket,
  removeSeatingChoiceFromBasket,
  nextPassenger,
};

var clickOutsideConfig = {
  excludeScrollbar: true,
};

export default connect(mapStateToProps, mapDispatchToProps)(
  SeatingChoice,
  clickOutsideConfig,
);
