import React, { Component } from 'react';
import { connect } from 'react-redux';
import ItinerarySelect from './ItinerarySelect';

import {
  setCurrentSeatMap,
  setLoading,
  setCurrentPassenger,
  setShowSeatmapValidateInfant,
  setShowSeatmapValidateGroup
} from '../../redux/actions';

import {
  validateInfants,
  validateLargeGroup
} from '../../utils/validateBasket';

import MediaQuery from 'react-responsive';

function renderAriaForSegment(segment) {
  const departure = `${segment.departure_city_name || ''} ${
    segment.departure_airport
  }`.trim();
  const arrival = `${segment.arrival_city_name || ''} ${
    segment.arrival_airport
  }`.trim();

  return `${segment.direction} Flight: ${departure} to ${arrival}`;
}

class Itinerary extends Component {
  handleChange = id => {
    const {
      setCurrentSeatMap,
      setCurrentPassenger,
      setLoading,
      passengers,
      segmentColumns,
      basket,
      setShowSeatmapValidateInfant,
      setShowSeatmapValidateGroup,
      restrictionMap,
      itinerary
    } = this.props;

    if (!validateInfants(basket, passengers, segmentColumns)) {
      // invalid seats due to infants
      setShowSeatmapValidateInfant(true);
      return;
    }

    if (
      !validateLargeGroup(
        basket,
        passengers,
        restrictionMap,
        itinerary.currentSeatmap
      )
    ) {
      // invalid seats due to large group
      setShowSeatmapValidateGroup(true);
      return;
    }

    setLoading(true);
    setCurrentSeatMap(id);
    setCurrentPassenger(passengers[0]);
    setLoading(false);
  };

  render() {
    const index = this.props.flights.findIndex(
      flights => flights.segment_id === this.props.currentSeatMap
    );
    const ITINERARY_CONDENSE_BARRIER = 1000;
    const direction = index >= 0 ? this.props.flights[index].direction : null;

    const { variants = {} } = this.props;
    let brand = undefined;
    if (variants.test_uber_theme === true) brand = 'uber';

    const flightOptions = this.props.flights.map((segment, segmentIndex) => {
      return (
        <MediaQuery
          key={segmentIndex}
          minWidth={ITINERARY_CONDENSE_BARRIER / this.props.relativeWidthRatio}
        >
          {matches => {
            return (
              <option
                aria-selected={segmentIndex === index ? 'true' : 'false'}
                aria-label={renderAriaForSegment(segment)}
                key={segment.segment_id}
                value={segment.segment_id}
                disabled={!segment.available}
                className="gr-text-title gr-rounded-lg"
              >
                {segment.departure_city_name &&
                segment.arrival_city_name &&
                matches
                  ? `${segment.departure_city_name} (${segment.departure_airport}) → ${segment.arrival_city_name} (${segment.arrival_airport})`
                  : `${segment.departure_airport} → ${segment.arrival_airport}`}
              </option>
            );
          }}
        </MediaQuery>
      );
    });
    return (
      <ItinerarySelect
        id="itinerary-select"
        length={this.props.flights.length}
        handleChange={this.handleChange}
        current={{
          index,
          direction
        }}
        direction={{}}
        value={this.props.currentSeatMap}
        relativeWidthRatio={this.props.relativeWidthRatio}
        brand={brand}
        locale={this.props.locale}
      >
        {flightOptions}
      </ItinerarySelect>
    );
  }
}

const mapStateToProps = state => ({
  flights: state.itinerary.flights,
  passengers: state.passengers.listOfPassenger,
  currentPassenger: state.passengers.currentPassenger,
  currentSeatMap: state.itinerary.currentSeatmap,
  segmentColumns: state.session.segmentColumns,
  basket: state.basket,
  relativeWidthRatio: state.session.relativeWidthRatio,
  restrictionMap: state.itinerary.restrictionMap,
  itinerary: state.itinerary,
  variants: state.session.variants,
  locale: state.session.locale
});

const mapDispatchToProps = {
  setCurrentPassenger,
  setCurrentSeatMap,
  setLoading,
  setShowSeatmapValidateInfant,
  setShowSeatmapValidateGroup
};

export default connect(mapStateToProps, mapDispatchToProps)(Itinerary);
