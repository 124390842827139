import React from 'react';

import Price from '../Price';
const HOST = process.env
  ? process.env?.REACT_APP_STATIC_URL ?? 'https://static.gordiansoftware.com'
  : 'https://static.gordiansoftware.com';

const getImageSource = (title) => {
  switch (title) {
    case 'Window Seat':
      return `${HOST}/images/window.svg`;
    case 'Aisle Seat':
      return `${HOST}/images/aisle.svg`;
    case 'Aisle Or Window Seat':
      return `${HOST}/images/aisle_or_window.svg`;
    case 'Together Seat':
      return `${HOST}/images/together.svg`;
    default:
      return `${HOST}/images/together.svg`;
  }
};

const getProductDescription = (product) => {
  const { display_name } = product;
  switch (display_name) {
    case 'Window Seat':
      return 'I want a view!';
    case 'Aisle Seat':
      return 'I want lesser interference and better access!';
    case 'Aisle Or Window Seat':
      return 'I just dont want the middle seat!';
    case 'Together Seat':
      return 'I want to sit together with the rest of my company';
    default:
      return `I want a ${display_name.toLowerCase()}`;
  }
};

const getProductPrice = (product) => {
  const { price_and_availability } = product;
  const { price } = price_and_availability.group;

  return price.total;
};

export const SeatingChoiceCard = (props) => {
  const cardStyle = props.selected ? 'gr-sc-card-selected' : '';

  const headerStyle = props.selected
    ? 'gr-sc-card-header-selected'
    : 'gr-sc-card-header';

  const productPrice = getProductPrice(props.product);

  return (
    <>
      <div onClick={props.cardClick} className={`gr-sc-card ${cardStyle}`}>
        <div
          className={`gr-flex gr-items-center gr-text-center gr-py-2 ${headerStyle}`}
        >
          <h3 className="gr-font-bold gr-w-full gr-font-mono gr-text-l">
            {props.product.display_name}
          </h3>
        </div>
        <div className="gr-flex gr-sc-card-content">
          <div className="gr-sc-card-icon gr-px-2">
            <img
              alt={props.product.display_name}
              src={`${getImageSource(props.product.display_name)}`}
            />
          </div>
          <div className="gr-flex gr-flex-col gr-justify-between gr-items-center gr-sc-card-info gr-px-2">
            <p className="gr-text-center gr-font-mono gr-text-l gr-py-2">
              {getProductDescription(props.product)}
            </p>
            <Price
              currency={productPrice.currency}
              amount={productPrice.amount}
              decimalPlaces={productPrice.decimal_places}
              abbreviatePrice={false}
            />
            {/* <div className="gr-text-center gr-py-2 " >SELECTIONS GO HERE</div> */}
          </div>
        </div>
      </div>
    </>
  );
};
