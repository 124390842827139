import React, { Component } from 'react';
import {
  UpsellInterfaces,
  SeatInterfaces,
  PassengerInterfaces
} from './CommonInterfaces';
import Button from '../shared/Button';
import SeatDisclaimerModal from './SeatDisclaimerModal';
import {
  showUpsellModal,
  acceptUpsellModal,
  updateSelectedSeat,
  setSegmentsCurrentCompartment,
  nextPassenger,
  acceptSeat
} from '../../redux/actions';
import { connect } from 'react-redux';
import { ReduxInterfaces } from '../../redux/stateInterface';
import Price from './Price';
import { rtlLangs } from '../../translations/rtl-langs';

interface UpsellDisclaimerInterface {
  showUpsellModal: (doShow: boolean) => void;
  acceptUpsellModal: (doShow: boolean) => void;
  updateSelectedSeat: (seat: SeatInterfaces.SelectedSeat | null) => void;
  currentSegment: string;
  inFocusCompartment: UpsellInterfaces.UpsellOption | null;
  setSegmentsCurrentCompartment: (payload: {
    id: string;
    compartment: UpsellInterfaces.UpsellOption;
  }) => void;
  selectedSeat: SeatInterfaces.SelectedSeat | null;
  lastSelectedSeat: SeatInterfaces.SelectedSeat | null;
  nextPassenger: (currentPassenger: PassengerInterfaces.Passenger) => void;
  acceptSeat: (metaCreator: {
    currentSeatmap: string;
    selectedSeat: SeatInterfaces.SelectedSeat;
    currentPassenger: PassengerInterfaces.Passenger;
    selectedProduct: any;
  }) => void;
  selectedProduct: any;
  currentPassenger: PassengerInterfaces.Passenger;
  updateLastSelectedSeat: (seat: SeatInterfaces.SelectedSeat | null) => void;
  locale: string | null;
}

// TODO: Replace raw strings with Translations.
class UpsellDisclaimer extends Component<UpsellDisclaimerInterface> {
  getBenefits(): UpsellInterfaces.Benefit[] {
    if (this.props.inFocusCompartment) {
      return this.props.inFocusCompartment.benefits;
    } else {
      return [];
    }
  }

  makeModalTitle() {
    return <div>Comes with:</div>;
  }

  makeTitle() {
    return <div>Changing Fares Disclaimer</div>;
  }

  makeBulletPoints() {
    const bulletPoints = this.getBenefits().map((benefit, index) => (
      <li key={index} className="">
        {benefit.benefit}
      </li>
    ));
    return (
      <ul
        className="gr-upsell-disclaimer gr-list-inside gr-mb-8"
        style={{ listStyle: 'circle' }}
      >
        {bulletPoints}
      </ul>
    );
  }

  makeConfirmIntentText() {
    return <div>Ready to upgrade?</div>;
  }

  makeAcceptButton() {
    let priceElement = null;

    if (
      this.props.selectedProduct &&
      this.props.selectedProduct.price_and_availability
    ) {
      const priceAndAvailability = this.props.selectedProduct
        .price_and_availability[this.props.currentPassenger.passenger_id];

      priceElement = (
        <Price
          currency={priceAndAvailability.price?.total.currency}
          amount={priceAndAvailability.price?.total.amount}
          decimalPlaces={priceAndAvailability.price?.total.decimal_places}
          tabIndex={0}
          prefixText={'Yes, upgrade me for'}
        />
      );
    }

    return (
      <Button
        id="gr_upsell_disclaimer_accept_button"
        handleClick={() => this.doAccept()}
        primary
      >
        {priceElement}
      </Button>
    );
  }

  makeDeclineButton() {
    return (
      <Button
        handleClick={() => {
          this.doTeardown();
        }}
        id="gr_upsell_disclaimer_decline_button"
      >
        <h2>No, don&lsquo;t upgrade me.</h2>
      </Button>
    );
  }

  doTeardown() {
    this.props.showUpsellModal(false);
    this.props.updateSelectedSeat(null);
  }

  doAccept() {
    // Add seat to basket.
    if (this.props.selectedSeat) {
      this.props.acceptSeat({
        currentSeatmap: this.props.currentSegment,
        selectedSeat: this.props.selectedSeat,
        currentPassenger: this.props.currentPassenger,
        selectedProduct: this.props.selectedProduct
      });
      this.props.nextPassenger(this.props.currentPassenger);
    }

    // Now start closing the modal.
    this.doTeardown();
    this.props.acceptUpsellModal(true);

    // Set the selected compartment to be in focus so that user won't be prompted to go through upsell disclaimer
    // again for changing seats within the compartment.
    if (this.props.inFocusCompartment) {
      this.props.setSegmentsCurrentCompartment({
        id: this.props.currentSegment,
        compartment: this.props.inFocusCompartment
      });
    }
  }

  render() {
    if (this.props.inFocusCompartment) {
      return (
        <SeatDisclaimerModal
          name="upsell_disclaimer"
          modalTitle={this.makeModalTitle()}
          title={this.makeTitle()}
          bulletPoints={this.makeBulletPoints()}
          confirmIntentText={this.makeConfirmIntentText()}
          acceptButton={this.makeAcceptButton()}
          declineButton={this.makeDeclineButton()}
          direction={
            rtlLangs.includes(this.props.locale || 'en-US') ? 'rtl' : 'ltr'
          }
          showThisModal={this.props.showUpsellModal}
        />
      );
    } else {
      this.doTeardown();
      return null;
    }
  }
}

const mapStateToProps = (state: ReduxInterfaces.StoreInterface) => ({
  currentSegment: state.itinerary.currentSeatmap,
  inFocusCompartment: state.session.inFocusCompartment,
  selectedSeat: state.selectedSeat,
  currentPassenger: state.passengers.currentPassenger,
  selectedProduct:
    state.products.products?.seat?.[state.selectedSeat?.product_id] || null,
  locale: state.session.locale
});

const mapDispatchToProps = {
  showUpsellModal,
  acceptUpsellModal,
  updateSelectedSeat,
  setSegmentsCurrentCompartment,
  nextPassenger,
  acceptSeat
};

export default connect(mapStateToProps, mapDispatchToProps)(UpsellDisclaimer);
