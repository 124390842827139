const hopper = {
  actionButtonBorderRadius: '8px',
  select_seat_button: {
    backgroundColor: '#FFFFFF',
    borderColor: '#cbd5e0',
    textColor: '#01AAE4',
  },
  selectArrowColor: '#01AAE4',
};

export default hopper;
