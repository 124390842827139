import React, { Component } from 'react';
import { v4 as uuidv4 } from 'uuid';

type BulkheadColumn = string[];

interface BulkheadProps {
  columns: BulkheadColumn[];
  overWing: boolean;
}

export default class Bulkhead extends Component<BulkheadProps, {}> {
  render() {
    const { columns = [], overWing } = this.props;
    return (
      <div
        className={`gr-flex gr-px-4 sm:gr-px-8 gr-border-l-4 gr-border-r-4 sm:gr-border-l-8 sm:gr-border-r-8 gr-border-gray-${
          overWing ? '450' : '300'
        }`}
      >
        {columns
          .reduce((acc, val, index, list) => {
            let current = val;
            if (index + 1 !== list.length) {
              current = [...val, ''];
              return acc.concat(current);
            }
            return acc.concat(current);
          }, [])
          .map(column => {
            return column === '' ? (
              <div
                key={uuidv4()}
                className="aisle gr-mx-auto gr-w-8 gr-h-8 sm:gr-w-10 md:gr-w-12"
              ></div>
            ) : (
              <div
                key={uuidv4()}
                className="colums gr-flex gr-text-center gr-border-t-8 gr-border-gray-300 gr-w-8 sm:gr-w-10 md:gr-w-12"
              ></div>
            );
          })}
      </div>
    );
  }
}
