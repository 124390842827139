import React, { Component } from 'react';
import { connect } from 'react-redux';

import {
  nextSeatmap,
  showModal,
  updateSelectedSeat,
  setCurrentPassenger,
  confirmBasket,
  setBasketUpdated,
  setShowSeatmapValidateInfant,
  setShowSeatmapValidateGroup,
} from '../../../redux/actions';
import Button from '../../shared/Button';

import { Translate } from 'react-localize-redux';

export class NextFlight extends Component {
  constructor(props) {
    super(props);
    this.state = {
      buttonType: null,
    };
  }

  handleClick = () => {
    const { updateSelectedSeat, setCurrentPassenger, passengers } = this.props;

    updateSelectedSeat(null);

    const next = this.getNextAvailable();

    setCurrentPassenger(passengers[0]);
    // if valid seating arrangement with infants, otherwise show error
    this.progressToNextOrFinish(next);

    if (window.onSeatingChoiceBasketChange) {
      window.onSeatingChoiceBasketChange(this.props.basket);
    }
  };

  getNextAvailable = () => {
    const { itinerary } = this.props;
    const { flights = [], currentSeatmap } = itinerary;
    for (let i = 0, foundMatch = false; i < flights.length; i++) {
      const seatmap = flights[i];
      if (foundMatch && seatmap.available) {
        return seatmap;
      } else if (seatmap.segment_id === currentSeatmap) {
        foundMatch = true;
      }
    }
    return null;
  };

  progressToNextOrFinish = (next) => {
    const { editMode } = this.props;
    if (!next || editMode) {
      this.props.setBasketUpdated(true);
      this.props.confirmBasket();
      return;
    }
    return this.props.nextSeatmap(next.segment_id);
  };

  render() {
    const { variants } = this.props;
    const { test_confirm_on_click = false } = variants;

    const chooseButton = () => {
      const confirmSeats = <Translate id="select_seats"></Translate>;
      const nextFlight = <Translate id="next_flight"></Translate>;
      const nextSeatMap = this.getNextAvailable();
      if (!nextSeatMap || this.props.editMode) {
        return confirmSeats;
      } else {
        return nextFlight;
      }
    };

    const showButton =
      !test_confirm_on_click || chooseButton().props.id !== 'select_seats';

    return (
      showButton && (
        <div className={this.props.className || 'gr-w-40 sm:gr-w-56'}>
          <Button
            primary
            id="next-button"
            hovergrBg="blue-primary"
            hoverTextColor="gr-text-white"
            handleClick={() => this.handleClick()}
            disabled={
              this.props.basketChanging ||
              this.props.showSeatmapValidateInfantPopup ||
              this.props.showSeatmapValidateGroupPopup
            }
            className="gr-flex gr-flex-row gr-justify-around"
          >
            <span className="gr-m-auto">{chooseButton()}</span>
          </Button>
        </div>
      )
    );
  }
}

const mapStateToProps = (state) => ({
  basket: state.basket,
  itinerary: state.itinerary,
  passengers: state.passengers.listOfPassenger,
  editMode: state.session.editMode,
  variants: state.session.variants,
  basketChanging: state.session.basketChanging,
  segmentColumns: state.session.segmentColumns,
  showSeatmapValidateInfantPopup: state.session.showSeatmapValidateInfantPopup,
  showSeatmapValidateGroupPopup: state.session.showSeatmapValidateGroupPopup,
  restrictionMap: state.itinerary.restrictionMap,
});

const mapDispatchToProps = {
  nextSeatmap,
  updateSelectedSeat,
  setCurrentPassenger,
  showModal,
  confirmBasket,
  setBasketUpdated,
  setShowSeatmapValidateInfant,
  setShowSeatmapValidateGroup,
};

export default connect(mapStateToProps, mapDispatchToProps)(NextFlight);
