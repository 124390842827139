import React, { useEffect, useReducer, useState } from 'react';
import AddButton from './AddButton';
import { SubtractButton } from './SubtractButton';
import { formatPrice } from '../../utils/formatPrice';
import { connect } from 'react-redux';
import { setBaggageBasket } from '../../redux/actions';
import { parseAcceptedBags } from '../../utils/parseAcceptedBags';
import {
  Bag,
  Basket,
  Journey,
  Passenger,
  Theme,
  EachPriceAndAvailability,
} from './CommonTypes';
import { Translate } from 'react-localize-redux';

function PassengerSelectionRow({
  passenger,
  bag,
  basket,
  setTotalPrice,
  setBaggageBasket,
  smallWidth,
  isMobile,
  index,
  journeys,
  activeJourney,
  baggageDisclaimers,
  variants,
  theme,
  modalShown,
  addButtonTextWidth,
  maxProductPrice,
  maxWeight,
  fontFamily,
}: {
  passenger: Passenger;
  bag: Bag;
  setTotalPrice: any;
  basket: Basket;
  setBaggageBasket?: any;
  smallWidth?: boolean;
  isMobile: boolean;
  index: number;
  modalShown: boolean;
  journeys: [Journey];
  activeJourney: Journey;
  baggageDisclaimers: { [ix: number]: { [ix: number]: string } };
  variants?: { [key: string]: any };
  theme?: Theme;
  addButtonTextWidth: number;
  maxProductPrice: { amount: number; currency: string; decimal_places: number };
  maxWeight: string;
  fontFamily: string;
}) {
  const canvas = document.createElement('canvas');
  const context = canvas.getContext('2d') as CanvasRenderingContext2D;
  context.font = `14px ${fontFamily || 'Roboto'}`;

  const formattedMaxPrice = formatPrice(
    maxProductPrice?.amount ?? 0,
    maxProductPrice?.currency ?? 'USD',
    maxProductPrice?.decimal_places ?? 0,
  );

  const maxPriceWidth = context.measureText(formattedMaxPrice).width + 1;
  const maxWeightWidth = context.measureText(maxWeight).width + 1;

  const passengerId =
    passenger.api_passenger_id ?? passenger.id ?? passenger.passenger_id;
  const bagDetails = bag.product_details;

  const getBagOptions = () => {
    const bagOptions = [];

    const allBags = [bag, ...bag.weight_based_offer];
    for (const b of allBags) {
      const paxPriceAndAvailability =
        b.price_and_availability[passengerId as string];
      if (paxPriceAndAvailability) {
        bagOptions.push(
          ...paxPriceAndAvailability
            .filter((ppa) => ppa.available)
            .map((ppa) => ({
              ...ppa,
              product_details: b.product_details,
              product_id: b.product_id,
            })),
        );
      }
    }

    bagOptions.sort(
      (bo1, bo2) => bo1.product_details.weight - bo2.product_details.weight,
    );
    bagOptions.forEach((bo: any, index) => {
      bo.index = index;
    });

    return bagOptions;
  };

  const bagOptions = getBagOptions();

  const maxPieces =
    bag.system === 'weight'
      ? 1
      : (bagOptions as any)
          .filter((bo: EachPriceAndAvailability) => bo.available)
          .sort(
            (b1: EachPriceAndAvailability, b2: EachPriceAndAvailability) =>
              b2.quantity - b1.quantity,
          )[0]?.quantity ?? 0;

  const journeyIndex = journeys.findIndex(
    (j: Journey) =>
      j['to'] === activeJourney.to && j['from'] === activeJourney.from,
  );

  const bagDisclaimer = baggageDisclaimers
    ? Object.entries(
        Object.entries(baggageDisclaimers)?.find(
          (journeyDisclaimer) =>
            parseInt(journeyDisclaimer[0]) === journeyIndex,
        )?.[1] ?? {},
      ).find((paxDisclaimer) => parseInt(paxDisclaimer[0]) === index)?.[1] ?? ''
    : '';

  const [passengerTotalPrice, setPassengerTotalPrice] = useState(0);

  const bagsReducer = (previousState: Array<any>, data: any) => {
    const previousSelectedBags = JSON.parse(JSON.stringify(previousState));
    if (data.action === 'reset_state') {
      return [];
    }
    if (data.action === 'add_piece') {
      if (bag.system === 'weight') {
        const firstBagOption = (bagOptions as any)[0];
        previousSelectedBags.push({
          price: firstBagOption.price.total,
          weight: firstBagOption.product_details.weight,
          index: firstBagOption.index,
          uniqueId: firstBagOption.unique_id,
          productId: firstBagOption.product_id,
          amount: 1,
        });
      } else {
        if (previousSelectedBags.length === 0) {
          previousSelectedBags.push({
            weight: bagDetails.weight,
            amount: 0,
          });
        }
        previousSelectedBags[0].amount++;

        const matchingBagOption = (bagOptions as any).find(
          (bagOption: EachPriceAndAvailability) =>
            bagOption.quantity === previousSelectedBags[0].amount,
        );
        previousSelectedBags[0].price = { ...matchingBagOption.price.total };
        previousSelectedBags[0].uniqueId = matchingBagOption.unique_id;
      }
    }
    if (data.action === 'remove_piece') {
      if (bag.system === 'weight') {
        previousSelectedBags.splice(data.bagIndex, 1);
      } else {
        previousSelectedBags[0].amount--;

        const matchingBagOption = (bagOptions as any).find(
          (bagOption: EachPriceAndAvailability) =>
            bagOption.quantity === previousSelectedBags[0].amount,
        );
        previousSelectedBags[0].price = { ...matchingBagOption?.price.total };
        previousSelectedBags[0].uniqueId = matchingBagOption?.unique_id;

        if (previousSelectedBags[0].amount === 0) {
          previousSelectedBags.shift();
        }
      }
    }
    if (data.action === 'add_weight') {
      const nextWeightOffer: any =
        bagOptions[previousSelectedBags[data.bagIndex].index + 1];
      previousSelectedBags[data.bagIndex] = {
        price: nextWeightOffer.price.total,
        weight: nextWeightOffer.product_details.weight,
        index: nextWeightOffer.index,
        uniqueId: nextWeightOffer.unique_id,
        productId: nextWeightOffer.product_id,
        previousProductId: previousSelectedBags[data.bagIndex].productId,
        amount: 1,
      };
    }
    if (data.action === 'remove_weight') {
      const previousWeightOffer: any =
        bagOptions[previousSelectedBags[data.bagIndex].index - 1];
      previousSelectedBags[data.bagIndex] = {
        price: previousWeightOffer.price.total,
        weight: previousWeightOffer.product_details.weight,
        index: previousWeightOffer.index,
        uniqueId: previousWeightOffer.unique_id,
        productId: previousWeightOffer.product_id,
        previousProductId: previousSelectedBags[data.bagIndex].productId,
        amount: 1,
      };
    }

    return [...previousSelectedBags];
  };

  const selectedBagFromBasket =
    basket.bags[passengerId as string]?.[bag.product_id];

  const previouslySelectedBagOption =
    bag.system === 'weight'
      ? (bagOptions as any).find((b: any) =>
          Object.keys(basket.bags[passengerId as string] ?? {}).includes(
            b.product_id,
          ),
        )
      : (bagOptions as any).find((b: EachPriceAndAvailability) =>
          b.unique_id
            ? b.unique_id === selectedBagFromBasket?.uniqueId
            : b.quantity === selectedBagFromBasket?.quantity,
        );

  const [selectedBags, setSelectedBags] = useReducer(
    bagsReducer,
    previouslySelectedBagOption
      ? [
          bag.system === 'weight'
            ? {
                price: previouslySelectedBagOption.price.total,
                weight: previouslySelectedBagOption.product_details.weight,
                index: previouslySelectedBagOption.index,
                uniqueId: previouslySelectedBagOption.unique_id,
                productId: previouslySelectedBagOption.product_id,
                amount: 1,
              }
            : {
                weight: bag.product_details.weight,
                amount: previouslySelectedBagOption.quantity,
                uniqueId: previouslySelectedBagOption.unique_id,
                price: previouslySelectedBagOption.price.total,
              },
        ]
      : [],
  );

  useEffect(() => {
    if (Object.keys(basket.bags).length === 0) {
      setSelectedBags({ action: 'reset_state' });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modalShown]);

  useEffect(() => {
    const currentPassengerTotalPrice = selectedBags.reduce(
      (passengerTotalPrice, bag) => passengerTotalPrice + bag.price.amount,
      0,
    );
    setTotalPrice(
      (previousTotalPrice: number) =>
        previousTotalPrice + currentPassengerTotalPrice - passengerTotalPrice,
    );
    setPassengerTotalPrice(currentPassengerTotalPrice);

    updateBasket();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedBags]);

  const updateBasket = () => {
    const updatedBasket = {
      ...basket,
    };
    const currentPassengerSelections =
      updatedBasket.bags[passengerId as string] ?? {};

    /*
      The selectedBags array represents rows of the passenger selection. This is a more generic approach, although for
       piece-based bags we only have one row with a dynamic amount, and for weight-based bags we assume a max of 1 bag
       for now, so the array is likely to always contain only 1 element.
    */
    if (selectedBags.length > 0) {
      for (const selectedBag of selectedBags) {
        const bagDetails = {
          quantity: selectedBag.amount,
          uniqueId: selectedBag.uniqueId,
          totalPrice: selectedBag.price,
        };
        currentPassengerSelections[selectedBag.productId || bag.product_id] =
          bagDetails;

        if (selectedBag.previousProductId) {
          delete currentPassengerSelections[selectedBag.previousProductId];
        }
      }
    } else {
      for (const productId of [
        bag.product_id,
        ...bag.weight_based_offer.map((wbo) => wbo.product_id),
      ]) {
        delete currentPassengerSelections[productId];
      }
    }

    updatedBasket.bags[passengerId as string] = {
      ...updatedBasket.bags[passengerId as string],
      ...currentPassengerSelections,
    };

    if (Object.keys(updatedBasket.bags[passengerId as string]).length === 0) {
      delete updatedBasket.bags[passengerId as string];
    }

    setBaggageBasket(updatedBasket);
  };

  const onAddBagClick = () => {
    setSelectedBags({ action: 'add_piece' });
  };

  const onRemoveBagClick = (bagIndex: number) => {
    setSelectedBags({ action: 'remove_piece', bagIndex });
  };

  const onAddWeightClick = (bagIndex: number) => {
    setSelectedBags({ action: 'add_weight', bagIndex });
  };

  const onRemoveWeightClick = (bagIndex: number) => {
    setSelectedBags({ action: 'remove_weight', bagIndex });
  };

  const BagSelectionRows = () => {
    return selectedBags.map((selectedBag, index) => (
      <div
        className={`gr-flex gr-justify-between ${
          isMobile ? 'gr-mr-35' : ''
        } gr-mt-3 gr-bag-added-description-text  gr-text-gray-550 gr-text-sm gr-not-italic gr-leading-normal gr-font-semibold gr-items-center`}
        key={index}
      >
        <span>{selectedBag.amount}</span>
        <div className="gr-flex">
          {bag.system === 'weight' ? (
            <SubtractButton
              onClick={() => onRemoveWeightClick(index)}
              disabled={!bagOptions[selectedBag.index - 1]}
              mobile={isMobile}
            />
          ) : null}
          <span
            className="gr-pl-1 gr-pr-1 gr-text-center"
            style={{ minWidth: `${maxWeightWidth}px` }}
          >
            {selectedBag.weight + bagDetails.weight_unit}
          </span>
          {bag.system === 'weight' ? (
            <AddButton
              onClick={() => onAddWeightClick(index)}
              small
              disabled={!bagOptions[selectedBag.index + 1]}
              addButtonTextWidth={addButtonTextWidth}
              mobile={isMobile}
            />
          ) : null}
        </div>
        <span
          className="gr-text-center"
          style={{ minWidth: `${maxPriceWidth}px` }}
        >
          {formatPrice(
            selectedBag.price.amount,
            selectedBag.price.currency,
            selectedBag.price.decimal_places,
          )}
        </span>
        <span
          className="gr-cursor-pointer remove-bag-btn"
          style={{ color: theme?.bags?.removeBagText ?? '#76B761' }}
          onClick={() => onRemoveBagClick(index)}
        >
          <Translate id={'remove'}>Remove</Translate>
        </span>
      </div>
    ));
  };

  const currentBag =
    bag.system === 'weight'
      ? (bagOptions as Array<any>)[selectedBags[0]?.index]
      : (bagOptions as Array<any>).find(
          (b) => b.quantity === (selectedBags[0]?.amount ?? 0),
        );
  const currentBagTotal = currentBag?.price.total;
  const nextBag =
    bag.system === 'weight'
      ? (bagOptions as Array<any>)[selectedBags[0]?.index ?? 0]
      : (bagOptions as Array<any>).find(
          (b) => b.quantity === (selectedBags[0]?.amount ?? 0) + 1,
        );
  const nextBagTotal = nextBag?.price.total;
  const nextBagPrice = nextBagTotal
    ? formatPrice(
        nextBagTotal.amount - (currentBagTotal?.amount ?? 0),
        nextBagTotal.currency,
        nextBagTotal.decimal_places,
        true,
      )
    : undefined;

  return isMobile ? (
    <div
      className={
        'gr-border-t gr-border-solid-t gr-border-gray-t-300 gr-pl-6 gr-pr-6'
      }
      style={{ paddingBottom: '7.5px', paddingTop: '7.5px' }}
    >
      <div className="gr-flex gr-justify-between gr-items-center">
        {smallWidth ? (
          <div className="gr-flex gr-flex-col">
            <span
              className={`gr-bag-pax-name-text gr-text-gray-900 gr-text-sm gr-not-italic gr-leading-normal gr-font-bold gr-mb-1`}
            >
              {passenger.first_names || passenger.surname
                ? (passenger.first_names ?? '') +
                  ' ' +
                  (passenger.surname ?? '')
                : 'Passenger ' + (index + 1)}
            </span>
            {bagDisclaimer ? (
              <span className="gr-bag-disclaimer-text gr-text-left">
                ✓ {bagDisclaimer}
              </span>
            ) : null}
          </div>
        ) : (
          <>
            <span
              className={`gr-bag-pax-name-text gr-text-gray-900 gr-text-sm gr-not-italic gr-leading-normal gr-font-bold`}
            >
              {passenger.first_names || passenger.surname
                ? (passenger.first_names ?? '') +
                  ' ' +
                  (passenger.surname ?? '')
                : 'Passenger ' + (index + 1)}
            </span>
            {bagDisclaimer ? (
              <span className={`gr-bag-disclaimer-text gr-ml-auto gr-mr-2`}>
                ✓ {bagDisclaimer}
              </span>
            ) : null}
          </>
        )}
        <AddButton
          onClick={onAddBagClick}
          disabled={
            (bag.system === 'weight'
              ? selectedBags.length
              : (bagOptions as any).length === 0 || selectedBags[0]?.amount) >=
            maxPieces
          }
          showPrice={Boolean(variants?.test_baggage_price_on_add_button)}
          buttonText={variants?.test_baggage_text_on_add_button}
          price={nextBagPrice}
          mobile={isMobile}
          addButtonTextWidth={addButtonTextWidth}
        />
      </div>
      {BagSelectionRows()}
    </div>
  ) : (
    <div
      className={'gr-mr-4 gr-inline-grid gr-my-4 gr-items-center'}
      style={{ gridTemplateColumns: 'auto 1fr auto', columnGap: '16px' }}
    >
      <span
        className={`gr-bag-pax-name-text gr-text-gray-900 gr-not-italic gr-leading-normal gr-font-bold gr-text-lg`}
      >
        {passenger.first_names || passenger.surname
          ? (passenger.first_names ?? '') + ' ' + (passenger.surname ?? '')
          : 'Passenger ' + (index + 1)}
      </span>
      <div className="gr-flex gr-items-center">
        {bagDisclaimer ? (
          <span className={`gr-bag-disclaimer-text `}>✓ {bagDisclaimer}</span>
        ) : null}
      </div>
      <AddButton
        onClick={onAddBagClick}
        disabled={
          (bag.system === 'weight'
            ? selectedBags.length
            : (bagOptions as any).length === 0 || selectedBags[0]?.amount) >=
          maxPieces
        }
        showPrice={Boolean(variants?.test_baggage_price_on_add_button)}
        buttonText={variants?.test_baggage_text_on_add_button}
        price={nextBagPrice}
        mobile={isMobile}
        addButtonTextWidth={addButtonTextWidth}
      />
      <div style={{ gridColumn: 'span 3' }} className={'gr-px-7'}>
        {BagSelectionRows()}
      </div>
    </div>
  );
}

const mapStateToProps = (state: any) => ({
  activeJourney: state.itinerary.currentBaggageJourney,
  journeys: state.itinerary.baggageJourneys,
  baggageDisclaimers: state.session.baggageDisclaimers,
  maxProductPrice: state.products.maxProductPrice,
  basket: parseAcceptedBags(state.basket, state.baggageJSON.data),
  theme: state.session.theme,
  modalShown: state.session.modalShown,
  variants: state.session.variants,
  fontFamily: state.session.fontFamily,
});

export default connect(mapStateToProps, {
  setBaggageBasket,
})(PassengerSelectionRow);
