import React from 'react';
import BaggageQuantitySelector from './BaggageQuantitySelector';
import { connect } from 'react-redux';

function PassengerBaggageRow({
  passenger,
  available_products,
  disclaimer_text,
  locale,
}) {
  const productSelect = (
    name,
    product_id,
    passenger_id,
    max_quantity,
    price,
  ) => {
    return (
      <BaggageQuantitySelector
        key={`${product_id}-${passenger_id}`}
        name={name}
        product_id={product_id}
        passenger_id={passenger_id}
        max_quantity={max_quantity}
        price={price}
      />
    );
  };

  const renderAvailableProducts = () => {
    // Ensure all products are in the same order
    const productSort = (a, b) => {
      const nameA = a?.product_details?.display_name;
      const nameB = b?.product_details?.display_name;
      if (nameA > nameB) return 1;
      if (nameB > nameA) return -1;
      return 0;
    };
    const productRows = available_products
      .sort(productSort)
      .map((product, index) => {
        const name = product?.product_details?.display_name;
        const product_id = product.product_id;
        const passenger_id = passenger.passenger_id;
        const priceDetails = product?.price_and_availability?.[passenger_id][0];
        const max_quantity = priceDetails.length;
        const price = priceDetails.price?.total?.amount;
        const currency = priceDetails?.price?.total?.currency;
        const decimalPlaces = priceDetails?.price?.total?.decimal_places;
        const formatter = new Intl.NumberFormat(locale, {
          style: 'currency',
          currency: currency,
        });
        const formattedPrice = formatter.format(price / 10 ** decimalPlaces);
        return (
          <div
            key={`${product_id}_${passenger_id}_product_row`}
            className="gr-flex gr-w-full gr-flex-col"
          >
            {productSelect(
              name,
              product_id,
              passenger_id,
              max_quantity,
              formattedPrice,
            )}
            {index < available_products.length - 1 && (
              <hr className="gr-w-full gr-border-gray-100 gr-my-4" />
            )}
          </div>
        );
      });
    return productRows;
  };
  return (
    <div className="gr-flex gr-flex-col">
      <div className="gr-flex gr-flex-col md:gr-flex-row gr-justify-between gr-pb-6">
        <div className="flex-1 gr-pr-2">
          <div className="gr-flex gr-pb-2 gr-font-bold gr-text-base gr-text-black">
            {`${passenger.first_names} ${passenger.surname}`}
          </div>
          {disclaimer_text && (
            <div className="gr-text-left gr-text-sm gr-pb-2 md:gr-pt-3">
              {disclaimer_text}
            </div>
          )}
        </div>

        <div className="gr-flex flex-2 gr-w-full md:gr-w-3/5 gr-flex-col">
          {renderAvailableProducts()}
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  locale: state.session.locale,
});

export default connect(mapStateToProps, {})(PassengerBaggageRow);
