import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { withLocalize, Translate } from 'react-localize-redux';
import Button from '../shared/Button';

import { showModal } from '../../redux/actions';

class BaggageFooter extends PureComponent {
  buildBasket() {
    const { basket = {} } = this.props;
    const finalBasket = [];
    for (const passenger_id in basket) {
      const passengerSelections = basket[passenger_id];
      for (const bag_id in passengerSelections) {
        const quantity = passengerSelections[bag_id];
        const basketItem = {
          product_id: bag_id,
          passenger_id,
          quantity,
        };
        if (quantity > 0) finalBasket.push(basketItem);
      }
    }
    return finalBasket;
  }

  calculateCost() {
    const { basket = {} } = this.props;

    const priceCurrencyInfo = () => {
      const priceInfo = {
        currency: null,
        decimal_places: null,
      };
      const { baggageData } = this.props;
      if (!baggageData || Object.keys(baggageData).length === 0) {
        return priceInfo;
      }
      const { bag = {} } = baggageData?.results?.products;
      const productIDs = Object.keys(bag);
      const firstBagID = productIDs[0];
      if (!firstBagID) {
        return priceInfo;
      }
      try {
        const product = bag[firstBagID];
        const { price_and_availability } = product;
        const passengerIDs = Object.keys(price_and_availability);
        const firstPassengerID = passengerIDs[0];
        if (!firstPassengerID) {
          return priceInfo;
        }

        const passenger_bag_availability =
          price_and_availability[firstPassengerID];
        const firstProduct = passenger_bag_availability[0];
        const total_details = firstProduct?.price.total;
        priceInfo.currency = total_details.currency;
        priceInfo.decimal_places = total_details.decimal_places;
      } catch {
        return priceInfo;
      }
      return priceInfo;
    };

    const getPrice = (product_id, passenger_id, quantity) => {
      const { baggageData } = this.props;
      try {
        const { bag = {} } = baggageData?.results?.products;
        const product = bag[product_id];
        const { price_and_availability } = product;
        const passenger_bag_availability = price_and_availability[passenger_id];
        const product_by_quantity = passenger_bag_availability.filter(
          (option) => {
            return option.quantity === quantity;
          },
        )[0];
        const total_details = product_by_quantity?.price.total;
        return {
          cost: total_details?.amount || 0,
        };
      } catch {
        return {
          cost: 0,
        };
      }
    };
    let cost = 0.0;
    let { currency, decimal_places } = priceCurrencyInfo();
    if (!currency || !decimal_places) {
      return null;
    }
    for (const passenger_id in basket) {
      const passengerSelections = basket[passenger_id];
      for (const bag_id in passengerSelections) {
        const quantity = passengerSelections[bag_id];
        const priceDetails = getPrice(bag_id, passenger_id, quantity);
        cost += priceDetails.cost;
      }
    }
    const formatter = new Intl.NumberFormat(this.props.locale, {
      style: 'currency',
      currency: currency,
    });
    return formatter.format(cost / Math.pow(10, decimal_places));
  }

  render() {
    return (
      <div
        className="footer gr-absolute gr-bottom-0 gr-h-20 gr-border gr-w-full"
        style={{ borderTop: '1px solid lightGrey' }}
      >
        <footer className="gr-flex gr-items-center gr-justify-between sm:gr-flex-row gr-h-full gr-w-full">
          <div className="gr-w-1/3 sm:gr-w-1/4 gr-text-black">
            <Translate id="total" /> {`: ${this.calculateCost()}`}
          </div>
          <div className="gr-flex-row gr-flex gr-gap-2 gr-px-5">
            <div className="gr-w-24">
              <Button
                id="cancel-button"
                handleClick={() => {
                  this.props.showModal(false);
                }}
              >
                <Translate id="common.cancel" />
              </Button>
            </div>
            <div className="gr-w-24">
              <Button
                primary={true}
                id="submit-baggage"
                handleClick={() => {
                  if (window.onBaggageBasketChange) {
                    window.onBaggageBasketChange(this.buildBasket());
                    this.props.showModal(false);
                    return;
                  }
                }}
              >
                <Translate id="common.save" />
              </Button>
            </div>
          </div>
        </footer>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  basket: state.basket.bags,
  baggageData: state.baggageJSON.data,
  locale: state.session.locale,
});

const ModalLocalize = withLocalize(BaggageFooter);

export default connect(mapStateToProps, {
  showModal,
})(ModalLocalize);
