const budgetair = {
  textPrimary: '#597385',
  textSecondary: '#414141',
  title: 'rgb(89, 115, 133)',
  primary: '#78D80B',
  secondary: '#FFFFFF',
  alert: '#DF374B',
  actionButtonBorderRadius: '8px',
  buttonBorderRadius: '8px',
  seats: {
    1: {
      fill: '#92BE14',
      stopColor: '#92BE14',
    },
    2: {
      fill: '#ADCE4F',
      stopColor: '#ADCE4F',
    },
    3: {
      fill: '#B69CC6',
      stopColor: '#B69CC6',
    },
    4: {
      fill: '#72368D',
      stopColor: '#72368D',
    },
    5: {
      fill: '#62002A',
      stopColor: '#62005A',
    },
    6: {
      fill: '#49A345',
      stopColor: '#49A345',
    },
    not_available: {
      fill: '#ece1ef',
      stopColor: '#ece1ef',
    },
    selected_seat: {
      fill: '#00B0FF',
      stopColor: '#00B0FF',
      labelColor: '#ffffff',
    },
  },
};

export default budgetair;
