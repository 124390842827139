import React, { PureComponent } from 'react';

export default class PowerIcon extends PureComponent {
  render() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        height="16"
        viewBox="0 96 960 960"
        width="18"
      >
        <path d="M382 936V818L240 662V447q0-25 17.5-42.5T300 387h72l-30 30V216h60v171h156V216h60v201l-30-30h72q25 0 42.5 17.5T720 447v215L578 818v118H382Z" />
      </svg>
    );
  }
}
