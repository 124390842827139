import React, {
  Children,
  cloneElement,
  Component,
  isValidElement
} from 'react';

import { CategoryInterfaces, CompartmentInterfaces } from './CommonInterfaces';
import Row from './Row';

interface CompartmentProps {
  compartment: CompartmentInterfaces.Compartment;
  categories: CategoryInterfaces.Categories;
  currentSeatmapKey: string;
  parentHeight: number;
  isLastCompartment: boolean;
}

export default class Compartment extends Component<CompartmentProps> {
  between(x: number, range_start: number, range_end: number): boolean {
    return x >= range_start && x <= range_end;
  }

  renderRows(): JSX.Element[] {
    const {
      compartment,
      categories = {},
      currentSeatmapKey,
      isLastCompartment
    } = this.props;

    const currentCategories = categories[currentSeatmapKey];
    const rowList: JSX.Element[] = [];

    for (let idx in compartment.seat_rows) {
      const index = parseInt(idx);
      const seat_row = compartment.seat_rows[index];
      // hasCategory decides if we draw a bbox around a row-group, i.e., with examples/vueling.json
      // we define the SEAT_IMAGES map in categories.js, and the categories are attached to the json there,
      // then, recognized here.
      let hasCategory = null;

      for (let key in currentCategories) {
        const currentCategory = currentCategories[key];
        const color = currentCategory.fill;
        if (currentCategory.start_row && currentCategory.end_row) {
          if (
            this.between(
              seat_row.row,
              currentCategory.start_row,
              currentCategory.end_row
            )
          ) {
            hasCategory = { ...currentCategory, color };
          }
        }
      }

      rowList.push(
        <Row
          bulkheadFront={compartment.definition.bulkhead_front ?? false}
          bulkheadRear={compartment.definition.bulkhead_rear ?? false}
          category={hasCategory}
          overWing={this.between(
            seat_row.row,
            compartment.wings.start_row,
            compartment.wings.end_row
          )}
          cabinName={compartment.definition.cabin ?? ''}
          key={index}
          seat_row={seat_row}
          index={index}
          columns={compartment.definition.columns}
          last={isLastCompartment && index === compartment.seat_rows.length - 1}
        />
      );
    }

    return rowList;
  }

  renderChildren() {
    return Children.map(this.props.children, child => {
      if (isValidElement(child)) {
        const args: {} = { parentHeight: this.props.parentHeight };
        return cloneElement(child, args);
      }
      return child;
    });
  }

  render() {
    const identifier = `compartment-${this.props.compartment.definition.cabin}-${this.props.currentSeatmapKey}`;
    return (
      <div className="compartment" id={identifier} key={identifier}>
        {this.renderRows()}
        {this.renderChildren()}
      </div>
    );
  }
}
