import React, { PureComponent } from 'react';
import { connect } from 'react-redux';

interface NonSeatItemTheme {
  margin: number;
}

interface NonSeatItemVariants {
  test_seatmap_zoomout: boolean;
}

interface NonSeatItemInfo {
  width: number;
  ariaLabelText: string;
  identifyingClass: string;
}

interface NonSeatItemProps {
  info: NonSeatItemInfo;
  theme: NonSeatItemTheme;
  variants: NonSeatItemVariants;
  useZoomout: boolean;
  useTheme: boolean;
  hasBorder: boolean;
}

export class NonSeatItem extends PureComponent<NonSeatItemProps, {}> {
  render() {
    const {
      width = 1,
      ariaLabelText = '',
      identifyingClass = '',
    } = this.props.info;
    const {
      theme,
      variants,
      useZoomout = false,
      hasBorder = true,
    } = this.props;
    const { test_seatmap_zoomout = false } = variants;
    const themeMargin = this.props.useTheme ? theme.margin : 0;
    const outsideMarginX = 2 * (width - 1);
    const insideMargin = themeMargin * width;
    return (
      <div
        style={{
          marginLeft: `${outsideMarginX}px`,
          marginRight: `${outsideMarginX}px`,
        }}
      >
        <div
          aria-label={ariaLabelText}
          style={{
            margin: `${insideMargin}px`,
          }}
          className={`${identifyingClass} gr-flex ${
            hasBorder ? 'gr-border' : ''
          } gr-items-center gr-content-center gr-justify-center gr-h-8 gr-w-${
            width * 8
          } sm:gr-w-${width * 10} md:gr-w-${width * 12} ${
            test_seatmap_zoomout && useZoomout === true ? 'md:gr-mx-0' : ''
          }`}
        >
          {this.props.children}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: any) => ({
  theme: state.session.theme,
  variants: state.session.variants,
});

export default connect(mapStateToProps)(NonSeatItem);
