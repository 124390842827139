import React, { Component } from 'react';
import { connect } from 'react-redux';

import { Translate } from 'react-localize-redux';

import Price from './Price';
import { formatPrice } from '../../utils/formatPrice';
import { getDisplayPrice } from '../../utils/getDisplayPrice';
import { CategoryDetails } from './CategoryDetails';

const Box = ({ backgroundColor, borderColor }) => (
  <div
    className="gr-h-6 gr-w-6 gr-rounded gr-border"
    style={{ backgroundColor, borderColor }}
  ></div>
);

export const UnavailableIcon = (props) => {
  const bg = props.bgColor || '#D4E3EC';
  const stroke = props.strokeColor || '#A9BBC6';
  const className = props.className || '';
  return (
    <svg
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={`gr-h-6 gr-w-6 gr-mt-1 ${className} ${
        props.stretch === true ? 'stretched-svg' : ''
      }`}
    >
      <rect width="20" height="20" rx="3" fill={bg} />
      <path d="M1 1L18.5 18.5" stroke={stroke} strokeLinecap="square" />
      <path d="M1 19L18.5 1.5" stroke={stroke} strokeLinecap="square" />
    </svg>
  );
};

export const Unavailable = (props) => (
  <div
    className="category unavailable gr-relative"
    tabIndex={-1}
    aria-label={'Seat Category: Unavailable'}
  >
    <div aria-hidden="true" className="gr-flex gr-items-center gr-gap-4">
      <UnavailableIcon
        bgColor={props.bgColor}
        strokeColor={props.strokeColor}
      />
      <h2 className="gr-font-medium gr-text-gray-600">
        <Translate id="unavailable" />
      </h2>
    </div>
  </div>
);

const CategoryInfo = ({
  name,
  range,
  description,
  reservationFeeText,
  displayPriceBelow,
}) => (
  <div className="catergory-info gr-flex-1 gr-break-words">
    <div
      className={`${
        displayPriceBelow ? 'gr-flex-col' : 'gr-flex'
      } gr-justify-between gr-items-center`}
    >
      <h2 className="gr-font-medium gr-text-gray-600 gr-pr-2">{name}</h2>
      <div
        className={`gr-text-gray-600 ${
          displayPriceBelow ? 'gr-text-left gr-mt-2' : 'gr-text-right'
        }`}
      >
        {range}
      </div>
    </div>
    <div className="gr-mt-2 gr-text-secondary-text gr-text-xs">
      {description}
      <div style={{ marginTop: '5px' }}>{reservationFeeText}</div>
    </div>
  </div>
);

const CategoryImage = ({ img_url = null, name, rank = 4 }) => {
  return img_url ? (
    <img
      className={`gr-block gr-w-full gr-max-w-full ${
        rank >= 4 ? 'gr-hidden' : rank >= 3 ? 'height-md:gr-hidden' : ''
      } gr-rounded gr-h-32 sm:gr-h-24 gr-mb-4 height-xs:gr-hidden`}
      src={img_url}
      alt={name}
    ></img>
  ) : null;
};

export class Category extends Component {
  state = {
    currentPassenger: null,
    displayDetails: false,
  };

  preLoadImages = () => {
    const { image_url = null, image_urls = [] } = this.props;

    const allImageURLS = (image_url ? [image_url] : []).concat(
      image_urls?.filter((i) => Boolean(i)) ?? []
    );
    allImageURLS.forEach((imageURL) => {
      const image = new Image();
      image.src = imageURL;
    });
  };

  componentDidMount() {
    this.preLoadImages();
  }

  render() {
    const {
      currentPassenger,
      name = '',
      fill,
      stopColor,
      borderColor,
      image_url = null,
      image_urls = [],
      description,
      type,
      index = 0,
      rank = 4,
      priceAdjustment = {},
      price_range,
      variants = {},
      hideDetails = false,
      benefits,
      useEnhancedDetails,
      displayPriceBelow,
    } = this.props;

    let { convenienceFeeDisplayVariant } = this.props;

    let backgroundColor = fill || stopColor;

    if (type === 'unavailable') {
      return <Unavailable strokeColor={borderColor} bgColor={fill} />;
    }

    if (!currentPassenger || !(currentPassenger.passenger_id in price_range)) {
      return [];
    }

    const { start_price: startPrice, end_price: endPrice } =
      price_range[currentPassenger.passenger_id];

    const { test_reservation_fee_name = 'Convenience Fee' } = variants;

    const startDisplayPrice = getDisplayPrice(
      convenienceFeeDisplayVariant,
      startPrice.base_price,
      startPrice.total
    );
    const endDisplayPrice = getDisplayPrice(
      convenienceFeeDisplayVariant,
      endPrice.base_price,
      endPrice.total
    );

    const priceFactor = priceAdjustment.factor ?? 1.0;

    if (endPrice.markup_amount === 0) convenienceFeeDisplayVariant = 'default';

    const reservationFee = (
      <Price
        amount={endPrice.markup_amount * priceFactor}
        currency={endPrice.currency}
        decimalPlaces={endPrice.decimal_places}
        abbreviatePrice={convenienceFeeDisplayVariant !== 'default'}
        simple={convenienceFeeDisplayVariant !== 'default'}
      />
    );

    let range =
      startPrice && endPrice ? (
        <span>
          <Price
            amount={startDisplayPrice * priceFactor}
            currency={startPrice.currency}
            decimalPlaces={startPrice.decimal_places}
            abbreviatePrice={convenienceFeeDisplayVariant !== 'default'}
            simple={convenienceFeeDisplayVariant !== 'default'}
          />
          <span>&nbsp;-&nbsp;</span>
          <Price
            amount={endDisplayPrice * priceFactor}
            currency={endPrice.currency}
            decimalPlaces={endPrice.decimal_places}
            abbreviatePrice={convenienceFeeDisplayVariant !== 'default'}
            simple={convenienceFeeDisplayVariant !== 'default'}
          />
        </span>
      ) : null;

    if (startPrice && endPrice && startDisplayPrice === endDisplayPrice) {
      range = (
        <Price
          amount={startDisplayPrice * priceFactor}
          currency={startPrice.currency}
          decimalPlaces={startPrice.decimal_places}
          abbreviatePrice={convenienceFeeDisplayVariant !== 'default'}
          simple={convenienceFeeDisplayVariant !== 'default'}
        />
      );
    }
    const startPriceAsString = formatPrice(
      startDisplayPrice * priceFactor,
      startPrice.currency,
      startPrice.decimal_places
    );
    const endPriceAsString = formatPrice(
      endDisplayPrice * priceFactor,
      endPrice.currency,
      endPrice.decimal_places
    );

    const priceRangeAria = `${startPriceAsString} to ${endPriceAsString}`;

    const onMouseEnter = () => {
      this.setState({
        displayDetails: true,
      });
    };

    const onMouseLeave = () => {
      this.setState({
        displayDetails: false,
      });
    };

    const displayCategoryDetails =
      useEnhancedDetails &&
      description &&
      !hideDetails &&
      this.state.displayDetails;

    const reservationFeeName = decodeURI(test_reservation_fee_name);
    let fullReservationFeeText = '';

    switch (convenienceFeeDisplayVariant) {
      case 'variant':
        fullReservationFeeText = (
          <>
            Up to a {reservationFee} {reservationFeeName} added at Checkout
          </>
        );
        break;
      case 'full':
        fullReservationFeeText = (
          <>
            {' '}
            {reservationFee} {reservationFeeName}{' '}
          </>
        );
        break;
      default:
        break;
    }

    return (
      <div>
        <div
          className={`category gr-relative pointer ${
            displayCategoryDetails ? 'gr-cursor-pointer' : ''
          }`}
          tabIndex={0}
          aria-label={`Seat Category: ${name}. Description: ${description} Price range: ${priceRangeAria}`}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
        >
          {image_url ? (
            <CategoryImage
              index={index}
              img_url={image_url}
              name={name}
              rank={rank}
            ></CategoryImage>
          ) : null}
          <div
            aria-hidden="true"
            className="gr-flex gr-mb-6 gr-text-base gr-items-start gr-gap-4"
          >
            <Box
              backgroundColor={backgroundColor}
              borderColor={borderColor}
            ></Box>
            <CategoryInfo
              name={name}
              description={description}
              range={range}
              displayPriceBelow={displayPriceBelow && !hideDetails}
              reservationFeeText={fullReservationFeeText}
            />
          </div>
        </div>
        {displayCategoryDetails && (
          <CategoryDetails
            name={name}
            description={description}
            categoryDetails={benefits}
            image_url={image_url}
            image_urls={image_urls}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  currentPassenger: state.passengers.currentPassenger,
  priceAdjustment: state.session.priceAdjustment,
  variants: state.session.variants,
  useEnhancedDetails:
    state.itinerary.seatmapsBySegment[state.itinerary.currentSeatmap]
      ?.useEnhancedDetails,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Category);
