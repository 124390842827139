import React from 'react';
import PowerIcon from '../../shared/PowerIcon';
import ScreenIcon from '../../shared/ScreenIcon';
import RestrictedReclineIcon from '../../shared/RestrictedRecline';

function Amenity({ name, icon, characteristic }) {
  return (
    <div
      id={`${characteristic}-amenity`}
      className="gr-flex gr-text-secondary-text gr-mx-2"
    >
      <div className="gr-flex">{icon}</div>
      <div className="gr-flex">{name}</div>
    </div>
  );
}
export default function Amenities({ characteristics, desktop = false }) {
  const hasPowerOutlet = characteristics.includes('electronic_connection');
  const hasIndividualScreen = characteristics.includes('individual_screen');
  const hasRestrictedRecline = characteristics.includes('restricted_recline');
  const hasNoRecline = characteristics.includes('no_recline');
  const hasAnyAmenities = hasPowerOutlet || hasIndividualScreen;
  if (!hasAnyAmenities) {
    return null;
  }

  const padding = desktop ? 'gr-pt-4' : 'gr-pt-2';
  return (
    <span
      className={`gr-flex gr-w-full gr-text-xs gr-justify-center gr-border-t gr-border-grey gr-mt-2 ${padding}`}
    >
      {hasPowerOutlet && (
        <Amenity
          name="AC Power"
          icon={<PowerIcon />}
          characteristic="electronic_connection"
        />
      )}
      {hasIndividualScreen && (
        <Amenity
          name="Personal Screen"
          icon={<ScreenIcon />}
          characteristic="individual_screen"
        />
      )}
      {hasRestrictedRecline && (
        <Amenity
          name="Limited Recline"
          icon={<RestrictedReclineIcon />}
          characteristic="restricted_recline"
        />
      )}
      {hasNoRecline && (
        <Amenity
          name="No Recline"
          icon={<RestrictedReclineIcon />}
          characteristic="restricted_recline"
        />
      )}
    </span>
  );
}
