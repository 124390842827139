import React, { Component } from 'react';
import { Benefits } from './SeatDetails/Benefits';
import Marquee from 'react-fast-marquee';

export class CategoryDetails extends Component {
  render() {
    const {
      name,
      description,
      categoryDetails = null,
      image_url = null,
      image_urls = []
    } = this.props;

    const availableImage = image_url || image_urls?.[0];
    const displaySingleImage =
      image_urls?.length === 1 || (!image_urls?.length && image_url);

    const getImageElement = imgURL => (
      <img
        alt={''}
        src={imgURL}
        width={'260px'}
        className={'gr-mr-1 gr-rounded-lg'}
      />
    );

    return (
      <div
        className={`gr-z-100 gr-h-fit gr-shadow-inner gr-justify-center gr-items-center gr-h-auto gr-rounded-lg gr-bg-white gr-shadow-categories gr-font-inter`}
        id={'category-details'}
        tabIndex={0}
        style={{
          width: `${!availableImage || displaySingleImage ? '260px' : '500px'}`,
          top: '140px',
          left: '370px',
          position: 'fixed'
        }}
      >
        <div className="gr-p-3" style={{ borderBottomWidth: '1px' }}>
          {availableImage &&
            (displaySingleImage ? (
              getImageElement(image_urls[0] ?? image_url)
            ) : (
              <Marquee className={'gr-rounded-lg'}>
                {image_urls.map((img_url, index) => (
                  <div key={index}>{getImageElement(img_url)}</div>
                ))}
              </Marquee>
            ))}
          <h3
            className={`gr-text-black gr-font-semibold gr-text-base gr-mb-1 ${
              availableImage ? 'gr-mt-3' : ''
            }`}
          >
            {name}
          </h3>
          <span className={'gr-text-gray-500 gr-text-xs'}>{description}</span>
        </div>

        {categoryDetails && categoryDetails.length > 0 ? (
          <div className="gr-p-3 gr-pt-1">
            <Benefits descriptions={categoryDetails} isCategoryLegend={true} />{' '}
          </div>
        ) : null}
      </div>
    );
  }
}
