import React, { Component } from 'react';
import OnSeatClosePopup from './OnSeatClosePopup';
import { ReactComponent as RandomSeatIcon } from '../../svg/random.svg';
import Button from '../shared/Button';
import { ReactComponent as Close } from '../../svg/close.svg';
import { Translate } from 'react-localize-redux';
import {
  setShowSeatmapValidateInfant,
  setShowOnClosePopUp,
  setBasketUpdated,
  confirmBasket,
  showModal,
  clearBasket
} from '../../redux/actions';
import { connect, ConnectedProps } from 'react-redux';
import FocusTrap from 'focus-trap-react';
import MediaQuery from 'react-responsive';

const mapStateToProps = (state: any) => ({
  relativeWidthRatio: state.session.relativeWidthRatio,
  passengers: state.passengers,
  showOnClosePopUp: state.session.showOnClosePopUp
});

const mapDispatchToProps = {
  setShowSeatmapValidateInfant,
  setShowOnClosePopUp,
  confirmBasket,
  setBasketUpdated,
  showModal,
  clearBasket
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

interface SeatInfantValidationPopupProps extends PropsFromRedux {
  mobile?: boolean;
  parentHeight: number;
  position: any;
  exitModal: boolean;
}

export class OnCloseModal extends Component<SeatInfantValidationPopupProps> {
  render() {
    const { mobile = false, passengers = {}, showOnClosePopUp } = this.props;
    const popupIcon = <RandomSeatIcon />;
    const numPax = passengers.listOfPassenger.length;
    const primary =
      numPax > 1 ? (
        <Translate id="exit_modal_group_title" />
      ) : (
        <Translate id="exit_modal_single_pax_title" />
      );
    const secondary =
      numPax > 1 ? (
        <Translate id="exit_modal_group_desc" />
      ) : (
        <Translate id="exit_modal_single_pax_desc" />
      );

    const cancelButtonText =
      numPax > 1 ? (
        <Translate id="exit_modal_random_seat_group" />
      ) : (
        <Translate id="exit_modal_random_seat_single_pax" />
      );
    const randomlyAssignSeatText =
      numPax > 1 ? (
        <Translate id="exit_modal_select_seat_group" />
      ) : (
        <Translate id="exit_modal_select_seat_single_pax" />
      );

    const confirmButtonOnClick = () => {
      this.props.setShowOnClosePopUp({
        show: false
      });
      if (showOnClosePopUp.trigger === 'ON_NEXT') {
        showOnClosePopUp.next_function();
        return;
      }
      if (showOnClosePopUp.trigger === 'ON_EXIT') {
        if (showOnClosePopUp.opt_in) {
          this.props.showModal(false);
        } else {
          this.props.clearBasket();
        }
      }
    };
    const randomlyAssignSeatButton = (
      <Button primary={false} handleClick={confirmButtonOnClick}>
        {randomlyAssignSeatText}
      </Button>
    );

    const selectSeatButton = (
      <Button
        primary={true}
        handleClick={() => this.props.setShowOnClosePopUp({ show: false })}
      >
        {cancelButtonText}
      </Button>
    );

    const closeButton = (
      <button
        id="on-close-modal-exit-button"
        onClick={() => this.props.setShowOnClosePopUp({ show: false })}
      >
        <Close />
      </button>
    );

    return (
      <MediaQuery minWidth={650 / this.props.relativeWidthRatio}>
        {matches => (
          <FocusTrap
            focusTrapOptions={{ initialFocus: '#seat-validation-container' }}
            active={!mobile && matches && !this.props.exitModal}
          >
            <OnSeatClosePopup
              mobile={mobile}
              popupIcon={popupIcon}
              primary={primary}
              secondary={secondary}
              confirmButton={randomlyAssignSeatButton}
              cancelButton={selectSeatButton}
              closeButton={closeButton}
              id="on-close-popup"
              parentHeight={this.props.parentHeight}
              {...this.props.position}
            />
          </FocusTrap>
        )}
      </MediaQuery>
    );
  }
}

export default connector(OnCloseModal);
