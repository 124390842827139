import React, { Component } from 'react';
import { connect } from 'react-redux';

import { showCategories, showDesktopCategories } from '../../redux/actions';
import { Translate } from 'react-localize-redux';
import LegendIcon from '../shared/LegendIcon';
import MediaQuery from 'react-responsive';

class SeatLegend extends Component {
  handleClick = () => {
    this.props.showCategories(true);
  };

  shouldComponentUpdate(nextProps) {
    return (
      nextProps.modalShown !== this.props.modalShown ||
      nextProps.isModal !== this.props.isModal ||
      nextProps.relativeWidthRatio !== this.props.relativeWidthRatio
    );
  }

  render() {
    const SEATLEGEND_DISPLAY_BARRIER = 1119;
    return (this.props.modalShown && this.props.isModal) ||
      !this.props.isModal ? (
      <MediaQuery
        minWidth={SEATLEGEND_DISPLAY_BARRIER / this.props.relativeWidthRatio}
      >
        {(match) => {
          const whenToHide = match ? 'gr-hidden' : '';
          const onClick = match
            ? () =>
                this.props.showDesktopCategories(
                  !this.props.showingDesktopCategories,
                )
            : this.handleClick;
          return (
            <div
              onClick={onClick}
              className={`seat-legend ${whenToHide} gr-z-100 gr-border gr-bg-white gr-px-4 gr-cursor-pointer gr-rounded-lg gr-text-base gr-h-12 gr-flex gr-rounded gr-justify-around gr-items-center gr-shadow-lg`}
            >
              <div alt="categories" className="gr-mx-2">
                <LegendIcon />
              </div>
              <span className="gr-text-sm gr-text-title">
                <Translate id="seat_legend" />
              </span>
            </div>
          );
        }}
      </MediaQuery>
    ) : null;
  }
}

const mapStateToProps = (state) => ({
  modalShown: state.session.modalShown === true,
  isModal: state.session.isModal === true,
  variants: state.session.variants,
  showingCategories: state.session.showingCategories,
  showingDesktopCategories: state.session.showingDesktopCategories,
  relativeWidthRatio: state.session.relativeWidthRatio,
});

const mapDispatchToProps = {
  showCategories,
  showDesktopCategories,
};

export default connect(mapStateToProps, mapDispatchToProps)(SeatLegend);
