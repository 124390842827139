import React, { PureComponent } from 'react';
import GenericDetailsContainer from './GenericDetailsContainer';

export interface IGenericDetailsProps {
  parentHeight: number;
  position: any;
  idPrefix: string;
  popupIcon: React.ReactNode;
  primary: React.ReactNode;
  secondary: React.ReactNode;
  mobile: boolean;
}

export class GenericDetails extends PureComponent<IGenericDetailsProps> {
  render() {
    const {
      parentHeight,
      position,
      idPrefix,
      popupIcon,
      primary,
      secondary,
      mobile
    } = this.props;

    return (
      <GenericDetailsContainer
        idPrefix={`${idPrefix}-details`}
        mobile={mobile}
        parentHeight={parentHeight}
        {...position}
      >
        <div
          id={`${idPrefix}-details`}
          className="seat-detail gr-flex gr-items-center gr-justify-between gr-p-3 gr-py-4"
        >
          <div className="gr-flex gr-justify-center gr-w-1/5">{popupIcon}</div>

          <div className="gr-flex gr-flex-col gr-justify-start gr-w-4/5">
            <div className="gr-flex-col gr-pr-2">
              <span className="gr-flex gr-text-title gr-py-1 gr-font-semibold">
                {primary}
              </span>
              <span className="gr-flex gr-text-secondary-text gr-flex-shrink-0">
                {secondary}
              </span>
            </div>
          </div>
        </div>
      </GenericDetailsContainer>
    );
  }
}

export default GenericDetails;
