import React, { useState } from 'react';
import CabinBagIcon from './CabinBag';
import CheckedBagIcon from './CheckedBag';
import { ReactComponent as HeightRuler } from '../../svg/height-dimension-ruler.svg';
import { ReactComponent as WidthRuler } from '../../svg/width-dimension-ruler.svg';
import { ReactComponent as LengthRuler } from '../../svg/length-dimension-ruler.svg';
import { BagDetails } from './CommonTypes';
import { Translate } from 'react-localize-redux';

export function BagImage({
  bagDetails,
  useTotalDimensions = false,
  smallWidth = false,
}: {
  bagDetails: BagDetails;
  useTotalDimensions: boolean;
  smallWidth?: boolean;
}) {
  const [ratio] = useState(1);

  let minWidth = '0px';
  if (!smallWidth) {
    if (bagDetails.checked) {
      if (
        useTotalDimensions ||
        (!bagDetails.length && !bagDetails.width && !bagDetails.height)
      ) {
        minWidth = '120px';
      } else {
        minWidth = '160px';
      }
    } else {
      if (
        useTotalDimensions ||
        (!bagDetails.length && !bagDetails.width && !bagDetails.height)
      ) {
        minWidth = '90px';
      } else {
        minWidth = '120px';
      }
    }
  }

  return (
    <div
      className="gr-bag-dimension-text gr-self-center gr-text-gray-550 gr-text-xs gr-not-italic gr-font-normal gr-leading-normal gr-self-center"
      style={{
        minWidth,
      }}
    >
      {bagDetails.checked ? (
        <div className="gr-relative">
          {bagDetails.length || useTotalDimensions ? (
            <div
              className="gr-absolute dimension-ruler"
              style={{
                width: `${96 * ratio}px`,
                top: `${148 * ratio}px`,
              }}
            >
              <LengthRuler />
            </div>
          ) : null}
          {bagDetails.length || useTotalDimensions ? (
            <span
              className="gr-absolute dimension-text"
              style={{
                top: `${158 * ratio}px`,
                left:
                  useTotalDimensions || !bagDetails.length
                    ? `${47 * ratio}px`
                    : `${31 * ratio}px`,
              }}
            >
              {useTotalDimensions ? (
                <Translate id={'l'}>l</Translate>
              ) : (
                bagDetails.length + ' ' + bagDetails.dimension_unit
              )}
            </span>
          ) : null}

          {bagDetails.width || useTotalDimensions ? (
            <div
              className="gr-absolute dimension-ruler"
              style={{
                width: `${14 * ratio}px`,
                top: `${126 * ratio}px`,
                left: `${-17 * ratio}px`,
              }}
            >
              <WidthRuler />
            </div>
          ) : null}
          {bagDetails.width || useTotalDimensions ? (
            <span
              className="gr-absolute dimension-text"
              style={{
                top: `${132 * ratio}px`,
                left:
                  useTotalDimensions || !bagDetails.width
                    ? `${-26 * ratio}px`
                    : `${ratio * -56}px`,
              }}
            >
              {useTotalDimensions ? (
                <Translate id={'w'}>w</Translate>
              ) : (
                bagDetails.width + ' ' + bagDetails.dimension_unit
              )}
            </span>
          ) : null}

          {bagDetails.height || useTotalDimensions ? (
            <div
              className="gr-absolute dimension-ruler"
              style={{
                width: `${7 * ratio}px`,
                left: `${106 * ratio}px`,
                top: `${10 * ratio}px`,
              }}
            >
              <HeightRuler />
            </div>
          ) : null}
          {bagDetails.height || useTotalDimensions ? (
            <span
              className="gr-absolute dimension-text"
              style={{
                top: `${58 * ratio}px`,
                left: `${ratio * 118}px`,
                minWidth: `50px`,
              }}
            >
              {useTotalDimensions ? (
                <Translate id={'h'}>h</Translate>
              ) : (
                bagDetails.height + ' ' + bagDetails.dimension_unit
              )}
            </span>
          ) : null}

          <div
            style={{
              width: `${96 * ratio}px`,
              height: `${138 * ratio}px`,
              //            minHeight: '179px'
            }}
          >
            <CheckedBagIcon />
          </div>
        </div>
      ) : (
        <div className="gr-relative">
          {bagDetails.length || useTotalDimensions ? (
            <div
              className="gr-absolute dimension-ruler"
              style={{
                width: `${60 * ratio}px`,
                top: `${98 * ratio}px`,
              }}
            >
              <LengthRuler />
            </div>
          ) : null}
          {bagDetails.length || useTotalDimensions ? (
            <span
              className="gr-absolute dimension-text"
              style={{
                top: `${110 * ratio}px`,
                left:
                  useTotalDimensions || !bagDetails.length
                    ? `${29 * ratio}px`
                    : `${17 * ratio}px`,
                minWidth: '50px',
              }}
            >
              {useTotalDimensions ? (
                <Translate id={'l'}>l</Translate>
              ) : (
                bagDetails.length + ' ' + bagDetails.dimension_unit
              )}
            </span>
          ) : null}
          {bagDetails.width || useTotalDimensions ? (
            <div
              className="gr-absolute dimension-ruler"
              style={{
                width: `${14 * ratio}px`,
                top: `${88 * ratio}px`,
                left: `${-17 * ratio}px`,
              }}
            >
              <WidthRuler />
            </div>
          ) : null}
          {bagDetails.width || useTotalDimensions ? (
            <span
              className="gr-absolute dimension-text"
              style={{
                top: `${94 * ratio}px`,
                left:
                  useTotalDimensions || !bagDetails.width
                    ? `${-24 * ratio}px`
                    : `${ratio * -54}px`,
              }}
            >
              {useTotalDimensions ? (
                <Translate id={'w'}>w</Translate>
              ) : (
                bagDetails.width + ' ' + bagDetails.dimension_unit
              )}
            </span>
          ) : null}
          {bagDetails.height || useTotalDimensions ? (
            <div
              className="gr-absolute dimension-ruler"
              style={{
                width: `${5 * ratio}px`,
                left: `${70 * ratio}px`,
                top: `${8 * ratio}px`,
              }}
            >
              <HeightRuler />
            </div>
          ) : null}
          {bagDetails.height || useTotalDimensions ? (
            <span
              className="gr-absolute dimension-text"
              style={{
                top: `${46 * ratio}px`,
                left: `${ratio * 79}px`,
                minWidth: `50px`,
              }}
            >
              {useTotalDimensions ? (
                <Translate id={'h'}>h</Translate>
              ) : (
                bagDetails.height + ' ' + bagDetails.dimension_unit
              )}
            </span>
          ) : null}
          <div
            style={{
              width: `${60 * ratio}px`,
              height: `${98 * ratio}px`,
              //             minHeight: '139.65px'
            }}
          >
            <CabinBagIcon />
          </div>
        </div>
      )}
    </div>
  );
}
