import { createActions, createAction } from 'redux-actions';
import {
  buildUpsellCallbackElement,
  didUpsell,
} from '../components/seatmap/UpsellUtils';
import { SeatPrice } from './enums';

export const SET_LOCALE = 'SET_LOCALE';
export const SET_SESSION_DATA = 'SET_SESSION_DATA';
export const SET_BAGGAGE_DATA = 'SET_BAGGAGE_DATA';
export const SET_ITINERARY = 'SET_ITINERARY';
export const SET_FLIGHTS = 'SET_FLIGHTS';
export const SET_CURRENT_SEAT_MAP = 'SET_CURRENT_SEAT_MAP';
export const SET_SEAT_CATEGORIES = 'SET_SEAT_CATEGORIES';
export const SET_CURRENT_PASSENGER = 'SET_CURRENT_PASSENGER';
export const SET_BAGGAGE_DISCLAIMERS = 'SET_BAGGAGE_DISCLAIMERS';
export const UPDATE_SELECTED_SEAT = 'UPDATE_SELECTED_SEAT';
export const UPDATE_SEAT_DETAILS_POSITION = 'UPDATE_SEAT_DETAILS_POSITION';
export const ACCEPT_SEAT = 'ACCEPT_SEAT';
export const REMOVE_SEAT_FROM_BASKET = 'REMOVE_SEAT_FROM_BASKET';
export const NEXT_SEATMAP = 'NEXT_SEATMAP';
export const SET_PASSENGERS = 'SET_PASSENGERS';
export const SET_SEATMAPS = 'SET_SEATMAPS';
export const CREATE_BASKET_ID = 'CREATE_BASKET_ID';
export const NEXT_PASSENGER = 'NEXT_PASSENGER';
export const SHOW_MODAL = 'SHOW_MODAL';
export const SET_HIDE_ON_ACCEPT = 'SET_HIDE_ON_ACCEPT';
export const SET_LOADING = 'SET_LOADING';
export const IS_MOBILE = 'IS_MOBILE';
export const SHOW_CATEGORIES = 'SHOW_CATEGORIES';
export const HAS_SEATS = 'HAS_SEATS';
export const SET_VARIANTS = 'SET_VARIANTS';
export const SET_THEME = 'SET_THEME';
export const SET_TEXT_OVERRIDES = 'SET_TEXT_OVERRIDES';
export const SEAT_MAP_SCROLLED = 'SEAT_MAP_SCROLLED';
export const SET_SHOULD_HIDE_PRICE = 'SET_SHOULD_HIDE_PRICE';
export const SHOW_EXIT_MODAL = 'SHOW_EXIT_MODAL';
export const ACCEPT_EXIT_REGULATION = 'ACCEPT_EXIT_REGULATION';
export const COMPARTMENT_HEIGHT = 'COMPARTMENT_HEIGHT';
export const SET_RELATIVE_WIDTH_RATIO = 'SET_RELATIVE_WIDTH_RATIO';
export const SET_ACCEPTED_PRODUCTS = 'SET_ACCEPTED_PRODUCTS';
export const SET_BASKET = 'SET_BASKET';
export const SET_BAGGAGE_BASKET = 'SET_BAGGAGE_BASKET';
export const ADD_SEATING_CHOICE_TO_BASKET = 'ADD_SEATING_CHOICE_TO_BASKET';
export const REMOVE_SEATING_CHOICE_FROM_BASKET =
  'REMOVE_SEATING_CHOICE_FROM_BASKET';
export const SET_IS_MODAL = 'SET_IS_MODAL';
export const SET_SHOULD_ABBREVIATE_SEAT_PRICE =
  'SET_SHOULD_ABBREVIATE_SEAT_PRICE';
export const SET_EDIT_MODE = 'SET_EDIT_MODE';
export const SET_AGENT_BRAND = 'SET_AGENT_BRAND';
export const SET_OFFERED_PRODUCTS = 'SET_OFFERED_PRODUCTS';
export const SET_ACTIVE_WIDGET = 'SET_ACTIVE_WIDGET';
export const CLEAR_BASKET = 'CLEAR_BASKET';
export const SET_WAITING_ON_AGENT = 'SET_WAITING_ON_AGENT';
export const SHOW_DESKTOP_CATEGORIES = 'SHOW_DESKTOP_CATEGORIES';
export const SET_SEAT_MAP_STEP = 'SET_SEAT_MAP_STEP';
export const SHOW_SEAT_MAP = 'SHOW_SEAT_MAP';
export const BASKET_CHANGE = 'BASKET_CHANGE';
export const FINISH_BASKET_CHANGE = 'FINISH_BASKET_CHANGE';
export const INCR_BAG_FOR_PASSENGER = 'INCR_BAG_FOR_PASSENGER';
export const DECR_BAG_FOR_PASSENGER = 'DECR_BAG_FOR_PASSENGER';
export const SET_BAGGAGE_PASSENGERS = 'SET_BAGGAGE_PASSENGERS';
export const HAS_BAGS = 'HAS_BAGS';
export const SET_SEGMENT_COLUMNS = 'SET_SEGMENT_COLUMNS';
export const SET_SHOW_SEATMAP_VALIDATE_INFANT =
  'SET_SHOW_SEATMAP_VALIDATE_INFANT';
export const SET_SHOW_SEATMAP_VALIDATE_GROUP =
  'SET_SHOW_SEATMAP_VALIDATE_GROUP';
export const SET_PRICE_ADJUSTMENT = 'SET_PRICE_ADJUSTMENT';
export const SET_BASKET_UPDATED = 'SET_BASKET_UPDATED';
export const SET_PRICE_TEXT_PREPEND = 'SET_PRICE_TEXT_PREPEND';
export const SET_UNIQUE_CHARACTERISTICS = 'SET_UNIQUE_CHARACTERISTICS';
export const SET_IS_ENHANCED = 'SET_IS_ENHANCED';
export const SHOW_UPSELL_MODAL = 'SHOW_UPSELL_MODAL';
export const ACCEPT_UPSELL_MODAL = 'ACCEPT_UPSELL_MODAL';
export const ADD_UPSELL_OPTIONS = 'ADD_UPSELL_OPTIONS';
export const SET_SEGMENTS_CURRENT_COMPARTMENT =
  'SET_SEGMENTS_CURRENT_COMPARTMENT';
export const SET_SEGMENTS_INITIAL_COMPARTMENT =
  'SET_SEGMENTS_INITIAL_COMPARTMENT';
export const SET_IN_FOCUS_COMPARTMENT = 'SET_IN_FOCUS_COMPARTMENT';
export const ADD_RESTRICTIONS = 'ADD_RESTRICTIONS';
// Map to identity payload creator, i.e., setX(y) = {type: SET_X, payload: y}
export const SET_SHOW_ON_CLOSE_POP_UP = 'SET_SHOW_ON_CLOSE_POP_UP';
export const SET_CURRENT_BAGGAGE_JOURNEY = 'SET_CURRENT_BAGGAGE_JOURNEY';
export const SET_BAGGAGE_JOURNEYS = 'SET_BAGGAGE_JOURNEYS';
export const SET_MAX_PRODUCT_PRICE = 'SET_MAX_PRODUCT_PRICE';
export const SET_CURRENCY = 'SET_CURRENCY';
export const SET_FONT_FAMILY = 'SET_FONT_FAMILY';

export const {
  setLocale,
  setBaggageData,
  setItinerary,
  setFlights,
  setCurrentSeatMap,
  setSeatCategories,
  setCurrentPassenger,
  setBaggageDisclaimers,
  updateSelectedSeat,
  updateSeatDetailsPosition,
  setPassengers,
  createBasketId,
  nextPassenger,
  setLoading,
  isMobile,
  showCategories,
  hasSeats,
  setVariants,
  setTheme,
  setTextOverrides,
  seatMapScrolled,
  setShouldHidePrice,
  showExitModal,
  acceptExitRegulation,
  compartmentHeight,
  setRelativeWidthRatio,
  setAcceptedProducts,
  setBasket,
  setBaggageBasket,
  setIsModal,
  setShouldAbbreviateSeatPrice,
  setEditMode,
  setAgentBrand,
  setOfferedProducts,
  setActiveWidget,
  showDesktopCategories,
  incrBagForPassenger,
  decrBagForPassenger,
  setBaggagePassengers,
  finishBasketChange,
  hasBags,
  setSegmentColumns,
  setShowSeatmapValidateInfant,
  setShowSeatmapValidateGroup,
  setPriceAdjustment,
  setBasketUpdated,
  setPriceTextPrepend,
  setUniqueCharacteristics,
  showUpsellModal,
  acceptUpsellModal,
  addUpsellOptions,
  setSegmentsCurrentCompartment,
  setSegmentsInitialCompartment,
  setInFocusCompartment,
  addRestrictions,
  addSeatingChoiceToBasket,
  removeSeatingChoiceFromBasket,
  setIsEnhanced,
  setShowOnClosePopUp,
  setCurrentBaggageJourney,
  setBaggageJourneys,
  setMaxProductPrice,
  setCurrency,
  setFontFamily,
} = createActions(
  SET_LOCALE,
  SET_SESSION_DATA,
  SET_BAGGAGE_DATA,
  SET_ITINERARY,
  SET_FLIGHTS,
  SET_CURRENT_SEAT_MAP,
  SET_SEAT_CATEGORIES,
  SET_CURRENT_PASSENGER,
  SET_BAGGAGE_DISCLAIMERS,
  UPDATE_SELECTED_SEAT,
  UPDATE_SEAT_DETAILS_POSITION,
  SET_PASSENGERS,
  SET_SEATMAPS,
  CREATE_BASKET_ID,
  NEXT_PASSENGER,
  SET_HIDE_ON_ACCEPT,
  SET_LOADING,
  IS_MOBILE,
  SHOW_CATEGORIES,
  HAS_SEATS,
  SET_VARIANTS,
  SET_THEME,
  SET_TEXT_OVERRIDES,
  SEAT_MAP_SCROLLED,
  SET_SHOULD_HIDE_PRICE,
  SHOW_EXIT_MODAL,
  ACCEPT_EXIT_REGULATION,
  COMPARTMENT_HEIGHT,
  SET_RELATIVE_WIDTH_RATIO,
  SET_ACCEPTED_PRODUCTS,
  SET_BASKET,
  SET_BAGGAGE_BASKET,
  SET_IS_MODAL,
  SET_SHOULD_ABBREVIATE_SEAT_PRICE,
  SET_EDIT_MODE,
  SET_AGENT_BRAND,
  SET_OFFERED_PRODUCTS,
  SET_ACTIVE_WIDGET,
  SHOW_DESKTOP_CATEGORIES,
  INCR_BAG_FOR_PASSENGER,
  DECR_BAG_FOR_PASSENGER,
  SET_BAGGAGE_PASSENGERS,
  FINISH_BASKET_CHANGE,
  HAS_BAGS,
  SET_SEGMENT_COLUMNS,
  SET_SHOW_SEATMAP_VALIDATE_INFANT,
  SET_SHOW_SEATMAP_VALIDATE_GROUP,
  SET_PRICE_ADJUSTMENT,
  SET_BASKET_UPDATED,
  SET_PRICE_TEXT_PREPEND,
  SET_UNIQUE_CHARACTERISTICS,
  SHOW_UPSELL_MODAL,
  ACCEPT_UPSELL_MODAL,
  ADD_UPSELL_OPTIONS,
  SET_SEGMENTS_CURRENT_COMPARTMENT,
  SET_SEGMENTS_INITIAL_COMPARTMENT,
  SET_IN_FOCUS_COMPARTMENT,
  ADD_RESTRICTIONS,
  ADD_SEATING_CHOICE_TO_BASKET,
  REMOVE_SEATING_CHOICE_FROM_BASKET,
  SET_IS_ENHANCED,
  SET_SHOW_ON_CLOSE_POP_UP,
  SET_CURRENT_BAGGAGE_JOURNEY,
  SET_BAGGAGE_JOURNEYS,
  SET_MAX_PRODUCT_PRICE,
  SET_CURRENCY,
  SET_FONT_FAMILY,
);

export const nextSeatmap = createAction(
  NEXT_SEATMAP,
  (action) => action,
  (action) => ({
    track: {
      event: 'Next Seatmap',
      props: { segment_id: action },
    },
  }),
);

export const removeSeatFromBasket = createAction(
  REMOVE_SEAT_FROM_BASKET,
  (action) => action,
  ({ selectedSeat: seat, currentSeatmap: segment_id }) => ({
    track: {
      event: 'Product Removed',
      props: { seat, segment_id },
    },
  }),
);

export const acceptSeat = createAction(
  ACCEPT_SEAT,
  (action) => action,
  ({
    selectedSeat: seat,
    currentSeatmap: segment_id,
    currentPassenger: passenger,
    selectedProduct,
  }) => ({
    track: {
      event: 'Product Selected',
      props: { seat, segment_id, passenger, selectedProduct },
    },
  }),
);

export const showModal = createAction(
  SHOW_MODAL,
  (action) => action,
  (action) => ({
    track: {
      event: action ? 'Show Modal' : 'Hide Modal',
      props: {
        shown: action,
      },
    },
  }),
);

export const showSeatmap = createAction(
  SHOW_SEAT_MAP,
  (action) => action,
  (action) => ({
    track: {
      event: 'Seatmap Shown',
      props: {
        displayType: action.displayType,
        isEnhanced: action.isEnhanced,
        variants: action.variants,
      },
    },
  }),
);

export const setSeatMapStep = createAction(
  SET_SEAT_MAP_STEP,
  (action) => action,
  (action) => ({
    track: {
      event: 'Set Seatmap Step',
      props: action,
    },
  }),
);

const sendMetricData = async (state, action, newBasket, gordianInternal) => {
  // Calculate metrics to be sent to Mixpanel
  const dispatchInfo = { event: '', props: {}, isMultipleEvents: false };

  if (newBasket.seats?.length === 0) {
    // Check if basket is empty (has been cleared)
    dispatchInfo.event = 'Basket Cleared';
    dispatchInfo.props = action;
    return dispatchInfo;
  }

  try {
    // Catch any errors to ensure that a dispatch is sent
    const mixpanelMetrics = {};
    let pax_count = 1; // Send pax_count to mixpanel
    state.itinerary.flights.forEach((flight) => {
      // Interate to the seat level of seatJSON to get counts
      const { segment_id, available } = flight;
      const seatmaps = state.itinerary.seatmapsBySegment;
      const flightCategories = state.categories[segment_id];
      if (available && seatmaps[segment_id].available) {
        const categoriesData = {};
        for (let categoryId in flightCategories) {
          const category = flightCategories[categoryId];
          categoriesData[category.seat_category_id] = {
            name: category.name,
            rank: category.rank,
            total: 0,
          };
        }
        const metricData = {
          totalSeats: 0,
          bookableSeats: 0,
          freeSeats: 0,
          freeMarkupSeats: 0,
          paidSeats: 0,
          paidMarkupSeats: 0,
          categories: categoriesData,
        };
        seatmaps[segment_id].decks.forEach((deck) => {
          deck.compartments.forEach((compartment) => {
            compartment.seat_rows.forEach((row) => {
              if (row.row !== null) {
                row.row_groups.forEach((section) => {
                  section.forEach((seat) => {
                    metricData.totalSeats++;
                    const productInfo =
                      state.products.products.seat[seat.product_id];
                    if (
                      seat.bookable_seat &&
                      Object.values(productInfo.price_and_availability)[0]
                        ?.available
                    ) {
                      metricData.bookableSeats++;
                      // Increase total for category
                      if (seat.seat_category_id) {
                        metricData.categories[seat.seat_category_id].total++;
                      }
                      // Increase respective price totals
                      const price = Object.values(
                        productInfo.price_and_availability,
                      )[0].price;
                      pax_count = Object.keys(
                        productInfo.price_and_availability,
                      ).length; // Update pax_count
                      if (price?.total.amount > 0) {
                        const markupAmount = price.markups.reduce(
                          (accumulator, curr) => accumulator + curr.amount,
                          0,
                        );
                        if (price.total.amount === markupAmount) {
                          metricData.freeMarkupSeats++;
                        } else {
                          metricData.paidSeats++;
                          if (markupAmount > 0) {
                            metricData.paidMarkupSeats++;
                          }
                        }
                      } else {
                        metricData.freeSeats++;
                      }
                    }
                  });
                });
              }
            });
          });
        });
        mixpanelMetrics[segment_id] = metricData;
      }
    });

    const dispatchProps = [];
    newBasket.seats.forEach((seat) => {
      const seat_metrics = {
        type: '', // SeatPrice Enum: free, free_with_markup, paid_with_markup, paid_w/o_markup
        available_seats_percent: 0.0, // Available / Total * 100
        free_seats_percent: 0.0, // Free / Total Available * 100
        free_markup_seats_percent: 0.0, // Free_With_Markup / Total Available * 100
        paid_seats_percent: 0.0, // Paid / Total Available * 100
        paid_markup_seats_percent: 0.0, // Markup / Total Available * 100
      };

      const metricData = mixpanelMetrics[seat.segment_id];

      const price = seat.price_and_availability[seat.passenger_id].price; // Determine what type of seat was chosen
      if (price.total > 0) {
        seat_metrics.type =
          price.markup_amount > 0
            ? price.markup_amount === price.total
              ? SeatPrice.FreeWithMarkup
              : SeatPrice.PaidWithMarkup
            : SeatPrice.PaidWithoutMarkup;
      } else {
        seat_metrics.type = SeatPrice.Free;
      }

      seat_metrics.available_seats_percent =
        Math.round((metricData.bookableSeats / metricData.totalSeats) * 1000) /
        10;
      seat_metrics.free_seats_percent =
        Math.round((metricData.freeSeats / metricData.bookableSeats) * 1000) /
        10;
      seat_metrics.free_markup_seats_percent =
        Math.round(
          (metricData.freeMarkupSeats / metricData.bookableSeats) * 1000,
        ) / 10;
      seat_metrics.paid_seats_percent =
        Math.round((metricData.paidSeats / metricData.bookableSeats) * 1000) /
        10;
      seat_metrics.paid_markup_seats_percent =
        Math.round(
          (metricData.paidMarkupSeats / metricData.bookableSeats) * 1000,
        ) / 10;

      // Get price info for tracking
      const price_metrics = {
        base_price: price.base_price / Math.pow(10, price.decimal_places),
        markup_amount: price.markup_amount / Math.pow(10, price.decimal_places),
        total: price.total / Math.pow(10, price.decimal_places),
        markup_percent:
          price.base_price > 0
            ? Math.round((price.markup_amount / price.base_price) * 1000) / 10
            : 0.0,
      };

      const category_percent_metrics = {};
      for (let categoryId in metricData.categories) {
        const categoryData = metricData.categories[categoryId];
        category_percent_metrics[categoryData.name] =
          Math.round((categoryData.total / metricData.bookableSeats) * 1000) /
          10;
      }

      dispatchProps.push({
        pax_count,
        seat_metrics,
        price_metrics,
        category_percent_metrics,
      });
    });
    dispatchInfo.event = 'Seat Confirmed';
    dispatchInfo.props = dispatchProps; // Set dispatch props to array of props
    dispatchInfo.isMultipleEvents = true;
  } catch (e) {
    // If an error occurs then ensure dispatch is still sent
    gordianInternal.onError(e);
    dispatchInfo.isMultipleEvents = false;
    dispatchInfo.event = 'Basket Change with No Metrics';
    dispatchInfo.props = action;
  }

  return dispatchInfo;
};

const basketChange = async (
  dispatch,
  getState,
  gordianInternal,
  action,
  getNewBasket,
) => {
  let state = getState();
  if (state.session.basketChanging) {
    return; // prevent double-taps, or somebody clicking outside the modal immediately after confirming their seats
  }

  var newBasket = getNewBasket(state);
  // Get Seat Metrics
  const dispatchInfo = await sendMetricData(
    state,
    action,
    newBasket,
    gordianInternal,
  );
  dispatch({
    // Send dispatch with any metrics
    type: BASKET_CHANGE,
    payload: {},
    meta: {
      track: dispatchInfo,
    },
  });

  try {
    await gordianInternal.onBasketChange(undefined, newBasket);
    // build a list of upsold legs, then pass into upsellTickets.
    const upsoldLegs = [];
    for (const segmentId in state.session.currentCompartments) {
      const upsold = state.session.currentCompartments[segmentId];
      if (didUpsell(upsold)) {
        if (gordianInternal.upsellTickets) {
          const initial = state.session.initialCompartments[segmentId];

          upsoldLegs.push(buildUpsellCallbackElement(upsold, initial));
        } else {
          gordianInternal.onError(
            new Error(
              'Attempted to upsell a seat without attaching an upsellTickets callback',
            ),
          );
        }
      }
    }
    if (upsoldLegs.length && gordianInternal.upsellTickets) {
      await gordianInternal.upsellTickets(upsoldLegs).catch((error) => {
        gordianInternal.onError(error);
      });
    }
  } catch (e) {
    gordianInternal.onError(e);
  }
  if (state.session.isModal) {
    dispatch(showModal(false));
  }
  dispatch(finishBasketChange());
};

export const clearBasket = (action) => {
  return async (dispatch, getState, { gordianInternal }) => {
    dispatch({
      type: CLEAR_BASKET,
      payload: {},
      meta: {},
    });
    const getNewBasket = (state) => ({ ...state.basket, seats: [] });
    return basketChange(
      dispatch,
      getState,
      gordianInternal,
      action,
      getNewBasket,
    );
  };
};

export const confirmBasket = (action) => {
  return async (dispatch, getState, { gordianInternal }) => {
    const getNewBasket = (state) => state.basket;
    return basketChange(
      dispatch,
      getState,
      gordianInternal,
      action,
      getNewBasket,
    );
  };
};
