import React, { Component } from 'react';
import { connect } from 'react-redux';
import { CategoriesLoader } from '../shared/Loaders';
import MediaQuery from 'react-responsive';
import CategoriesList from './CategoriesList';
import { rtlLangs } from '../../translations/rtl-langs';

export class Categories extends Component {
  render() {
    const {
      currentSeatmap,
      categories,
      showingDesktopCategories,
      theme,
      relativeWidthRatio,
      locale
    } = this.props;

    const { test_mobile_simple = true } = this.props.variants;
    const simpleVariant = test_mobile_simple;
    const CATEGORIES_DISPLAY_BARRIER = 1119;

    return this.props.loading ? (
      <CategoriesLoader />
    ) : (
      <MediaQuery maxWidth={CATEGORIES_DISPLAY_BARRIER / relativeWidthRatio}>
        {matchMaxSmallWidth => {
          const whetherToHide =
            matchMaxSmallWidth || !showingDesktopCategories ? 'gr-hidden' : '';
          const margin = rtlLangs.includes(locale)
            ? 'sm:gr-mr-10'
            : 'sm:gr-ml-10';
          return (
            <div
              className={`categories gr-sticky gr-z-100 gr-h-fit gr-shadow-inner ${whetherToHide} ${margin} gr-justify-center gr-items-center height-md:gr-p-6 gr-p-8 gr-h-auto gr-rounded-lg gr-bg-white gr-shadow-categories`}
              tabIndex={0}
              style={{ width: '320px', top: '0px' }}
            >
              <CategoriesList
                currentSeatmap={currentSeatmap}
                categories={categories}
                simpleVariant={simpleVariant}
                theme={theme}
              />
            </div>
          );
        }}
      </MediaQuery>
    );
  }
}

const mapStateToProps = state => ({
  categories: state.categories,
  loading: state.session.loading,
  currentSeatmap: state.itinerary.currentSeatmap,
  theme: state.session.theme,
  variants: state.session.variants || {},
  showingDesktopCategories: state.session.showingDesktopCategories,
  relativeWidthRatio: state.session.relativeWidthRatio,
  locale: state.session.locale
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Categories);
