import React from 'react';
import ContentLoader from 'react-content-loader';

export const SeatLoader = () => (
  <ContentLoader
    speed={4}
    style={{ width: '100%', height: '100%' }}
    viewBox="0 0 40 40"
    backgroundColor="#f3f3f3"
    foregroundColor="#ecebeb"
  >
    <rect x="0" y="0" width="40" height="40" />
  </ContentLoader>
);

export const CategoriesLoader = () => (
  <div className="categories gr-absolute gr-hidden g-top-0 lg:gr-block gr-px-5 gr-pt-8 gr-ml-8 gr-my-8 gr-left-0 gr-h-auto gr-rounded-lg gr-bg-white shadow-categories">
    <ContentLoader
      speed={2}
      width={280}
      height={250}
      viewBox="0 0 320 300"
      backgroundColor="#f3f3f3"
      foregroundColor="#ecebeb"
    >
      <rect x="48" y="8" rx="3" ry="3" width="88" height="6" />
      <rect x="48" y="26" rx="3" ry="3" width="52" height="6" />
      <rect x="0" y="56" rx="3" ry="3" width="410" height="6" />
      <rect x="0" y="72" rx="3" ry="3" width="380" height="6" />
      <rect x="0" y="88" rx="3" ry="3" width="178" height="6" />
      <rect x="48" y="128" rx="3" ry="3" width="88" height="6" />
      <rect x="48" y="146" rx="3" ry="3" width="52" height="6" />
      <rect x="0" y="176" rx="3" ry="3" width="410" height="6" />
      <rect x="0" y="192" rx="3" ry="3" width="380" height="6" />
      <rect x="0" y="208" rx="3" ry="3" width="178" height="6" />
      <rect x="0" y="120" rx="0" ry="0" width="40" height="40" />
      <rect x="0" y="0" rx="0" ry="0" width="40" height="40" />
    </ContentLoader>
  </div>
);
