import React from 'react';

export function Backdrop({ transparent = false }: { transparent: boolean }) {
  return (
    <div
      className={
        'gr-fixed gr-top-0 gr-left-0 gr-w-full gr-h-full gr-rounded-lg'
      }
      style={{
        backgroundColor: !transparent ? 'rgba(0, 0, 0, 0.5)' : undefined,
        zIndex: 99,
      }}
    />
  );
}
