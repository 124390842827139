import { ReduxInterfaces } from './stateInterface';

export const initialSessionState: ReduxInterfaces.SessionInterface = {
  loading: false,
  exitModal: false,
  acceptedExitRegulation: false,
  hasSeats: true,
  hasBags: true,
  parentHeight: 0,
  relativeWidthRatio: 1,
  modalShown: false,
  isMobile: false,
  showingCategories: false,
  activeWidget: null,
  basketChanging: false,
  onBasketChange: () => {},
  variants: {},
  showingDesktopCategories: true,
  seatMapStep: 'introduction',
  locale: null,
  uniqueCharacteristics: [],
  activeFilters: {
    // Add other potential filters for the seatmap here.
    characteristics: []
  },
  upsellModal: false,
  acceptedUpsellModal: false,
  currentCompartments: {},
  initialCompartments: {},
  inFocusCompartment: null,
  textOverrides: {},
  showOnClosePopUp: { show: false }
};

export const initialItineraryState: ReduxInterfaces.ItineraryInterface = {
  flights: [],
  currentSeatmap: '',
  seatmapsBySegment: {},
  upsellOptionsMap: {},
  restrictionMap: {}
};
