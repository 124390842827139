/*
 * 2022-07-30 - this file should be temporary. It exists to convert between the unofficial
 * "v1/2" format produced by corev1 and the official v2.2 format produced by corev2
 * we will be able to delete this function once:
 * 1. the SDK no longer uses raw=TRUE
 * 2. v1 passes the v2.2 format into the seatmap
 */

import { components } from '../core/v2_2';
import {
  AvailabilityV2Json as V1AvailabilityV2Json,
  Segment as V1Segment,
  OriginDestination as V1OriginDestination,
  AvailableSeatmap as V1AvailableSeatmap,
  Seatmap as V1Seatmap,
  Deck as V1Deck,
  Compartment as V1Compartment,
  SeatRow as V1SeatRow,
  Price as V1Price,
  RowGroupItem as V1RowGroupItem,
  EachPriceAndAvailability as V1EachPriceAndAvailability,
  Seat as V1Seat,
} from '../core/corev1';

type Seatmaps = components['schemas']['Seatmaps'];
type Itineraries = components['schemas']['Itineraries'];
type ItinerarySegments = components['schemas']['ItinerarySegments'];
type ItinerarySegment = components['schemas']['ItinerarySegment'];
type SeatCategories = components['schemas']['SeatCategories'];
type SearchResults = components['schemas']['SearchResults'];
type Language = components['schemas']['Language'];
type SeatProduct = components['schemas']['SeatProduct'];
type SeatProducts = components['schemas']['SeatProducts'];
type Deck = components['schemas']['Deck'];
type Decks = components['schemas']['Decks'];
type SeatRow = components['schemas']['SeatRow'];
type RowGroupItem = components['schemas']['RowGroupItem'];
type Compartment = components['schemas']['Compartment'];
type PriceAndAvailability = components['schemas']['PriceAndAvailability'];
type EachPriceAndAvailability =
  components['schemas']['EachPriceAndAvailability'];

function isV1SeatmapAvailable(
  v1Seatmap: V1Seatmap,
): v1Seatmap is V1AvailableSeatmap {
  return v1Seatmap.decks != null && v1Seatmap.seat_categories != null;
}

export function convertFromRawToV2_2Format(
  availabilityJson: V1AvailabilityV2Json,
): SearchResults {
  // extra fields not in the schema
  const { booking_id, language = 'en-US' } = availabilityJson;
  var products = {
    seat: availabilityJson.results.flight.origin_destinations.reduce(
      (journeySeatProducts: SeatProducts, v1Journey: V1OriginDestination) =>
        Object.assign(
          journeySeatProducts,
          v1Journey.segments.reduce(
            (segmentSeatProducts: SeatProducts, v1Segment: V1Segment) => {
              const v1Seatmap: V1Seatmap = v1Segment.seatmap;
              if (isV1SeatmapAvailable(v1Seatmap)) {
                return Object.assign(
                  segmentSeatProducts,
                  v1Seatmap.decks.reduce(
                    (deckSeatProducts: SeatProducts, v1Deck: V1Deck) =>
                      Object.assign(
                        deckSeatProducts,
                        v1Deck.compartments.reduce(
                          (
                            compartmentSeatProducts: SeatProducts,
                            v1Compartment: V1Compartment,
                          ) =>
                            Object.assign(
                              compartmentSeatProducts,
                              v1Compartment.seat_rows.reduce(
                                (
                                  seatRowSeatProducts: SeatProducts,
                                  v1SeatRow: V1SeatRow,
                                ) =>
                                  Object.assign(
                                    seatRowSeatProducts,
                                    v1SeatRow.row_groups.reduce(
                                      (
                                        rowGroupsSeatProducts: SeatProducts,
                                        v1RowGroup: V1RowGroupItem[],
                                      ) =>
                                        Object.assign(
                                          rowGroupsSeatProducts,
                                          v1RowGroup.reduce(
                                            (
                                              rowGroupSeatProducts: SeatProducts,
                                              v1RowGroupItem: V1RowGroupItem,
                                            ) => {
                                              if (
                                                v1RowGroupItem.product_id &&
                                                v1RowGroupItem.price_and_availability
                                              ) {
                                                const v1Seat: V1Seat =
                                                  v1RowGroupItem as V1Seat;
                                                rowGroupSeatProducts[
                                                  v1Seat.product_id
                                                ] =
                                                  convertFromRawSeatToV2_2Product(
                                                    v1Seat,
                                                    v1Segment.id,
                                                  );
                                              }
                                              return rowGroupSeatProducts;
                                            },
                                            {},
                                          ),
                                        ),
                                      {},
                                    ),
                                  ),
                                {},
                              ),
                            ),
                          {},
                        ),
                      ),
                    {},
                  ),
                );
              } else {
                return segmentSeatProducts;
              }
            },
            {},
          ),
        ),
      {},
    ),
  };
  var seatmaps: Seatmaps = [];
  var itineraries: Itineraries = [
    {
      journeys: [],
    },
  ];
  // append inbound and outbound to flights
  availabilityJson.results.flight.origin_destinations.forEach((v1Journey) => {
    const segments: ItinerarySegments = [];
    v1Journey.segments.forEach((v1Segment) => {
      // eslint-disable-next-line unused-imports/no-unused-vars
      const { id, seatmap, ...restOfSegmentData } = v1Segment; // Remove seatmap field entirely from segment data
      const segment: ItinerarySegment = {
        segment_id: id,
        ...restOfSegmentData,
      };
      segments.push(segment);
      if (isV1SeatmapAvailable(v1Segment.seatmap)) {
        const v1Seatmap: V1AvailableSeatmap = v1Segment.seatmap;
        const seatCategories: SeatCategories = v1Seatmap.seat_categories.map(
          (category) => {
            const { name, id, ...restOfCategoryData } = category;
            return {
              ...restOfCategoryData,
              seat_category_id: id,
              display_name: name,
              rank: category.rank || 0,
              image_url: category.image_url || '',
            };
          },
        );

        const decks: Decks = v1Seatmap.decks.map(convertFromRawDeckToV2_2);
        seatmaps.push({
          ...v1Segment.seatmap,
          available: true,
          segment_id: v1Segment.id,
          seat_categories: seatCategories,
          decks: decks,
        });
      } else {
        seatmaps.push({
          ...v1Segment.seatmap,
          available: false,
          segment_id: v1Segment.id,
        });
      }
    });
    itineraries[0].journeys.push({
      segments: segments,
    });
  });
  return {
    // if we need to we will add this into the official response
    // same with language
    search_id: booking_id,
    language: language as Language,
    passengers: availabilityJson.results.passengers.map((v1Passenger) => {
      const { passenger_type, id, ...restOfPassengerData } = v1Passenger;
      return {
        type: passenger_type || 'adult',
        passenger_id: id,
        ...restOfPassengerData,
      };
    }),
    results: {
      itineraries,
      products,
      seatmaps,
    },
    status: 'success',
  };
}

function convertFromRawDeckToV2_2(v1Deck: V1Deck): Deck {
  return {
    ...v1Deck,
    compartments: v1Deck.compartments.map(convertFromRawCompartmentToV2_2),
  };
}

function convertFromRawCompartmentToV2_2(
  v1Compartment: V1Compartment,
): Compartment {
  return {
    definition: {
      ...v1Compartment.definition,
      cabin: v1Compartment.definition.cabin.name,
    },
    seat_rows: v1Compartment.seat_rows.map(convertFromRawSeatRowToV2_2),
  };
}

function convertFromRawSeatRowToV2_2(v1SeatRow: V1SeatRow): SeatRow {
  return {
    ...v1SeatRow,
    row: v1SeatRow.row || null,
    row_groups: v1SeatRow.row_groups.map((rowGroup) =>
      rowGroup.map(convertFromRawRowGroupItemToV2_2),
    ),
  };
}

function convertFromRawRowGroupItemToV2_2(
  v1RowGroupItem: V1RowGroupItem,
): RowGroupItem {
  const {
    name,
    // eslint-disable-next-line unused-imports/no-unused-vars
    price_and_availability,
    ...restOfRowGroupData
  } = v1RowGroupItem; // price_and_availability needs to be removed from row_group
  return {
    ...restOfRowGroupData,
    display_name: name,
  };
}

function convertEachRawPriceAndAvailabilityToV2_2(
  v1Pna: V1EachPriceAndAvailability,
): EachPriceAndAvailability {
  if (!v1Pna.available) {
    return {
      available: false,
      passenger_id: v1Pna.passenger_id,
    };
  }
  const v1Price: V1Price = v1Pna.price as V1Price;
  return {
    available: true,
    commission: {
      breakdown: [],
      total: {
        amount: 0,
        currency: v1Price.currency,
        decimal_places: v1Price.decimal_places,
        in_original_currency: {
          amount: 0,
          currency: v1Price.currency,
          decimal_places: v1Price.decimal_places,
        },
        in_settlement_currency: {
          amount: 0,
          currency: 'USD',
          decimal_places: 2,
        },
      },
    },
    // TODO: invoice currency in v2!! ... we need to always have USD for v1 compatibility, but v2 doesn't always return USD
    price: {
      base: {
        amount:
          v1Price.base_price || v1Price.total - (v1Price.markup_amount || 0),
        currency: v1Price.currency,
        decimal_places: v1Price.decimal_places,
        in_original_currency: {
          amount:
            v1Price.base_price || v1Price.total - (v1Price.markup_amount || 0),
          currency: v1Price.currency,
          decimal_places: v1Price.decimal_places,
        },
        in_settlement_currency: {
          amount:
            v1Price.usd_base_price ||
            v1Price.total - (v1Price.markup_amount || 0),
          currency: 'USD',
          decimal_places: 2,
        },
      },
      fees: [],
      markups: [
        {
          amount: v1Price.markup_amount || 0,
          currency: v1Price.currency,
          decimal_places: v1Price.decimal_places,
          in_original_currency: {
            amount: v1Price.markup_amount || 0,
            currency: v1Price.currency,
            decimal_places: v1Price.decimal_places,
          },
          in_settlement_currency: {
            amount: v1Price.usd_markup_amount || v1Price.markup_amount || 0,
            currency: 'USD',
            decimal_places: 2,
          },
          markup_type: 'ota_markup',
        },
      ],
      taxes: [],
      total: {
        amount: v1Price.total,
        currency: v1Price.currency,
        decimal_places: v1Price.decimal_places,
        in_original_currency: {
          amount: v1Price.total,
          currency: v1Price.currency,
          decimal_places: v1Price.decimal_places,
        },
        in_settlement_currency: {
          amount: v1Price.usd_total || v1Price.total,
          currency: 'USD',
          decimal_places: 2,
        },
      },
    },
    passenger_id: v1Pna.passenger_id,
  };
}

function convertFromRawSeatToV2_2Product(
  rawSeat: V1Seat,
  segmentId: string,
): SeatProduct {
  const newPriceAndAvailability: PriceAndAvailability = {};
  Object.values(rawSeat.price_and_availability)
    .map(convertEachRawPriceAndAvailabilityToV2_2)
    .forEach((pna) => {
      newPriceAndAvailability[pna.passenger_id] = pna;
    });
  return {
    product_id: rawSeat.product_id,
    benefits: [],
    price_and_availability: newPriceAndAvailability,
    product_details: {
      segment_id: segmentId,
      row: rawSeat.row || null,
      column: rawSeat.columns[0],
    },
    product_type: 'seat',
    display_name: rawSeat.name,
  };
}
