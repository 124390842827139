import React, { Fragment, useLayoutEffect, useState } from 'react';
import { BagImage } from './BagImage';
import PassengerSelectionRow from './PassengerSelectionRow';
import DesktopPassengerRowBagSection from './DesktopPassengerRowBagSection';
import { DimensionsText } from './DimensionsText';
import { WeightText } from './WeightText';
import { formatPrice } from '../../utils/formatPrice';
import { connect } from 'react-redux';
import { Bag, EachPriceAndAvailability, Passenger, Theme } from './CommonTypes';
import { Translate } from 'react-localize-redux';

function BagCard({
  bag,
  passengers,
  isMobile,
  index,
  theme,
  addButtonTextWidth,
}: {
  bag: Bag;
  passengers: [Passenger];
  isMobile: boolean;
  index: number;
  theme: Theme;
  addButtonTextWidth: number;
}) {
  function useWindowWidth() {
    const [width, setWidth] = useState(window.innerWidth);
    useLayoutEffect(() => {
      function updateWidth() {
        setWidth(window.innerWidth);
      }
      window.addEventListener('resize', updateWidth);
      updateWidth();
      return () => window.removeEventListener('resize', updateWidth);
    }, []);
    return width;
  }
  const width = useWindowWidth();

  const allOffers = [bag, ...bag.weight_based_offer];

  const bagDetails = allOffers.sort(
    (o1, o2) => o2.product_details.weight - o1.product_details.weight,
  )[0].product_details;

  const useTotalDimensions = Boolean(
    bagDetails.total_dimensions &&
      (!bagDetails.height || !bagDetails.width || !bagDetails.length),
  );

  const allBagOptions = allOffers
    .flatMap((bag) => Object.values(bag.price_and_availability))
    .flat() as Array<EachPriceAndAvailability>;

  const minimumPrice = allBagOptions
    .map((option) => option.price.total)
    .sort((p1, p2) => p1.amount - p2.amount)[0];

  const [totalPrice, setTotalPrice] = useState(0);

  return (
    <div
      className={`gr-rounded-xl gr-text-black gr-border gr-border-solid gr-border-gray-300 gr-mt-4 gr-mb-4 ${
        isMobile ? 'gr-ml-4 gr-mr-4' : 'gr-max-w-3xl'
      }`}
      id={'bag-card-' + index}
      style={{ width: isMobile ? undefined : '95%' }}
    >
      {isMobile ? (
        width > 350 ? (
          <div className="bag-details gr-p-2 gr-pl-6 gr-flex gr-justify-between">
            <div className="gr-relative">
              <p className="gr-bag-title-name-text gr-leading-normal gr-text-gray-900 gr-text-lg gr-font-bold gr-not-italic gr-mb-2">
                {bag.system === 'weight'
                  ? bagDetails.checked
                    ? 'Checked Bag'
                    : 'Cabin Bag'
                  : bagDetails.display_name}
              </p>
              <WeightText bagDetails={bagDetails} />
              {useTotalDimensions ? (
                <DimensionsText
                  className={
                    'gr-bag-max-weight-text gr-leading-normal gr-text-gray-550 gr-text-xs gr-font-semibold gr-not-italic gr-flex'
                  }
                  bagDetails={bagDetails}
                />
              ) : null}
              <p
                className="gr-bag-starting-price-text gr-text-xs gr-not-italic gr-leading-normal gr-font-bold"
                style={{ color: theme?.bags?.fromPriceText ?? '#846be8' }}
              >
                <Translate id={'from'}>From</Translate>{' '}
                {formatPrice(
                  minimumPrice.amount,
                  minimumPrice.currency,
                  minimumPrice.decimal_places,
                )}
              </p>
              <p className="gr-bag-total-price-text gr-leading-normal gr-text-gray-550 gr-text-xs gr-font-normal gr-not-italic gr-absolute gr-bottom-0">
                <Translate id={'total'}>Total price</Translate>{' '}
                {formatPrice(
                  totalPrice,
                  minimumPrice.currency,
                  minimumPrice.decimal_places,
                )}
              </p>
            </div>
            <div
              className="gr-mt-5 gr-flex gr-ml-auto gr-mr-auto"
              style={{
                paddingBottom: '55px',
                paddingTop: '30px',
              }}
            >
              <BagImage
                useTotalDimensions={useTotalDimensions}
                bagDetails={bagDetails}
                smallWidth={width < 350 ? true : false}
              />
            </div>
          </div>
        ) : (
          <div className="bag-details gr-p-2 gr-flex gr-justify-center">
            <div className="gr-relative gr-flex gr-flex-col gr-justify-center">
              <p className="gr-bag-title-name-text gr-leading-normal gr-text-gray-900 gr-text-lg gr-font-bold gr-not-italic gr-m-0 gr-mb-4 gr-self-center">
                {bag.system === 'weight'
                  ? bagDetails.checked
                    ? 'Checked Bag'
                    : 'Cabin Bag'
                  : bagDetails.display_name}
              </p>
              <WeightText bagDetails={bagDetails} centered={true} />
              {useTotalDimensions ? (
                <DimensionsText
                  className={
                    'gr-bag-max-weight-text gr-text-gray-550 gr-text-xs gr-font-semibold gr-not-italic gr-flex gr-flex gr-justify-center gr-m-0'
                  }
                  bagDetails={bagDetails}
                />
              ) : null}
              <p
                className="gr-bag-starting-price-text gr-text-xs gr-not-italic gr-leading-normal gr-font-bold gr-m-0 gr-self-center"
                style={{ color: theme?.bags?.fromPriceText ?? '#846be8' }}
              >
                <Translate id={'from'}>From</Translate>{' '}
                {formatPrice(
                  minimumPrice.amount,
                  minimumPrice.currency,
                  minimumPrice.decimal_places,
                )}
              </p>
              <div
                className="gr-flex gr-mb-14 gr-justify-center"
                style={{ marginTop: '10px' }}
              >
                <BagImage
                  useTotalDimensions={useTotalDimensions}
                  bagDetails={bagDetails}
                  smallWidth={width < 350 ? true : false}
                />
              </div>
              <p className="gr-bag-total-price-text gr-leading-normal gr-text-gray-550 gr-text-xs gr-font-normal gr-not-italic gr-top-auto gr-self-center">
                <Translate id={'total'}>Total price</Translate>{' '}
                {formatPrice(
                  totalPrice,
                  minimumPrice.currency,
                  minimumPrice.decimal_places,
                )}
              </p>
            </div>
          </div>
        )
      ) : null}
      <div className={isMobile ? '' : 'gr-flex'}>
        {!isMobile ? (
          <DesktopPassengerRowBagSection
            totalPrice={totalPrice}
            bagSystem={bag.system}
            bagDetails={bagDetails}
            minimumPrice={minimumPrice}
          />
        ) : null}
        <div
          className={
            isMobile ? '' : 'gr-flex gr-flex-col gr-flex-grow gr-justify-evenly'
          }
        >
          {passengers.map((passenger: any, index: number) => (
            <Fragment
              key={bag.product_id + (passenger.id ?? passenger.passenger_id)}
            >
              <PassengerSelectionRow
                passenger={passenger}
                bag={bag}
                setTotalPrice={setTotalPrice}
                isMobile={isMobile}
                smallWidth={width < 350 ? true : false}
                index={index}
                addButtonTextWidth={addButtonTextWidth}
                maxWeight={bagDetails.weight + bagDetails.weight_unit}
              />
              {index < passengers.length - 1 && !isMobile ? (
                <hr
                  className="gr-bg-gray-400 gr-border-gray-200 gr-border-0 gr-m-0 gr-mr-4"
                  style={{ minHeight: '1px' }}
                />
              ) : null}
            </Fragment>
          ))}
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state: any) => ({
  theme: state.session.theme,
});

export default connect(mapStateToProps)(BagCard);
