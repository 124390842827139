import React, { Component } from 'react';
import { connect } from 'react-redux';

import { showModal, compartmentHeight, clearBasket } from '../../redux/actions';
import FocusTrap from 'focus-trap-react';

const bodyOverflow = document.body.style.overflow;
const bodyPosition = document.body.style.position;
const bodyHeight = document.body.style.height;

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch() {
    this.props.showModal(false);
    if (window.gordianInternal.noSeatsOnSearch) {
      // Display fallback message on card widget
      window.gordianInternal.noSeatsOnSearch();
    }
  }

  render() {
    if (this.state.hasError) {
      return <div></div>;
    }
    return this.props.children;
  }
}

export class ModalOverlay extends Component {
  constructor(props) {
    super(props);
    this.state = { focusTrapActive: false };
  }

  componentDidUpdate(prevProps) {
    if (this.props.modalShown) {
      const compartments = document.getElementById('gordian-compartments');
      if (typeof compartments != 'undefined' && compartments != null) {
        this.props.compartmentHeight(compartments.clientHeight);
      }
    }
    if (prevProps.modalShown !== this.props.modalShown) {
      if (!this.props.modalShown) {
        this.toggleScrollLock(false);
      } else {
        this.toggleScrollLock(true);
      }
    } else if (
      !prevProps.modalShown &&
      this.props.shown &&
      this.props.hasSeats
    ) {
      this.props.showModal(true);
      this.toggleScrollLock(true);
    }
  }

  componentDidMount() {
    const { modalShown, shown } = this.props;
    if (modalShown || shown) {
      const compartments = document.getElementById('gordian-compartments');
      if (typeof compartments != 'undefined' && compartments != null) {
        this.props.compartmentHeight(compartments.clientHeight);
      }
      this.props.showModal(true);
      this.toggleScrollLock(true);
    }
    // Set a 0.5 second wait before activating focus trap. This is a bit of a hacky way to prevent a focus trap error from occuring during initialization
    setTimeout(() => {
      this.activateFocusTrap();
    }, 500);
  }

  componentWillUnmount() {
    // Fixes student-universe issue where backing out of seatmap with back button would not tear down scroll lock.
    // Directly setting the document overflow here as calling toggleScrollLock in unload won't work after the back-
    // button has been pressed.
    document.body.style.overflow = bodyOverflow;
  }

  activateFocusTrap() {
    this.setState({
      focusTrapActive: true,
    });
  }

  toggleScrollLock = (on) => {
    document.body.style.overflow = on ? 'hidden' : bodyOverflow;
    var iOS =
      !!navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform);
    if (iOS) {
      document.body.style.position = on ? 'fixed' : bodyPosition;
      document.body.style.height = on ? '100%' : bodyHeight;
    }
  };

  handleOutsideClick = (e) => {
    if (e.target.id === this.node.id) {
      if (this.props.modal && this.props.isOptIn) {
        this.props.showModal(false);
      } else {
        this.props.clearBasket();
      }
    }
  };

  render() {
    return (
      <ErrorBoundary showModal={this.props.showModal}>
        <FocusTrap
          focusTrapOptions={{
            initialFocus: '#gordian-overlay',
            allowOutsideClick: true,
          }}
          active={this.props.modalShown && this.state.focusTrapActive}
        >
          <div
            id="gordian-overlay"
            tabIndex={-1}
            ref={(node) => (this.node = node)}
            onClick={(element) => this.handleOutsideClick(element)}
            className={` ${
              this.props.modalShown ? 'gr-block' : 'gr-hidden'
            } gordian-overlay gr-fixed gr-top-0 gr-left-0 gr-w-full gr-h-full`}
            style={{
              zIndex: 9000,
              background: 'rgba(0, 0, 0, 0.5)',
            }}
          >
            {this.props.children}
          </div>
        </FocusTrap>
      </ErrorBoundary>
    );
  }
}

const mapStateToProps = (state) => ({
  modalShown: state.session.modalShown,
  hasSeats: state.session.hasSeats,
});

const mapDispatchToProps = {
  showModal,
  compartmentHeight,
  clearBasket,
};

export default connect(mapStateToProps, mapDispatchToProps)(ModalOverlay);
