import React, { Component } from 'react';
import { connect } from 'react-redux';

import { Translate } from 'react-localize-redux';
import MediaQuery from 'react-responsive';
import Price from '../Price';
import { calculateConvenienceFeeVariant } from '../../../utils/calculateConvenienceFeeVariant';

function generatePriceTextPostfix(
  convenienceFeeDisplayType,
  text,
  shortenFeeText = false
) {
  return convenienceFeeDisplayType !== 'default'
    ? !shortenFeeText
      ? `(${text} included)`
      : '(Fees included)'
    : null;
}

export class PaySummary extends Component {
  render() {
    const { priceAdjustment = {}, relativeWidthRatio } = this.props;

    let { total, currency, decimal_places, count } =
      this.props.basket.seatingChoice.reduce(
        (accumulated, current) => {
          const { price_and_availability } = current;
          const { price } = price_and_availability.group;
          const { amount, currency, decimal_places } = price.total;
          accumulated['total'] = amount + accumulated['total'];
          accumulated['currency'] = currency;
          accumulated['decimal_places'] = decimal_places;
          accumulated['count'] += 1;
          return accumulated;
        },
        { total: 0, currency: '', decimal_places: 0, count: 0 }
      );

    const { variants } = this.props;
    const {
      test_seatmap_1d = false,
      test_reservation_fee_name = 'Convenience fee',
    } = variants;

    let convenienceFeeDisplayVariant = calculateConvenienceFeeVariant(
      variants['convenience_fee_display_variant']
    );
    // Determine currency
    const priceFactor = priceAdjustment.factor ?? 1.0;
    const usePriceAdjust = priceFactor !== 1.0;
    const priceTextPostfix = generatePriceTextPostfix(
      convenienceFeeDisplayVariant,
      decodeURI(test_reservation_fee_name)
    );
    const stackedLayout = (
      <MediaQuery maxWidth={850 / relativeWidthRatio}>
        {(shortenFeeText) => {
          const priceMinWidth = shortenFeeText ? 85 : 145;
          return (
            <div
              className="payment-summary gr-text-secondary-text sm:gr-flex-row-reverse sm:gr-mx-4 gr-mr-2"
              style={{ minWidth: priceMinWidth }}
            >
              <div
                className={`gr-block ${
                  usePriceAdjust || convenienceFeeDisplayVariant !== 'default'
                    ? ''
                    : 'gr-pb-2 sm:gr-pb-1'
                } gr-text-title`}
              >
                <Price
                  currency={currency}
                  amount={total * priceFactor}
                  decimalPlaces={decimal_places}
                  abbreviatePrice={convenienceFeeDisplayVariant !== 'default'}
                  simple={convenienceFeeDisplayVariant !== 'default'}
                />
              </div>
              {this.props.priceTextPrepend ?? ''} <Translate id="total" />
              {usePriceAdjust && (
                <div className="gr-text-xs">
                  (inc.{' '}
                  <Price
                    currency={currency}
                    amount={total * (priceFactor - 1.0)}
                    decimalPlaces={decimal_places}
                    abbreviatePrice={convenienceFeeDisplayVariant !== 'default'}
                    simple={convenienceFeeDisplayVariant !== 'default'}
                  />{' '}
                  {`${priceAdjustment.name}`})
                </div>
              )}
              <div className="gr-text-xs">
                {generatePriceTextPostfix(
                  convenienceFeeDisplayVariant,
                  decodeURI(test_reservation_fee_name),
                  shortenFeeText
                )}
              </div>
            </div>
          );
        }}
      </MediaQuery>
    );

    const rowLayout = (
      <div className="gr-flex gr-w-full gr-justify-between">
        <div className="gr-flex gr-text-title">
          <div>
            <Translate id="total" />
            <div className="gr-text-xs">{priceTextPostfix}</div>
          </div>
        </div>
        <div className="gr-flex">
          <Price
            currency={currency}
            amount={total}
            decimalPlaces={decimal_places}
            abbreviatePrice={convenienceFeeDisplayVariant !== 'default'}
            simple={convenienceFeeDisplayVariant !== 'default'}
          />
        </div>
      </div>
    );

    const layoutToUse = (
      <MediaQuery minWidth={1600 / relativeWidthRatio}>
        {(matches) =>
          matches && test_seatmap_1d === true ? rowLayout : stackedLayout
        }
      </MediaQuery>
    );

    return count !== 0 || (test_seatmap_1d === true && currency)
      ? layoutToUse
      : null;
  }
}

const mapStateToProps = (state) => ({
  basket: state.basket,
  variants: state.session.variants,
  categories: state.categories,
  seatProducts: state.products.products.seat,
  priceAdjustment: state.session.priceAdjustment,
  priceTextPrepend: state.session.priceTextPrepend,
  relativeWidthRatio: state.session.relativeWidthRatio,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(PaySummary);
