import 'react-app-polyfill/ie11';
import smoothscroll from 'smoothscroll-polyfill';
import findIndex from 'array.prototype.findindex';
import includes from 'array-includes';
import cssVars from 'css-vars-ponyfill';

const objectToValuesPolyfill = object => {
  return Object.keys(object).map(key => object[key]);
};

Object.values = Object.values || objectToValuesPolyfill;

if (!Array.prototype.flat) {
  // eslint-disable-next-line no-extend-native
  Array.prototype.flat = function(depth) {
    var flattend = [];
    (function flat(array, depth) {
      for (let el of array) {
        if (Array.isArray(el) && depth > 0) {
          flat(el, depth - 1);
        } else {
          flattend.push(el);
        }
      }
    })(this, Math.floor(depth) || 1);
    return flattend;
  };
}

cssVars({});
includes.shim();
findIndex.shim();
smoothscroll.polyfill();
