import React from 'react';
import { connect } from 'react-redux';

function SelectArrow(props) {
  const { theme = {} } = props;
  const { selectArrowColor } = theme;
  const styleOverride = selectArrowColor ? { color: selectArrowColor } : {};
  return (
    <svg
      className="gr-fill-current gr-h-6 gr-w-6"
      style={styleOverride}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 20 20"
    >
      <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
    </svg>
  );
}

const mapStateToProps = (state) => ({
  theme: state.session.theme,
});
export default connect(mapStateToProps, {})(SelectArrow);
