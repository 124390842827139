const nubank = {
  textPrimary: '#320850', // color.purple.90
  textSecondary: '#3E1874', // color.purple.80
  buttonText: '#FFFFFF',
  title: '#17092C', // color.purple.100
  gray_10: '#EEF4F9',
  gray_15: '#DBE4EA',
  gray_20: '#cbd5e0',
  gray_50: '#BBCBD5',
  gray_70: '#718096',
  gray_100: '#4a5568',
  primary: '#820AD1',
  secondary: '#3E1874', // color.purple.80
  link: '#EFEFEF',
  actionButtonBorderRadius: '20px',
  buttonBorderRadius: '4px',
  detailContainerBorder: '1px solid #EFEFEF',
  detailContainerBorderRadius: '20px',
  legendBorder: '1px solid #EFEFEF',
  legendBorderRadius: '8px',
  legendIconColor: '#000000',
  selectArrowColor: '#000000',
  alert: '#D01D1C', // color.red.50
  margin: '0px',
  seats: {
    0: {
      fill: '#615BCC',
      stopColor: '#615BCC',
      borderColor: '#615BCC',
    },
    1: {
      // color.purple.40
      fill: '#ECD9FF',
      stopColor: '#ECD9FF',
      borderColor: '#ECD9FF',
    },
    not_available: {
      // color.purple.10
      fill: '#F6ECFF',
      stopColor: '#F6ECFF',
      borderColor: '#ECD9FF',
    },
    selected_seat: {
      // Selected, color.purple.70
      fill: '#610F9B',
      stopColor: '#610F9B',
      borderColor: '#FFFFFF',
    },
  },
  fontFamily: 'Poppins, sans-serif',
};

export default nubank;
