import React, { PureComponent } from 'react';

export class Bathroom extends PureComponent {
  render() {
    return (
      <svg
        viewBox="0 0 25 27"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className="gr-block gr-h-4 gr-w-4 sm:gr-h-5 sm:gr-w-5 md:gr-h-6 md:gr-w-6"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M5.29302 5.4C6.79005 5.4 7.99302 4.19703 7.99302 2.7C7.99302 1.20297 6.79005 0 5.29302 0C3.79599 0 2.59302 1.20297 2.59302 2.7C2.59302 4.19703 3.79599 5.4 5.29302 5.4ZM8.68807 20.1564C9.16926 20.1564 9.65045 19.9426 9.99797 19.5683C10.3455 19.1941 10.5059 18.6861 10.4524 18.1782L9.32965 7.59208C9.24946 6.68317 8.47421 5.98812 7.5653 5.98812H3.07421C2.1653 5.98812 1.41678 6.68317 1.30985 7.59208L0.18708 18.1782C0.133614 18.6861 0.29401 19.1941 0.641535 19.5683C0.962327 19.9426 1.44352 20.1564 1.95144 20.1564H2.61975V24.9683C2.61975 25.9574 3.42173 26.7594 4.41084 26.7594H6.22866C7.21777 26.7594 8.01975 25.9574 8.01975 24.9683V20.1564H8.68807ZM24.9683 7.80594V14.8901C24.9683 15.8792 24.1663 16.6812 23.1772 16.6812H23.0435V24.9683C23.0435 25.9574 22.2415 26.7594 21.2524 26.7594H19.4346C18.4455 26.7594 17.6435 25.9574 17.6435 24.9683V16.6812H17.5099C16.5207 16.6812 15.7188 15.8792 15.7188 14.8901V7.80594C15.7188 6.81683 16.5207 6.01485 17.5099 6.01485H23.1504C24.193 6.01485 24.9683 6.81683 24.9683 7.80594ZM20.3702 5.4C21.8673 5.4 23.0702 4.19703 23.0702 2.7C23.0702 1.20297 21.8673 0 20.3702 0C18.8732 0 17.6702 1.20297 17.6702 2.7C17.6702 4.19703 18.8732 5.4 20.3702 5.4Z"
          fill="#A9BBC6"
        />
      </svg>
    );
  }
}
