export function formatPrice(
  amount: number,
  currency: string,
  decimalPlaces: number = 2,
  roundPrice?: boolean,
  locale?: string,
): string {
  let adjustedAmount = amount / Math.pow(10, decimalPlaces);
  if (roundPrice) {
    adjustedAmount =
      adjustedAmount < 1
        ? Math.ceil(adjustedAmount)
        : Math.round(adjustedAmount);
  }

  const fractionDigits = roundPrice ? 0 : decimalPlaces;

  const formatter = new Intl.NumberFormat(locale, {
    style: 'currency',
    currency: currency,
    maximumFractionDigits: fractionDigits,
    minimumFractionDigits: fractionDigits,
  });

  return formatter.format(adjustedAmount);
}

export function isNordicCurrency(currency: string) {
  return ['SEK', 'NOK', 'DKK'].includes(currency.toUpperCase());
}
