import React, { PureComponent } from 'react';

export class DetailsContainer extends PureComponent {
  state = {
    height: 0,
    width: 0,
    x: 0,
    y: 0,
    hide: false
  };

  ref = React.createRef();

  componentDidMount() {
    if (this.ref.current) {
      const enoughSpaceAbove =
        this.props.verticalSpaceBelow +
          this.ref.current.offsetHeight +
          this.props.seatHeight +
          5 <
        this.props.ySpace;
      const enoughSpaceBelow =
        this.props.verticalSpaceBelow - 5 > this.ref.current.offsetHeight;
      const enoughSpaceRight =
        this.props.horizontalSpaceRight > this.ref.current.offsetWidth + 5;
      this.setState({
        height: this.ref.current.offsetHeight,
        y: !enoughSpaceBelow
          ? !enoughSpaceAbove
            ? this.props.ySpace -
              this.props.verticalSpaceBelow -
              (this.props.seatHeight + this.ref.current.offsetHeight) / 2
            : this.props.ySpace -
              this.props.verticalSpaceBelow -
              this.props.seatHeight -
              this.ref.current.offsetHeight -
              5
          : this.props.ySpace - this.props.verticalSpaceBelow + 5,
        width: this.ref.current.offsetWidth,
        x:
          enoughSpaceBelow || enoughSpaceAbove
            ? !enoughSpaceRight
              ? this.props.leftDisplacement -
                this.ref.current.offsetWidth +
                this.props.seatWidth
              : this.props.leftDisplacement
            : this.props.horizontalSpaceRight <
              this.props.seatWidth + this.ref.current.offsetWidth + 5
            ? this.props.leftDisplacement - this.ref.current.offsetWidth - 5
            : this.props.leftDisplacement + this.props.seatWidth + 5
      });
    }
  }

  render() {
    const { mobile, children, isEnhancedSeatDetail = false } = this.props;
    const aria_seat = this.props.selected_seat;
    const aria_passenger = `${this.props.current_passenger.first_names} ${this.props.current_passenger.surname}`;

    const showMobileDetails = mobile ? '' : 'sm:gr-hidden';
    const fixedWidth = isEnhancedSeatDetail ? 'gr-w-280px' : 'gr-w-260px';
    return (
      <div
        role="dialog"
        aria-label={`Viewing Seat ${aria_seat} for ${aria_passenger}`}
        ref={this.ref}
        id="seat-details-container"
        tabIndex={-1}
        className={`${
          mobile
            ? `seat-details seat-details-mobile gr-absolute gr-block ${showMobileDetails} gr-w-11/12 gr-max-w-lg gr-z-60 gr-text-sm gr-w-260px gr-bg-white gr-rounded-lg gr-shadow-lg`
            : `gr-border-2 seat-details seat-details-desktop gr-absolute gr-hidden sm:gr-block gr-z-60 gr-text-sm ${fixedWidth} gr-bg-white ${
                isEnhancedSeatDetail ? '' : 'gr-rounded-lg'
              } gr-shadow-lg`
        }`}
        style={
          // Please note: for one-click variant the dialog box cannot overlap with the seat button
          mobile
            ? { bottom: 100, left: '50%', transform: 'translate(-50%, 0%)' }
            : { left: this.state.x, top: this.props.scrolledY + this.state.y }
        }
      >
        {children}
      </div>
    );
  }
}
