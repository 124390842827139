import { handleActions } from 'redux-actions';

const addSeatToBasketLocalStorage = (
  currentSeatmap,
  selectedSeat,
  currentPassenger,
) => {
  if (!window._gordianUseLocalStorage) {
    return;
  } else {
    const acceptedProduct = {
      segment_id: currentSeatmap,
      product_id: selectedSeat.product_id,
      passenger_id: currentPassenger.passenger_id,
      from_api: false,
      session_id: window._gordianSessionId,
    };

    const currentAcceptedProducts =
      JSON.parse(localStorage.getItem('gordianAcceptedProducts')) || [];

    // we want to leave all products that aren't clashing with the current passenger on the current seatmap
    const filterCallback = (product) => {
      return (
        product.passenger_id !== acceptedProduct.passenger_id ||
        product.segment_id !== acceptedProduct.segment_id
      );
    };

    const filteredAcceptedProducts = currentAcceptedProducts.filter((product) =>
      filterCallback(product),
    );

    filteredAcceptedProducts.push(acceptedProduct);

    localStorage.setItem(
      'gordianAcceptedProducts',
      JSON.stringify(filteredAcceptedProducts),
    );
  }
};

const removeSeatFromBasketLocalStorage = (currentSeatmap, productId) => {
  if (!window._gordianUseLocalStorage) {
    return;
  } else {
    const currentAcceptedProducts =
      JSON.parse(localStorage.getItem('gordianAcceptedProducts')) || [];

    // leave all products that don't match the one we want to remove
    const newAccceptedProducts = currentAcceptedProducts.filter(
      (product) =>
        !(
          product.product_id === productId &&
          product.segment_id === currentSeatmap &&
          product.session_id === window._gordianSessionId
        ),
    );
    localStorage.setItem(
      'gordianAcceptedProducts',
      JSON.stringify(newAccceptedProducts),
    );
  }
};

const formatSeatForBasket = (
  currentPassenger,
  selectedSeat,
  currentSeatmap,
  selectedProduct,
) => {
  const { type, api_passenger_id, ...restOfPassengerData } = currentPassenger;
  const { display_name, ...restOfSeatData } = selectedSeat;

  // After change to v2.2 of JSON we needed to add price_and_availability back with the old onBasketChange format so partners are uneffected by the migraiton
  const price_and_availability = {};
  Object.keys(selectedProduct.price_and_availability).forEach((passenger) => {
    const { available, passenger_id, price } =
      selectedProduct.price_and_availability[passenger];
    let price_format = {};
    if (available) {
      const markups = price.markups.reduce(
        (accumulator, curr) => accumulator + curr.amount,
        0,
      );
      const markupsUSD = price.markups.reduce(
        (accumulator, curr) => accumulator + curr.in_settlement_currency.amount,
        0,
      );
      price_format = {
        markup_amount: markups,
        currency: price.base.currency,
        total: price.total.amount,
        usd_base_price: price.base.in_settlement_currency.amount,
        base_price: price.base.amount,
        usd_markup_amount: markupsUSD,
        usd_total: price.total.in_settlement_currency.amount,
        decimal_places: price.base.decimal_places,
      };
    }
    price_and_availability[passenger_id] = {
      available,
      passenger_id,
      price: price_format,
    };
  });

  const newSeat = {
    name: display_name,
    passenger_type: type,
    api_passenger_id: api_passenger_id || restOfPassengerData.passenger_id,
    segment_id: currentSeatmap,
    price_and_availability,
    ...restOfSeatData,
    ...restOfPassengerData,
  };

  return newSeat;
};

export const basket = handleActions(
  {
    CREATE_BASKET_ID: (state, action) => {
      return {
        ...state,
        id: `basket_${action.payload}`,
      };
    },
    REMOVE_SEAT_FROM_BASKET: (state, action) => {
      const { selectedSeat, currentSeatmap } = action.payload;
      const [removedSeat] = state.seats.filter(
        (seat) =>
          seat.segment_id === currentSeatmap && seat.seat === selectedSeat.seat,
      );

      removeSeatFromBasketLocalStorage(currentSeatmap, selectedSeat.product_id);

      return {
        ...state,
        seats: state.seats.filter(
          (seat) =>
            !(
              seat.seat === selectedSeat.seat &&
              seat.segment_id === currentSeatmap
            ),
        ),
        removedSeat,
      };
    },
    SET_BASKET: (state, action) => {
      const seats = action.payload.basketData.map((seatData) =>
        formatSeatForBasket(
          seatData.currentPassenger,
          seatData.selectedSeat,
          seatData.currentSeatmap,
          seatData.selectedProduct,
        ),
      );
      return {
        ...state,
        // we only want the seats that actually have a passenger ID set,
        // because that means that the seat is part of the accepted products
        // and thus belongs in the basket.
        seats: seats,
      };
    },

    SET_BAGGAGE_BASKET: (state, action) => {
      return {
        ...state,
        ...action.payload,
      };
    },

    SET_SEATING_CHOICE_BASKET: (state, action) => {
      return {
        ...state,
        ...action.payload,
      };
    },

    ADD_SEATING_CHOICE_TO_BASKET: (state, action) => {
      const { currentPassenger, currentSeatmap, selectedProduct } =
        action.payload;

      const newSeatingChoiceProduct = {
        ...selectedProduct,
        ...currentPassenger,
        segment_id: currentSeatmap,
      };

      const { passenger_id } = currentPassenger;

      const seatingChoiceSelectionCopy = state.seatingChoice.filter(
        (seatingChoice) =>
          !(
            seatingChoice.passenger_id === passenger_id &&
            seatingChoice.segment_id === currentSeatmap
          ),
      );

      return {
        ...state,
        seatingChoice: [...seatingChoiceSelectionCopy, newSeatingChoiceProduct],
      };
    },

    REMOVE_SEATING_CHOICE_FROM_BASKET: (state, action) => {
      const { currentPassenger, currentSeatmap, selectedProduct } =
        action.payload;

      const { passenger_id } = currentPassenger;
      const { product_id } = selectedProduct;

      const seatingChoiceSelectionCopy = state.seatingChoice.filter(
        (seatingChoice) =>
          !(
            seatingChoice.passenger_id === passenger_id &&
            seatingChoice.segment_id === currentSeatmap &&
            seatingChoice.product_id === product_id
          ),
      );

      return {
        ...state,
        seatingChoice: [...seatingChoiceSelectionCopy],
      };
    },

    CLEAR_BASKET: (state) => {
      return {
        ...state,
        seats: [],
        bags: {},
        seatingChoice: [],
      };
    },
    INCR_BAG_FOR_PASSENGER: (state, action) => {
      const { passenger_id, product_id, max_quantity = 1 } = action.payload;
      const currentBasket = state?.bags ?? {};
      const passengerBasket = state?.bags?.[passenger_id] ?? {};
      const currentQuantity = passengerBasket[product_id] ?? 0;
      let newQuantity;
      newQuantity =
        currentQuantity >= max_quantity ? currentQuantity : currentQuantity + 1;

      passengerBasket[product_id] = newQuantity;
      return {
        ...state,
        bags: {
          ...currentBasket,
          [passenger_id]: passengerBasket,
        },
      };
    },

    DECR_BAG_FOR_PASSENGER: (state, action) => {
      const { passenger_id, product_id } = action.payload;
      const currentBasket = state?.bags ?? {};
      const passengerBasket = state?.bags?.[passenger_id] ?? {};
      const currentQuantity = passengerBasket[product_id] ?? 0;
      let newQuantity;
      newQuantity = currentQuantity > 0 ? currentQuantity - 1 : 0;
      passengerBasket[product_id] = newQuantity;
      return {
        ...state,
        bags: {
          ...currentBasket,
          [passenger_id]: passengerBasket,
        },
      };
    },

    ACCEPT_SEAT: (state, action) => {
      const {
        currentPassenger,
        selectedSeat,
        currentSeatmap,
        selectedProduct,
      } = action.payload;

      addSeatToBasketLocalStorage(
        currentSeatmap,
        selectedSeat,
        currentPassenger,
      );

      const newSeat = formatSeatForBasket(
        currentPassenger,
        selectedSeat,
        currentSeatmap,
        selectedProduct,
      );

      const { passenger_id } = currentPassenger;
      if (
        state.seats.some(
          (seat) =>
            seat.segment_id === currentSeatmap &&
            seat.passenger_id === passenger_id,
        )
      ) {
        const [removedSeat] = state.seats.filter(
          (seat) =>
            seat.segment_id === currentSeatmap &&
            seat.passenger_id === passenger_id,
        );
        return {
          ...state,
          seats: state.seats.map((seat) => {
            if (
              seat.segment_id === currentSeatmap &&
              seat.passenger_id === passenger_id
            ) {
              return newSeat;
            }
            return seat;
          }),
          removedSeat,
        };
      }
      return {
        ...state,
        seats: [...state.seats, newSeat],
      };
    },
  },
  {
    id: '',
    seats: [],
    bags: {},
    seatingChoice: [],
    lastSeatSelected: null,
  },
);
