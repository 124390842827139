import React from 'react';
import { connect } from 'react-redux';
import { clearBasket, setSeatMapStep } from '../redux/actions';
import { ReactComponent as SpotSeat } from '../svg/spot-seat.svg';
import { ReactComponent as ThreeDots } from '../svg/three-dots.svg';
import ListItemWithCheck from '../components/shared/ListItemWithCheck';
import Price from '../components/seatmap/Price'; // TODO: should be in shared
import Button from '../components/shared/Button';
import Link from '../components/shared/Link';

const Introduction = ({ setSeatMapStep, clearBasket, leadInPrice }) => {
  let pickSeats = () => {
      setSeatMapStep('seatmap');
    },
    notNow = e => {
      e.preventDefault();
      clearBasket();
    };
  let loading = leadInPrice === null || leadInPrice === undefined;
  return (
    <div
      className="gr-flex gr-flex-col gr-justify-center gr-text-center gr-w-full gr-items-center"
      style={{ height: '90%' }}
    >
      <SpotSeat className="gr-mb-4" alt="seat" />
      <div
        className="gr-font-semibold gr-text-2xl gr-m-2 gr-text-gray-600"
        id="gordian-finding-seats"
      >
        {loading ? (
          'Finding available seats'
        ) : (
          <React.Fragment>
            Found seats from <Price {...leadInPrice} />!
          </React.Fragment>
        )}
      </div>
      {loading ? (
        <ThreeDots
          key="1"
          className="gr-m-3 gr-h-4"
          style={{ fill: 'var(--color-gordian-primary)', position: 'relative' }}
          id="gordian-intro-spinner"
        />
      ) : (
        <React.Fragment>
          <div
            className="gr-font-medium gr-m-2 gr-text-gray-500"
            id="gordian-benefits"
          >
            <ul>
              <ListItemWithCheck key="best">
                Get the best seats before they go
              </ListItemWithCheck>
              <ListItemWithCheck key="extra">
                Select extra legroom seats
              </ListItemWithCheck>
              <ListItemWithCheck key="aisle">
                Reserve an aisle or window seat
              </ListItemWithCheck>
            </ul>
          </div>
          <Button
            id="gordian-pick-now-button"
            primary={true}
            handleClick={pickSeats}
            disabled={false}
            standalone={true}
            style={{ width: 'auto' }}
          >
            <span style={{ margin: 'auto' }}>
              Choose your seats now from <Price {...leadInPrice} />
            </span>
          </Button>
        </React.Fragment>
      )}
      <Link onClick={notNow} id="gordian-not-now">
        Skip seat selection
      </Link>
    </div>
  );
};

const mapDispatchToProps = {
  setSeatMapStep,
  clearBasket
};

const mapStateToProps = () => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(Introduction);
