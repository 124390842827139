import React from 'react';
import Itinerary from './Itinerary';
import PassengersList from './PassengersList';
import { isMobile } from 'react-device-detect';
const UberHeader = () => {
  const hidden = isMobile ? 'gr-hidden' : '';
  return (
    <header className={'gr-flex gr-flex-col'}>
      <div className={`gr-px-1 sm:gr-px-16 lg:gr-px-12 gr-py-4 ${hidden}`}>
        <p className="gr-text-2xl gr-font-bold"> Choose a seat </p>
      </div>
      <div className="gr-flex gr-px-1 sm:gr-px-16 lg:gr-px-12 gap-1 gr-h-16 gr-w-full gr-bg-white gr-mb-5">
        <Itinerary />
        <PassengersList />
      </div>
    </header>
  );
};

const GenericHeader = () => {
  return (
    <header className="gr-flex gr-h-20 gr-w-full">
      <Itinerary />
      <PassengersList />
    </header>
  );
};

const Header = ({ brand }) => {
  switch (brand) {
    case 'uber':
      return <UberHeader />;
    default:
      return <GenericHeader />;
  }
};

export default Header;
