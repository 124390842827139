import React from 'react';

import BagCard from './BagCard';
import { Bag, Passenger } from './CommonTypes';
import { connect } from 'react-redux';
import { formatPrice } from '../../utils/formatPrice';

function BagsContainer({
  bagsToDisplay,
  passengers,
  maxProductPrice,
  isMobile,
  variants,
  translate,
  fontFamily,
}: {
  bagsToDisplay: Array<Bag>;
  passengers: [Passenger];
  maxProductPrice: { amount: number; currency: string; decimal_places: number };
  isMobile: boolean;
  variants: { [key: string]: any };
  translate: any;
  fontFamily: string;
}) {
  const formattedMaxPrice = formatPrice(
    maxProductPrice?.amount ?? 0,
    maxProductPrice?.currency ?? 'USD',
    maxProductPrice?.decimal_places ?? 0,
    true,
  );

  const canvas = document.createElement('canvas');
  const context = canvas.getContext('2d') as CanvasRenderingContext2D;

  context.font = `16px ${fontFamily || 'Roboto'}`;
  let addButtonText = '';
  if (variants.test_baggage_text_on_add_button) {
    addButtonText += translate(
      variants.test_baggage_text_on_add_button.toLowerCase(),
    );
    addButtonText += ' ';
  }
  if (variants.test_baggage_price_on_add_button) {
    addButtonText += '+' + formattedMaxPrice;
  }

  let addButtonTextWidth = context.measureText(addButtonText).width;

  return (
    <div
      id={'bags-container'}
      className={`${isMobile ? '' : 'gr-flex gr-flex-col gr-items-center'}`}
    >
      {bagsToDisplay
        .sort(
          (bag1, bag2) =>
            bag1.product_details.weight - bag2.product_details.weight,
        )
        .sort((bag) =>
          bag.product_details.display_name.toLowerCase().includes('cabin')
            ? -1
            : 1,
        )
        .map((bag, index: number) => (
          <BagCard
            index={index}
            bag={bag}
            passengers={passengers}
            key={bag.product_id}
            isMobile={isMobile}
            addButtonTextWidth={addButtonTextWidth}
          />
        ))}
    </div>
  );
}

const mapStateToProps = (state: any) => ({
  variants: state.session.variants,
  maxProductPrice: state.products.maxProductPrice,
  fontFamily: state.session.fontFamily,
});

export default connect(mapStateToProps, {})(BagsContainer);
