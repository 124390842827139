const travelperk = {
  textPrimary: '#194EC0',
  textSecondary: '#79879D',
  title: '#120C80',
  primary: '#194EC0',
  secondary: '#79879D',
  buttonBorderRadius: '4px',
  alert: '#FC6363',
  seats: {
    1: {
      fill: '#FF739F',
      stopColor: '#DC3367'
    },
    2: {
      fill: '#8C6EE2',
      stopColor: '#6740D6'
    },
    3: {
      fill: '#FFC82C',
      stopColor: '#E5A517'
    },
    4: {
      fill: '#194EC0',
      stopColor: '#003097'
    },
    not_available: {
      fill: '#D9D9D9',
      stopColor: '#D9D9D9 '
    },
    selected_seat: {
      fill: '#2AC981',
      stopColor: '#0FA863'
    }
  }
};

export default travelperk;
