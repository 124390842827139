import React, { Component } from 'react';
import FocusTrap from 'focus-trap-react';

import { ReactComponent as Close } from '../../svg/close.svg';

interface SeatDisclaimerModalInterface {
  // Pass in these props.
  name: string; // i.e., "exit-regulation", "upsell"
  modalTitle: JSX.Element; // i.e., <Translate id="exit_modal_title"></Translate>
  title: JSX.Element; // i.e., <Translate id="exit_title"></Translate>
  bulletPoints: JSX.Element;
  confirmIntentText: JSX.Element; // i.e., "<h4><Translate id="exit_regulations_criteria"</h4>
  acceptButton: JSX.Element; // i.e., a button setup to handle a click when the choice is accepted
  declineButton: JSX.Element;
  showThisModal: (doShow: boolean) => void; // likely a redux setter, i.e., showExitModal(doShow).
  direction: string;
}

// A flexible container for displaying info when selecting seats that we want to display additional information
// for, i.e., an exit row seat requires us to display additional regulatory information.
// This component will also be used when we are upselling to a higher cabin class.
export default class SeatDisclaimerModal extends Component<
  SeatDisclaimerModalInterface
> {
  private node?: HTMLDivElement;

  constructor(props: SeatDisclaimerModalInterface) {
    super(props);
    this.node = undefined;
  }

  handleOutsideClick(e: React.MouseEvent<HTMLDivElement, MouseEvent>) {
    if ((e.target as HTMLDivElement).id === this?.node?.id) {
      this.props.showThisModal(false);
    }
  }

  render(): JSX.Element {
    const {
      name,
      modalTitle,
      title,
      bulletPoints,
      confirmIntentText,
      acceptButton,
      declineButton,
      direction
    } = this.props;

    return (
      <FocusTrap focusTrapOptions={{ initialFocus: `#${name}-overlay` }}>
        <div
          id={`${name}-overlay`}
          tabIndex={-1}
          ref={node => (this.node = node as HTMLDivElement)}
          onClick={element => this.handleOutsideClick(element)}
          className="gr-rounded gr-fixed gr-top-0 gr-left-0 gr-w-full gr-h-full"
          style={{ zIndex: 9999, background: 'rgba(0, 0, 0, 0.3)' }}
          dir={direction}
        >
          <div className={`gr-${name} gr-w-full gr-rounded`}>
            <div
              className={`gr-${name} gr-w-full gr-block gr-rounded gr-bg-white gr-h-full sm:gr-h-450 sm:gr-w-10/12 md:gr-w-7/12`}
              style={{
                maxWidth: '100%',
                maxHeight: '100%',
                position: 'fixed',
                left: '50%',
                top: '50%',
                transform: 'translate(-50%, -50%)'
              }}
            >
              <header
                className={`gr-${name} gr-flex gr-rounded gr-px-4 gr-bg-white gr-border-b gr-text-gray-800 gr-h-20 gr-border-grey-500 gr-items-center gr-justify-between`}
              >
                <div className={`gr-${name}`}>{title}</div>
                <button
                  onClick={e => {
                    e.preventDefault();
                    this.props.showThisModal(false);
                  }}
                  id={`close-${name}-overlay`}
                  aria-label={
                    name === 'exit-regulations'
                      ? 'Close Exit Regulations'
                      : 'Close Modal'
                  }
                >
                  <Close
                    className="gr-mx-2 gr-cursor-pointer"
                    style={{ width: 14, height: 14 }}
                  />
                </button>
              </header>
              <div
                className={`gr-${name} gr-block r-relative gr-w-full sm:gr-p-4 gr-p-6`}
              >
                <h1 className={`gr-${name} gr-text-base gr-text-black gr-mb-6`}>
                  {modalTitle}
                </h1>
                <div
                  className={`gr-${name} gr-text-gray-700 gr-text-sm sm:gr-text-xs`}
                >
                  {bulletPoints}
                  <div className={`gr-${name} gr-text-base gr-text-black`}>
                    <h4 className={`gr-${name} gr-mb-2`}>
                      {confirmIntentText}
                    </h4>
                  </div>
                </div>
              </div>
              <div
                className={`gr-${name} footer gr-rounded gr-absolute gr-bottom-0 gr-h-20 gr-border gr-bg-white gr-w-full`}
              >
                <footer
                  className={`gr-${name} gr-flex gr-items-center gr-justify-between gr-h-full gr-w-full gr-p-2`}
                >
                  <div className={`gr-${name} md:gr-m-4 gr-m-2 gr-w-1/2`}>
                    {declineButton}
                  </div>
                  <div className={`gr-${name} md:gr-m-4 gr-m-2 gr-w-1/2`}>
                    {acceptButton}
                  </div>
                </footer>
              </div>
            </div>
          </div>
        </div>
      </FocusTrap>
    );
  }
}
