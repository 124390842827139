import React, { PureComponent } from 'react';
import { connect } from 'react-redux';

interface AisleProps {
  row: any;
  locale: string;
  useEnhancedDetails: boolean;
  first: boolean;
  last: boolean;
}

class Aisle extends PureComponent<AisleProps, {}> {
  render() {
    const useEnhancedDetails = this.props.useEnhancedDetails;
    const first = this.props.first;
    const last = this.props.last;
    return (
      <div
        className={`aisle gr-flex gr-justify-center ${
          useEnhancedDetails ? 'gr-px-1 gr-mx-1' : ''
        } ${
          useEnhancedDetails ? 'gr-enhanced-aisle' : ''
        } gr-text-secondary-text gr-items-center`}
        style={{
          borderRadius: first ? '10px 10px 0 0' : last ? '0 0 10px 10px' : ''
        }}
      >
        <div
          className="sm:gr-text-sm sm:gr-block gr-content-center gr-justify-center"
          style={{ fontSize: '8px' }}
        >
          <p>
            {this.props.row
              ? new Intl.NumberFormat(this.props.locale).format(this.props.row)
              : '-'}
          </p>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: { session: { locale: string } }) => ({
  locale: state.session.locale
});

export default connect(mapStateToProps, {})(Aisle);
