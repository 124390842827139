import React, { PureComponent } from 'react';

export interface IGenericDetailsContainerProps {
  idPrefix: string;
  mobile: boolean;
  height?: number;
}

class GenericDetailsContainer extends PureComponent<
  IGenericDetailsContainerProps
> {
  constructor(props: IGenericDetailsContainerProps) {
    super(props);
    const { height = 150 } = props;
    this.state = {
      height
    };
  }

  ref = React.createRef<HTMLInputElement>();

  componentDidMount() {
    if (this.ref.current) {
      this.setState({ height: this.ref.current.clientHeight });
    }
  }

  render() {
    const { children, idPrefix } = this.props;
    return (
      <div
        ref={this.ref}
        id={`${idPrefix}-container`}
        tabIndex={-1}
        className={
          'seat-details seat-details-mobile gr-absolute gr-block gr-w-11/12 gr-max-w-lg gr-z-60 gr-text-sm gr-w-260px gr-bg-white gr-rounded-lg gr-shadow-lg'
        }
        style={{ bottom: 100, left: '50%', transform: 'translate(-50%, 0%)' }}
      >
        {children}
      </div>
    );
  }
}

export default GenericDetailsContainer;
