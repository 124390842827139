import React, { useEffect, useState } from 'react';
import { ReactComponent as SelectArrow } from '../../svg/baggage-select-arrow.svg';
import { ReactComponent as SelectArrowBlack } from '../../svg/baggage-select-arrow-black.svg';
import Select, { components } from 'react-select';
import { Journey } from './CommonTypes';
import { Translate } from 'react-localize-redux';

export default function ItinerarySelect({
  activeJourney,
  direction,
  handleChange,
  handleOpen,
  isMobile,
  options,
  itinerarySelectRef,
}: {
  activeJourney: Journey;
  direction: string;
  handleChange: (option: any) => void;
  handleOpen: (isOpen: boolean) => void;
  isMobile: boolean;
  options: any;
  itinerarySelectRef: React.Ref<any>;
}) {
  const customStyles = {
    control: (provided: any) => ({
      ...provided,
      border: 'none',
      boxShadow: 'none',
      cursor: 'pointer',
    }),
    indicatorSeparator: () => ({
      display: 'none',
    }),
    valueContainer: (provided: any) => ({
      ...provided,
      width: 0,
      height: 0,
      opacity: 0,
    }),
    menu: (provided: any) => ({
      ...provided,
      width: options[0].label.length * 12 + 15,
      right: 0,
    }),
    option: (provided: any, { isSelected }: { isSelected: boolean }) => ({
      ...provided,
      color: 'black',
      fontSize: '16px',
      backgroundColor: isSelected ? '#B4D4FF' : undefined,
      cursor: 'pointer',
      ':hover': {
        backgroundColor: !isSelected ? '#F0F0F0' : undefined,
      },
    }),
  };

  const DropdownIndicator = (props: any) => {
    return (
      <components.DropdownIndicator
        {...props}
        className={'gr-pr-0 gr-pl-4 gr-py-0'}
      >
        {isMobile ? <SelectArrow /> : <SelectArrowBlack />}
      </components.DropdownIndicator>
    );
  };

  const [currentlySelectedOption, setCurrentlySelectedOption] = useState();

  useEffect(() => {
    setCurrentlySelectedOption(
      options.find(
        (o: HTMLOptionElement) =>
          o.value === `${activeJourney.from}-${activeJourney.to}`,
      ),
    );
  }, [activeJourney, options]);

  useEffect(() => {
    setCurrentlySelectedOption(
      options.find(
        (o: HTMLOptionElement) =>
          o.value === `${activeJourney.from}-${activeJourney.to}`,
      ),
    );
  }, [activeJourney, options]);

  return (
    <div className={`gr-flex gr-relative gr-right-0 gr-items-center`}>
      <span
        className="gr-text-xs gr-text-gray-900 gr-relative gr-z-10 gr-left-4"
        id="itinerary-select-text"
      >
        {isMobile ? (
          <Translate id={direction.toLowerCase()}>{direction}</Translate>
        ) : (
          options.findIndex(
            (o: HTMLOptionElement) =>
              o.value === (currentlySelectedOption as any)?.value,
          ) +
          1 +
          '/' +
          options.length
        )}
      </span>
      <Select
        ref={itinerarySelectRef}
        styles={customStyles}
        onChange={(option) => {
          setCurrentlySelectedOption(option as any);
          handleChange(option);
        }}
        options={options}
        classNamePrefix={'itinerary-select'}
        components={{ DropdownIndicator, SingleValue: (() => null) as any }}
        value={currentlySelectedOption}
        openMenuOnFocus={true}
        isSearchable={false}
        //I added this timeout since else when clicking the itinerary bar it will trigger this, close, and then reopen immediately because it regains focus
        onMenuClose={() => setTimeout(handleOpen, 300, false)}
        onMenuOpen={() => setTimeout(handleOpen, 300, true)}
      />
    </div>
  );
}
