import React from 'react';

export default function Link(props) {
  return (
    <button
      className="gr-m-2 gr-text-link gr-p-0 gr-border-none gr-background-none gr-underline gr-cursor-pointer"
      {...props}
    >
      {props.children}
    </button>
  );
}
