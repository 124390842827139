import React, { PureComponent } from 'react';

export class Closet extends PureComponent {
  render() {
    return (
      <svg
        viewBox="0 -39 512.00009 512"
        width="80%"
        height="80%"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillOpacity="0.5"
          d="m477.972656 303.144531-138.453125-83.292969c-19.449219-11.699218-41.082031-19.125-63.464843-21.917968v-20.863282c0-11.753906 6.730468-22.695312 17.5625-28.558593 26.914062-14.566407 42.761718-42.621094 41.351562-73.21875-1.863281-40.527344-34.628906-73.34375-75.207031-75.2070315-45.488281-2.0742185-82.707031 34.1406245-82.707031 78.9140625 0 11.046875 8.957031 20 20 20 11.046874 0 20-8.953125 20-20 0-22.140625 18.402343-39.992188 40.867187-38.957031 19.882813.914062 36.175781 17.207031 37.089844 37.089843.699219 15.132813-7.132813 29.003907-20.4375 36.203126-23.757813 12.859374-38.519531 37.28125-38.519531 63.734374v20.851563c-22.289063 2.765625-43.84375 10.128906-63.238282 21.734375l-138.648437 82.972656h-.003907c-21.042968 12.59375-34.1367182 35.652344-34.164062 60.179688-.0429688 38.8125 31.328125 70.261718 70.144531 70.308594l371.542969.433593h.085938c38.683593 0 70.179687-31.453125 70.226562-70.148437.027344-24.523438-13.011719-47.617188-34.027344-60.257813zm-36.199218 90.40625c-.011719 0-.027344 0-.035157 0l-371.542969-.433593c-16.671874-.019532-30.214843-13.59375-30.195312-30.261719.011719-10.558594 5.648438-20.484375 14.707031-25.90625h-.003906l138.652344-82.96875c38.640625-23.125 86.914062-23.089844 125.542969.144531l138.457031 83.292969c9.042969 5.441406 14.65625 15.382812 14.644531 25.9375-.019531 16.65625-13.574219 30.195312-30.226562 30.195312zm0 0"
        />
      </svg>
    );
  }
}
