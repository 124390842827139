const cheaptickets = {
  textPrimary: '#597385',
  textSecondary: '#29425b',
  buttonText: '#29425b',
  title: '#00b0ff',
  primary: '#fff23e',
  secondary: '#7FD4F1',
  alert: '#DF374B',
  actionButtonBorderRadius: '8px',
  buttonBorderRadius: '8px',
  seats: {
    1: {
      fill: 'rgba(0, 178, 80, 0.2)',
      stopColor: 'rgba(0, 178, 80, 0.2)',
      labelColor: '#29425b',
    },
    2: {
      fill: '#faf18f',
      stopColor: '#faf18f',
      labelColor: '#29425b',
    },
    3: {
      fill: 'rgba(0, 75, 148, 0.2)',
      stopColor: 'rgba(0, 75, 148, 0.2)',
      labelColor: '#29425b',
    },
    4: {
      fill: 'rgba(126, 166, 212, 1.0)',
      stopColor: 'rgba(126, 166, 212, 1.0)',
      labelColor: '#ffffff',
    },
    5: {
      fill: 'rgba(0, 176, 255, 0.2)',
      stopColor: 'rgba(0, 176, 255, 0.2)',
      labelColor: '#29425b',
    },
    6: {
      fill: '#004b94',
      stopColor: '#004b94',
    },
    not_available_seat: {
      fill: 'g7f7f7f',
      stopColor: 'g7f7f7f',
    },
    selected_seat: {
      fill: '#00b0ff',
      stopColor: '#00b0ff',
      labelColor: '#ffffff',
    },
  },
};

export default cheaptickets;
