import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Translate } from 'react-localize-redux';
import { rtlLangs } from '../../translations/rtl-langs';

export class Airline extends Component {
  render() {
    const { currentSeatmap, flights = [], locale } = this.props;
    const [currentFlight = {}] = flights.filter((flight) => {
      return flight.segment_id === currentSeatmap;
    });

    const allOperatingFlightInfoIsAvailable = Boolean(
      currentFlight.operating_airline &&
        currentFlight.operating_flight_number &&
        currentFlight.operating_airline_logo
    );

    const [airline, flightNumber, airlineLogo] =
      allOperatingFlightInfoIsAvailable
        ? [
            currentFlight.operating_airline,
            currentFlight.operating_flight_number,
            currentFlight.operating_airline_logo,
          ]
        : [
            currentFlight.marketing_airline,
            currentFlight.marketing_flight_number,
            currentFlight.marketing_airline_logo,
          ];

    return (
      <div
        className="gr-hidden md:gr-flex sm:p-6 gr-text-sm"
        style={{ minWidth: 115 }} // Prevent text from wrapping to more 3 lines
      >
        <img
          className="gr-h-12 gr-w-12 gr-rounded-full"
          alt={airline}
          aria-label={airline + ' ' + flightNumber}
          src={airlineLogo}
        />
        <div className="gr-block gr-text-left gr-ml-2 gr-mt-1">
          <h2 className="gr-text-sm gr-text-title">
            {airline}
            {flightNumber}
          </h2>
          <div
            className="operated_by gr-text-secondary-text gr-text-sm"
            dir={rtlLangs.includes(locale) ? 'rtl' : 'ltr'}
          >
            <Translate id="operated_by" />: {airline}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  flights: state.itinerary.flights,
  currentSeatmap: state.itinerary.currentSeatmap,
  locale: state.session.locale,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Airline);
