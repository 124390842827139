import React from 'react';
import { connect } from 'react-redux';

function LegendIcon({ theme }) {
  const { legendIconColor, textPrimary } = theme;
  const fill = legendIconColor || textPrimary;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="10"
      fill="none"
      viewBox="0 0 18 10"
    >
      <path
        fill={fill}
        fillRule="evenodd"
        d="M0 2h2V0H0v2zm2 4H0V4h2v2zm0 4H0V8h2v2zm16-4H4V4h14v2zM4 10h14V8H4v2zm0-8V0h14v2H4z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
}

const mapStateToProps = (state) => ({
  theme: state.session.theme,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(LegendIcon);
