export function notAllSegmentsHaveSeats(basket, itinerary, passengers) {
  const { seats = [] } = basket;
  const { flights = [] } = itinerary;

  const totalAvailableFlights = flights.filter(flight => flight.available)
    .length;
  const totalSeatablePassengers = passengers.filter(
    passenger => !passenger.on_lap_of
  ).length;
  const expectedSeats = totalAvailableFlights * totalSeatablePassengers;
  return seats.length !== expectedSeats;
}

export function missingSeatsForSegment(basket, passengers, segment_id) {
  const { seats = [] } = basket;
  console.log(seats);

  const totalSeatablePassengers = passengers.filter(
    passenger => !passenger.on_lap_of
  ).length;

  const seatsSelectedOnSegment = seats.filter(
    seat => seat.segment_id === segment_id
  ).length;
  return totalSeatablePassengers !== seatsSelectedOnSegment;
}
