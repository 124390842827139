export function parseAcceptedBags(basket, baggageData) {
  const adjustedBags = {};

  for (const [passengerId, passengerSelections] of Object.entries(
    basket.bags,
  )) {
    adjustedBags[passengerId] = adjustedBags[passengerId] ?? {};
    for (const [productId, selectionDetails] of Object.entries(
      passengerSelections,
    )) {
      if (selectionDetails.totalPrice) {
        adjustedBags[passengerId][productId] = selectionDetails;
      } else {
        const bags = Object.values(baggageData.results?.products.bag ?? {});
        const matchingBag = bags.find((b) => b.product_id === productId);
        if (matchingBag) {
          const availabilityPerPassenger = Object.values(
            matchingBag.price_and_availability,
          );
          const matchingAvailabilityEntry = availabilityPerPassenger
            .flat()
            .find(
              (availabilityEntry) =>
                availabilityEntry.passenger_id === passengerId &&
                availabilityEntry.quantity === selectionDetails,
            );
          if (matchingAvailabilityEntry) {
            adjustedBags[passengerId][productId] = {
              quantity: matchingAvailabilityEntry.quantity,
              totalPrice: matchingAvailabilityEntry.price.total,
              ...(matchingAvailabilityEntry.unique_id && {
                uniqueId: matchingAvailabilityEntry.unique_id,
              }),
            };
          }
        }
      }
    }

    if (Object.keys(adjustedBags[passengerId]).length === 0) {
      delete adjustedBags[passengerId];
    }
  }

  return {
    ...basket,
    bags: adjustedBags,
  };
}
