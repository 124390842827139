import hopper from './hopper';
import travelperk from './travelperk';
import budgetair from './budgetair';
import cheaptickets from './cheaptickets';
import nubank from './nubank';
import priceline from './priceline';
import uber from './uber';
import allegris from './allegris';

export const themes = {
  hopper,
  travelperk,
  allegris,
  budgetair,
  cheaptickets,
  nubank,
  priceline,
  uber,
};

export const setUberFont = () => {
  var style = document.createElement('style');
  style.type = 'text/css';
  style.appendChild(
    document.createTextNode(`
      @font-face {
        font-family: 'Uber Move';
        font-style: normal;
        font-weight: normal;
        src: url(../fonts/font/UberMoveTextRegular.otf) format('opentype');
      }
      @font-face {
        font-family: 'Uber Move';
        font-style: normal;
        font-weight: light;
        src: url(../fonts/font/UberMoveTextLight.otf) format('opentype');
      }
      @font-face {
        font-family: 'Uber Move';
        font-style: normal;
        font-weight: bold;
        src: url(../fonts/font/UberMoveTextBold.otf) format('opentype');
      }
      @font-face {
        font-family: 'Uber Move';
        font-style: normal;
        font-weight: medium;
        src: url(../fonts/font/UberMoveTextMedium.otf) format('opentype');
      }
    `)
  );
  document.head.appendChild(style);
};
