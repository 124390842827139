import React from 'react';
import { ReactComponent as LuggageIcon } from '../../svg/luggage-small-icon.svg';
import { BagDetails } from './CommonTypes';
import { Translate } from 'react-localize-redux';

export function WeightText({
  bagDetails,
  centered = false,
  extraClassName = '',
}: {
  bagDetails: BagDetails;
  centered?: boolean;
  extraClassName?: string;
}) {
  return (
    <p
      className={`gr-bag-max-weight-text gr-leading-normal gr-text-gray-550 gr-text-xs gr-font-semibold gr-not-italic gr-flex ${
        centered ? 'gr-justify-center gr-m-0' : ''
      } ${extraClassName}`}
    >
      <LuggageIcon className="gr-pr-1" />{' '}
      <Translate id={'up_to'}>Up to</Translate>{' '}
      {bagDetails.weight + bagDetails.weight_unit}
    </p>
  );
}
