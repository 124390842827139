import React, { Component } from 'react';
import GenericValidationPopup from './templates/GenericValidationPopup';
import { ReactComponent as InfantIcon } from '../../svg/infant-error.svg';
import Button from '../shared/Button';
import { Translate } from 'react-localize-redux';
import { setShowSeatmapValidateInfant } from '../../redux/actions';
import { connect, ConnectedProps } from 'react-redux';
import FocusTrap from 'focus-trap-react';
import MediaQuery from 'react-responsive';

const mapStateToProps = (state: any) => ({
  relativeWidthRatio: state.session.relativeWidthRatio
});

const mapDispatchToProps = {
  setShowSeatmapValidateInfant
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

interface SeatInfantValidationPopupProps extends PropsFromRedux {
  mobile?: boolean;
  parentHeight: number;
  position: any;
  exitModal: boolean;
}

export class SeatInfantValidationPopup extends Component<
  SeatInfantValidationPopupProps
> {
  render() {
    const { mobile = false } = this.props;

    const popupIcon = <InfantIcon />;
    const primary = <Translate id="infants_next_to_adults" />;
    const secondary = <Translate id="infants_validation" />;
    const button = (
      <Button
        primary={true}
        handleClick={() => this.props.setShowSeatmapValidateInfant(false)}
      >
        <Translate id="change_seats" />
      </Button>
    );

    return (
      <MediaQuery minWidth={650 / this.props.relativeWidthRatio}>
        {matches => (
          <FocusTrap
            focusTrapOptions={{ initialFocus: '#seat-validation-container' }}
            active={!mobile && matches && !this.props.exitModal}
          >
            <GenericValidationPopup
              mobile={mobile}
              popupIcon={popupIcon}
              primary={primary}
              secondary={secondary}
              button={button}
              id="infant-details"
              parentHeight={this.props.parentHeight}
              {...this.props.position}
            />
          </FocusTrap>
        )}
      </MediaQuery>
    );
  }
}

export default connector(SeatInfantValidationPopup);
