import React, { Component } from 'react';

import Button from '../shared/Button';
import { connect } from 'react-redux';
import { Translate } from 'react-localize-redux';

import {
  showExitModal,
  acceptExitRegulation,
  updateSelectedSeat,
  showUpsellModal,
  acceptSeat,
  confirmBasket
} from '../../redux/actions';
import SeatDisclaimerModal from './SeatDisclaimerModal';
import { isMobile } from 'react-device-detect';
import MediaQuery from 'react-responsive';
import { rtlLangs } from '../../translations/rtl-langs';

class ExitRegulations extends Component {
  makeModalTitle() {
    return <Translate id="exit_regulations_modal_title"></Translate>;
  }

  makeTitle() {
    return <Translate id="exit_regulations_title" />;
  }

  makeBulletPoints() {
    const reasons = [
      <Translate id="exit_regulations_reason_1" key="reason-1" />,
      <Translate id="exit_regulations_reason_2" key="reason-2" />,
      <Translate id="exit_regulations_reason_3" key="reason-3" />,
      <Translate id="exit_regulations_reason_4" key="reason-4" />,
      <Translate id="exit_regulations_reason_5" key="reason-5" />,
      <Translate id="exit_regulations_reason_6" key="reason-6" />
    ];

    return (
      <ul
        className="gr-exit-regulations gr-list-inside gr-mb-8"
        style={{ listStyle: 'circle' }}
      >
        {reasons.map((reason, index) => (
          <li key={index} className="gr-exit-regulations gr-mb-2">
            {reason}
          </li>
        ))}
      </ul>
    );
  }

  makeConfirmIntentText() {
    return <Translate id="exit_regulations_criteria"></Translate>;
  }

  makeAcceptButton() {
    const {
      variants,
      selectedSeat,
      currentSeatmap,
      currentPassenger,
      acceptSeat,
      acceptExitRegulation,
      relativeWidthRatio,
      selectedProduct,
      confirmBasket
    } = this.props;
    const {
      test_seatmap_one_click_desktop = true,
      test_seatmap_one_click_mobile = false,
      test_confirm_on_click = false
    } = variants;
    const seat = selectedSeat;
    return (
      <MediaQuery maxWidth={620 / relativeWidthRatio}>
        {isMobileSize => (
          <Button
            handleClick={() => {
              acceptExitRegulation(true);
              const isMobileLike = isMobile || isMobileSize;
              const test_seatmap_one_click = isMobileLike
                ? test_seatmap_one_click_mobile
                : test_seatmap_one_click_desktop;
              if (test_seatmap_one_click) {
                acceptSeat({
                  currentSeatmap: currentSeatmap,
                  selectedSeat: seat,
                  currentPassenger: currentPassenger,
                  selectedProduct: selectedProduct
                });
              }
              this.props.showExitModal(false);
              test_confirm_on_click && confirmBasket(); // Confirm basket if Otravo embedded version
            }}
            id="accept_exit_regulations"
            primary
          >
            <Translate id="exit_regulations_accept" />
          </Button>
        )}
      </MediaQuery>
    );
  }

  makeDeclineButton() {
    return (
      <Button
        handleClick={() => {
          this.props.showExitModal(false);
          this.props.updateSelectedSeat(null);
          // If the upsell is currently showing, and a user declines the exit regulations, close the upsell window too.
          if (this.props.upsellModal) {
            this.props.showUpsellModal(false);
          }
        }}
        id="decline_exit_regulations"
      >
        <Translate id="exit_regulations_decline" />
      </Button>
    );
  }

  render() {
    return (
      <SeatDisclaimerModal
        name={'exit-regulations'}
        modalTitle={this.makeModalTitle()}
        title={this.makeTitle()}
        bulletPoints={this.makeBulletPoints()}
        confirmIntentText={this.makeConfirmIntentText()}
        acceptButton={this.makeAcceptButton()}
        declineButton={this.makeDeclineButton()}
        direction={rtlLangs.includes(this.props.locale) ? 'rtl' : 'ltr'}
        showThisModal={this.props.showExitModal}
      />
    );
  }
}

const mapStateToProps = state => ({
  upsellModal: state.session.upsellModal,
  currentPassenger: state.passengers.currentPassenger,
  currentSeatmap: state.itinerary.currentSeatmap,
  selectedSeat: state.selectedSeat,
  variants: state.session.variants,
  relativeWidthRatio: state.session.relativeWidthRatio,
  selectedProduct:
    state.products.products?.seat?.[state.selectedSeat?.product_id] || null,
  locale: state.session.locale
});

const mapDispatchToProps = {
  showExitModal,
  acceptExitRegulation,
  updateSelectedSeat,
  showUpsellModal,
  acceptSeat,
  confirmBasket
};

export default connect(mapStateToProps, mapDispatchToProps)(ExitRegulations);
