import React, { Component } from 'react';
import { v4 as uuidv4 } from 'uuid';

let uniqueNum = 0;
const getUniqueNum = () => (uniqueNum += 1);

export default class Colums extends Component {
  render() {
    const { columns = [], aisleMarginX } = this.props;

    const columnGroups = columns.map((c) => (
      <div
        key={uuidv4()}
        className={`row-group gr-flex gr-gap-1 gr-justify-center gr-items-center sm:gr-px-2`}
      >
        {c.map((v) => {
          return (
            <div
              key={uuidv4()}
              className="gr-flex gr-text-center gr-justify-center gr-items-center gr-h-8 gr-w-8 sm:gr-h-10 sm:gr-w-10 md:gr-h-12 md:gr-w-12"
            >
              {v}
            </div>
          );
        })}
      </div>
    ));

    //groups columns same way as rows to keep spacing consistent between both
    const groupedColumnsWithAisles = columnGroups.reduce(
      (acc, val, index, list) => {
        acc.push(val);
        if (index + 1 !== list.length) {
          acc.push('');
        }
        return acc;
      },
      [],
    );

    return (
      <div className="gr-flex gr-items-center gr-text-secondary-text gr-justify-between gr-border gr-mt-2 gr-mb-1 gr-rounded-lg gr-text-sm">
        {groupedColumnsWithAisles.map((columnGroup) => {
          return columnGroup === '' ? (
            <div
              key={`${columnGroup}–${getUniqueNum()}`}
              //last class is there to take extra aisle width into account
              className={`gr-h-8 gr-w-8 sm:gr-h-10 sm:gr-w-10 md:gr-h-12 md:gr-w-12 ${aisleMarginX}`}
            ></div>
          ) : (
            columnGroup
          );
        })}
      </div>
    );
  }
}
