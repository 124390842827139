const getDisplayPrice = (display_variant, base_price, total_price) => {
  try {
    if (display_variant?.toLowerCase().startsWith('variant'))
      return base_price !== undefined && base_price !== null
        ? base_price
        : total_price;
  } catch (e) {}
  return total_price;
};

module.exports = {
  getDisplayPrice,
};
