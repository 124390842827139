export const setDefaultPassengers = (
  passengers: any[],
  languageConfigurations: any[],
  language: string
) => {
  // Add passenger name defaults if not present
  const passengerCount = { passenger: 0, child: 0 };
  passengers.forEach(passenger => {
    const passengerType = passenger.type === 'adult' ? 'passenger' : 'child';
    passengerCount[passengerType]++;
    if (!passenger.first_names) {
      passenger.first_names = languageConfigurations.find(
        lang => lang.code === language
      ).translation[passengerType];
    }
    if (!passenger.surname) {
      passenger.surname = Intl.NumberFormat(language).format(
        passengerCount[passengerType]
      );
    }
  });
};
