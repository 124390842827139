const speakContainerId = 'speak-container';

// inspired by: https://a11y-guidelines.orange.com/en/web/components-examples/make-a-screen-reader-talk/
function speak(whatToSay) {
  const el = document.createElement('div');
  const id = 'speak-' + Date.now();
  el.setAttribute('id', id);
  el.setAttribute('aria-live', 'polite');
  el.classList.add('gr-sr-only');
  const speakContainer = document.getElementById(speakContainerId);
  if (speakContainer) {
    speakContainer.appendChild(el);

    window.setTimeout(() => {
      const whatToSayElement = document.getElementById(id);
      if (whatToSayElement) {
        whatToSayElement.innerHTML = whatToSay;
      }
    }, 100);

    window.setTimeout(() => {
      const toRemove = document.getElementById(id);
      if (toRemove) {
        speakContainer.removeChild(toRemove);
      }
    }, 1000);
  }
}

module.exports = { speak, speakContainerId };
