import React from 'react';

function SeatRecline({ fill = '#2990E6' }) {
  return (
    <div>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="32"
        height="32"
        fill="none"
        viewBox="0 0 32 32"
      >
        <path
          fill="#8795A3"
          d="M21.333 25.333h-9.426a3.988 3.988 0 01-3.947-3.387L5.333 9.333H2.667L5.32 22.346A6.68 6.68 0 0011.92 28h9.413v-2.667z"
        ></path>
        <path
          fill={fill}
          d="M7.133 7.52A2.684 2.684 0 016.48 3.8a2.682 2.682 0 013.72-.653 2.684 2.684 0 01.653 3.72 2.684 2.684 0 01-3.72.653zM21.64 20h-6.507l-1.373-5.467c2.107 1.187 4.373 2.054 6.867 1.627v-2.84c-2.174.413-4.587-.36-6.254-1.667L12.187 9.96c-.307-.24-.654-.4-1.014-.507-.426-.12-.88-.16-1.32-.08h-.026a3.024 3.024 0 00-2.454 3.48l1.8 7.894A4.011 4.011 0 0013.107 24h9.133l5.093 4 2-2-7.693-6z"
        ></path>
      </svg>
    </div>
  );
}

export default SeatRecline;
