import React, { Component } from 'react';
import { connect } from 'react-redux';
import { setCurrentPassenger } from '../../redux/actions';
import { speak } from '../../utils/sideEffects/speak';

import PassengerSelect from './PassengerSelect';
class PassengersList extends Component {
  handleChange = index => {
    const { setCurrentPassenger, passengers } = this.props;
    setCurrentPassenger({ ...passengers[index] });
  };

  componentDidUpdate(previousProps) {
    const { currentPassenger = {} } = this.props;
    const { currentPassenger: previousCurrentPassenger = {} } = previousProps;

    if (
      currentPassenger.passenger_id !== previousCurrentPassenger.passenger_id
    ) {
      const currentPassengerName = `${currentPassenger.first_names} ${currentPassenger.surname}`;
      speak(`Now selecting for ${currentPassengerName}`);
    }
  }

  render() {
    const {
      passengers = [],
      currentPassenger = {},
      seats = [],
      currentSeatmap,
      variants,
      locale
    } = this.props;
    const [seatForCurrentPassenger = {}] = seats.filter(
      seat =>
        seat.passenger_id === currentPassenger.passenger_id &&
        seat.segment_id === currentSeatmap
    );

    let brand = undefined;
    if (variants.test_uber_theme === true) brand = 'uber';

    return (
      <PassengerSelect
        id="passenger-select"
        length={this.props.passengers.length}
        current={this.props.currentPassenger}
        handleChange={this.handleChange}
        currentSeat={seatForCurrentPassenger}
        value={currentPassenger.index}
        relativeWidthRatio={this.props.relativeWidthRatio}
        brand={brand}
        locale={locale}
      >
        {passengers.map((passenger, index) => (
          <option
            aria-selected={
              passenger.passenger_id === currentPassenger.passenger_id
                ? 'true'
                : 'false'
            }
            aria-label={`Passenger: ${passenger.first_names} ${passenger.surname}`}
            key={`${passenger.first_names} ${passenger.surname}`}
            value={index}
          >
            {passenger.first_names} {passenger.surname}
          </option>
        ))}
      </PassengerSelect>
    );
  }
}

const mapStateToProps = state => ({
  seats: state.basket.seats,
  passengers: state.passengers.listOfPassenger,
  currentSeatmap: state.itinerary.currentSeatmap,
  currentPassenger: state.passengers.currentPassenger,
  relativeWidthRatio: state.session.relativeWidthRatio,
  variants: state.session.variants,
  locale: state.session.locale
});

const mapDispatchToProps = { setCurrentPassenger };

export default connect(mapStateToProps, mapDispatchToProps)(PassengersList);
