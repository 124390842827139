import './polyfills';
import { v4 as uuidv4 } from 'uuid';
import WebFont from 'webfontloader';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import { applyTheme, extend } from './themes/utils';

import * as Sentry from '@sentry/react';

import base from './themes/base';
import { themes as BrandThemes } from './themes/index';

import reducers from './redux/reducers';

import ModalOverlay from './components/shared/ModalOverlay';
import SeatMapTrigger from './components/seatmap/SeatMapTrigger';
import BaggageWidgetTrigger from './components/v1_baggage/BaggageWidgetTrigger';
import WidgetSelector from './widgets/WidgetSelector';

import './styles/tailwind.css';
import onTrackMiddleware from './middleware/tracking';

import {
  showModal,
  hasSeats,
  setTheme as setThemeInStore,
  setTextOverrides,
  setAcceptedProducts,
  setBaggageBasket,
  setIsModal,
  setEditMode,
  setAgentBrand,
  setLocale,
  setActiveWidget,
  hasBags,
} from './redux/actions.js';

import { LocalizeProvider } from 'react-localize-redux';

import { speakContainerId } from './utils/sideEffects/speak';

// https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/String/includes
if (!String.prototype.includes) {
  if (!String.prototype.includes) {
    // eslint-disable-next-line no-extend-native
    String.prototype.includes = function (search, start) {
      if (search instanceof RegExp) {
        throw TypeError('first argument must not be a RegExp');
      }
      if (start === undefined) {
        start = 0;
      }
      return this.indexOf(search, start) !== -1;
    };
  }
}

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  environment: process.env.REACT_APP_ENV,
  release: process.env.REACT_APP_RELEASE,
});

const seatMapTrigger = (anchor) => {
  // eslint-disable-next-line react/no-deprecated
  ReactDOM.render(
    <Provider store={window.gordianStore}>
      <LocalizeProvider store={window.gordianStore}>
        <SeatMapTrigger />
      </LocalizeProvider>
    </Provider>,
    anchor
  );
};

const baggageWidgetTrigger = (anchor) => {
  // eslint-disable-next-line react/no-deprecated
  ReactDOM.render(
    <Provider store={window.gordianStore}>
      <LocalizeProvider store={window.gordianStore}>
        <BaggageWidgetTrigger />
      </LocalizeProvider>
    </Provider>,
    anchor
  );
};

const upsellTrigger = (anchor) => {
  // eslint-disable-next-line react/no-deprecated
  ReactDOM.render(
    <Provider store={window.gordianStore}>
      <LocalizeProvider store={window.gordianStore}>
        <BaggageWidgetTrigger />
        <SeatMapTrigger />
      </LocalizeProvider>
    </Provider>,
    anchor
  );
};
const sentryReduxReducer = Sentry.createReduxEnhancer({});

window.gordianInternal = (() => {
  const init = ({
    onTrack,
    sessionId,
    onBasketChange,
    disableDefaultFontLoading = false,
  }) => {
    if (!disableDefaultFontLoading) {
      WebFont.load({
        google: {
          families: [
            'Roboto:100,200,300,400,500,600,700,800,900',
            'Inter:100,200,300,400,500,600,700,800,900',
            'Nunito Sans:100,200,300,400,500,600,700',
          ],
        },
      });
    }
    sessionId = sessionId || uuidv4();
    const tracking = new onTrackMiddleware(onTrack);

    // TODO: try to eliminate by passing onBasketChange into init instead of passing it into the render functions. The fact that it can be passed to the render functions suggests that different onBasketChanges can be handled, but that's not true.
    window.gordianInternal.onBasketChange = onBasketChange;

    const composeEnhancers =
      window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
    const enhancers = composeEnhancers(
      applyMiddleware(
        thunk.withExtraArgument({ gordianInternal: window.gordianInternal })
      ),
      applyMiddleware(tracking),
      sentryReduxReducer
    );
    const gordianStore = createStore(reducers, {}, enhancers);

    window.gordianStore = gordianStore;

    window._gordianSessionId = sessionId;

    return Promise.resolve({ message: 'init successfully' });
  };

  const setOnBasketChange = (onBasketChange) => {
    window.gordianInternal.onBasketChange =
      onBasketChange || window.gordianInternal.onBasketChange;
    if (window.gordianInternal.onBasketChange === undefined) {
      throw new Error('you must pass onBasketChange in Gordian.init');
    }
  };

  const setUpsellTickets = (upsellTickets) => {
    // Unlike onBasketChange, upsellTickets is an optional callback, so, we only care if it is undefined post-initiation
    // i.e., if we try and upsell a seat but upsellTickets is undefined. This behavior is documented in the callbacks.js
    // tests and in actions.js.
    window.gordianInternal.upsellTickets =
      upsellTickets || window.gordianInternal.upsellTickets;
  };

  const renderSeatmap = (anchor, props) => {
    setOnBasketChange(props.onBasketChange);
    setUpsellTickets(props.upsellTickets);
    // eslint-disable-next-line react/no-deprecated
    ReactDOM.unmountComponentAtNode(anchor);
    // eslint-disable-next-line react/no-deprecated
    ReactDOM.render(<UpsellApp allowProducts={['seats']} {...props} />, anchor);
  };

  const renderBaggageWidget = (anchor, props) => {
    props.widgetVersions = {
      bags: 1,
    };
    setOnBasketChange(props.onBasketChange);
    // eslint-disable-next-line react/no-deprecated
    ReactDOM.unmountComponentAtNode(anchor);
    // eslint-disable-next-line react/no-deprecated
    ReactDOM.render(<UpsellApp allowProducts={['bags']} {...props} />, anchor);
  };

  const renderBaggageWidget2 = (anchor, props) => {
    props.widgetVersions = {
      bags: 2,
    };
    setOnBasketChange(props.onBasketChange);
    // eslint-disable-next-line react/no-deprecated
    ReactDOM.unmountComponentAtNode(anchor);
    // eslint-disable-next-line react/no-deprecated
    ReactDOM.render(<UpsellApp allowProducts={['bags']} {...props} />, anchor);
  };

  const showUpsell = (anchor, props) => {
    setOnBasketChange(props.onBasketChange);
    // eslint-disable-next-line react/no-deprecated
    ReactDOM.unmountComponentAtNode(anchor);
    // eslint-disable-next-line react/no-deprecated
    ReactDOM.render(<UpsellApp {...props} />, anchor);
  };

  const UpsellApp = (props) => {
    const { onError = () => {} } = props;
    try {
      const { theme = {}, editMode = false, textOverrides = {} } = props;
      const {
        agentBrand = null,
        acceptedProducts = [],
        acceptedBags = {},
        segmentIndex = 0,
        onConfirmedSeats = null,
        onSeatmapLoaded = null,
        isOptIn,
        gordianUseLocalStorage = true,
        variants,
      } = props;

      window.gordianInternal.onConfirmedSeats = onConfirmedSeats;
      window.gordianInternal.onSeatmapLoaded = onSeatmapLoaded;
      window.gordianInternal.onError = onError;

      window._gordianUseLocalStorage = gordianUseLocalStorage;

      window.gordianStore.dispatch(setEditMode(editMode));
      window.gordianStore.dispatch(setAgentBrand(agentBrand));
      window.gordianStore.dispatch(
        setActiveWidget(
          props.activeWidget || (props.allowProducts || ['seatmap'])[0]
        )
      );

      if (Object.keys(acceptedBags).length > 0) {
        window.gordianStore.dispatch(
          setBaggageBasket({
            bags: acceptedBags,
          })
        );
      }
      if (acceptedProducts.length > 0) {
        window.gordianStore.dispatch(setAcceptedProducts(acceptedProducts));
      } else if (window._gordianUseLocalStorage) {
        const localStorageAcceptedProducts = JSON.parse(
          localStorage.getItem('gordianAcceptedProducts')
        );
        if (localStorageAcceptedProducts) {
          window.gordianStore.dispatch(
            setAcceptedProducts(
              localStorageAcceptedProducts.filter(
                (product) => product.session_id === window._gordianSessionId
              )
            )
          );
        }
      }

      let brandTheme = {};
      let { fontFamily = 'Roboto' } = props;
      if (agentBrand) {
        if (agentBrand in BrandThemes) {
          brandTheme = BrandThemes[agentBrand];
          if (brandTheme['fontFamily']) fontFamily = brandTheme['fontFamily'];
        }
        if (variants?.test_uber_theme?.toString()?.toLowerCase() === 'true') {
          brandTheme = BrandThemes['uber'];
          fontFamily = 'Uber Move';
        }
      }

      const currentTheme = extend(extend(base, brandTheme), theme);

      applyTheme(currentTheme);

      window.gordianStore.dispatch(setThemeInStore(currentTheme));

      window.gordianStore.dispatch(setTextOverrides(textOverrides));

      const { showOnLoad = true, modal = true } = props;
      const { locale = 'en-US' } = props;

      window.gordianStore.dispatch(setIsModal(modal));

      window.gordianStore.dispatch(setLocale(locale));

      window._gordianDebug = {};

      window._gordianDebug._setIsModal = (bool) =>
        window.gordianStore.dispatch(setIsModal(bool));
      window._gordianDebug._showModal = (bool) =>
        window.gordianStore.dispatch(showModal(bool));

      const speakContainer = (
        <div
          id={speakContainerId}
          style={{ height: 0, maxHeight: 0 }}
          tabIndex={-1}
        />
      );

      return (
        <Sentry.ErrorBoundary fallback={<div>An error has occured</div>}>
          <Provider store={window.gordianStore}>
            <LocalizeProvider store={window.gordianStore}>
              {modal ? (
                <ModalOverlay
                  shown={showOnLoad}
                  modal={modal}
                  isOptIn={isOptIn}
                >
                  <WidgetSelector
                    {...props}
                    fontFamily={fontFamily}
                    segmentIndex={segmentIndex}
                    modal={modal}
                  />
                </ModalOverlay>
              ) : (
                <WidgetSelector {...props} modal={modal} />
              )}
              {speakContainer}
            </LocalizeProvider>
          </Provider>
        </Sentry.ErrorBoundary>
      );
    } catch (error) {
      onError(error);
      return <div></div>;
    }
  };

  const toggleModal = (option) => {
    window.gordianStore.dispatch(showModal(option));
  };

  const noSeatsOnSearch = () => {
    window.gordianStore.dispatch(hasSeats(false));
  };

  const noBagsOnSearch = () => {
    window.gordianStore.dispatch(hasBags(false));
  };

  return {
    init,
    renderSeatmap,
    renderBaggageWidget,
    renderBaggageWidget2,
    showUpsell,
    toggleModal,
    seatMapTrigger,
    baggageWidgetTrigger,
    upsellTrigger,
    noSeatsOnSearch,
    noBagsOnSearch,
  };
})();
