export default function onTrackMiddleware(onTrack) {
  if (!onTrack) {
    throw new TypeError('You must provide a onTrack client instance.');
  }

  return (_store) => (next) => (action) => {
    if (!action.meta || !action.meta.track || !action.meta.track.event) {
      return next(action);
    }
    try {
      const { event, props, isMultipleEvents } = action.meta.track;
      if (isMultipleEvents) {
        // If multiple events sent as an array of props then send each individually
        props.forEach((prop) => {
          onTrack(event, prop);
        });
      } else {
        onTrack(event, props);
      }
    } catch (error) {}
    return next(action);
  };
}
