import React, { PureComponent } from 'react';

export class Kitchen extends PureComponent {
  render() {
    return (
      <svg
        viewBox="0 0 25 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className="gr-block gr-h-4 gr-w-4 sm:gr-h-5 sm:gr-w-5 md:gr-h-6 md:gr-w-6"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M19.7802 10.8787V11.5249C19.7802 12.8902 18.6729 13.9974 17.3077 13.9974H4.48596C3.1203 13.9974 2.01343 12.8902 2.01343 11.5249V10.1526V6.42857V0H19.7802C22.7839 0 24.7253 2.43544 24.7253 5.43956C24.7253 8.44368 22.7839 10.8787 19.7802 10.8787ZM19.7802 1.97802V6.42857V8.9011C21.6923 8.9011 22.7473 7.35124 22.7473 5.43915C22.7473 3.52747 21.6923 1.97802 19.7802 1.97802ZM2.47253 18H19.5418C20.907 18 22.0143 16.3643 22.0143 14.999H0C0 16.3643 1.10687 18 2.47253 18Z"
          fill="#A9BBC6"
        />
      </svg>
    );
  }
}
