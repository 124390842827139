import Footer from '../shared/Footer';
import Airline from './Airline';
import { FooterText } from './FooterText';
import NextFlight from './NextFlight';
import PaySummary from './PaySummary';
import SkipFlight from './SkipFlight';
import React from 'react';

const SeatmapFooter = ({
  displayTextInFooter,
  variants,
  relativeWidthRatio,
  test_seatmap_show_skip,
  test_seatmap_no_close,
  brand
}) => {
  let seatmapFooterClassname =
    'gr-flex gr-text-sm gr-w-full gr-p-4 sm:gr-p-0 sm:gr-text-base gr-justify-between gr-flex-row-reverse gr-gap-1 gr-items-center';
  return (
    <Footer>
      {brand !== 'uber' ? <Airline /> : null}
      {displayTextInFooter ? (
        <FooterText
          variants={variants}
          relativeWidthRatio={relativeWidthRatio}
        />
      ) : null}
      <div className={seatmapFooterClassname}>
        <NextFlight />
        {(test_seatmap_show_skip === true ||
          test_seatmap_no_close === true) && <SkipFlight />}
        <PaySummary />
      </div>
    </Footer>
  );
};

export default SeatmapFooter;
