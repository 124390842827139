import { TextOverrides } from '../redux/stateInterface';

function getSeatTriggerSubtitle(
  hasSeats: boolean,
  textOverrides: TextOverrides
) {
  if (!hasSeats) return 'seat_load_failed';
  if (textOverrides && textOverrides.card_seat_trigger_subtitle_available)
    return textOverrides.card_seat_trigger_subtitle_available;

  return 'seat_load_success';
}

export { getSeatTriggerSubtitle };
