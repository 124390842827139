const priceline = {
  hideZeroPrices: true,
  textPrimary: '#120C80',
  textSecondary: '#4D4B6D',
  buttonText: '#FFFFFF',
  title: '#120C80',
  gray_10: '#EEF4F9',
  gray_15: '#DBE4EA',
  gray_20: '#cbd5e0',
  gray_50: '#BBCBD5',
  gray_70: '#718096',
  gray_100: '#4a5568',
  primary: 'rgb(0, 170, 0)',
  secondary: '#4D4B6D',
  buttonBorderRadius: '4px',
  margin: '1.6px',
  alert: '#E06168',
  seats: {
    // These represent the colors of the seats depending on the category
    0: {
      fill: '#F1EDED', // the seat fill color
      stopColor: '#F1EDED', // the gradient stop color for the seat fill
      labelColor: '#856450', // the label color
      borderColor: '#856450'
    },
    1: {
      //
      fill: '#EDE5F5',
      stopColor: '#EDE5F5',
      labelColor: '#5E239F',
      borderColor: '#5E239F'
    },
    2: {
      fill: '#E9F1FB', // Preferred
      stopColor: '#E9F1FB',
      labelColor: '#3D86DD',
      borderColor: '#3D86DD'
    },
    3: {
      // Standard Seat
      fill: '#F2FAE9',
      stopColor: '#F2FAE9',
      labelColor: '#8AD443',
      borderColor: '#8AD443'
    },
    4: {
      fill: '#FED8C4',
      stopColor: '#FED8C4',
      labelColor: '#FF690F',
      borderColor: '#FF690F'
    },
    5: {
      fill: '#ddebea',
      stopColor: '#ddebea',
      labelColor: '#4d8784',
      borderColor: '#4d8784'
    },
    6: {
      fill: '#b8b1f2',
      stopColor: '#b8b1f2',
      labelColor: '#705bf1',
      borderColor: '#705bf1'
    },
    not_available: {
      fill: '#D9D9D9',
      stopColor: '#D9D9D9',
      labelColor: '#29425b',
      borderColor: '#A2A5AD'
    },
    selected_seat: {
      fill: '#007aff',
      stopColor: '#007aff',
      borderColor: '#007aff',
      labelColor: '#ffffff'
    }
  }
};

export default priceline;
