import React from 'react';
import Check from '../../svg/icon-check.svg';

export default function ListItemWithCheck(props) {
  return (
    <li
      style={{
        backgroundImage: `url(${Check})`,
        listStyleType: 'none',
        backgroundRepeat: 'no-repeat',
        backgroundPositionX: 'left',
        backgroundPositionY: '50%',
        paddingLeft: '24px'
      }}
      className="gr-text-lg gr-text-left gr-m-1"
      {...props}
    >
      {props.children}
    </li>
  );
}
