function validateBasket(
  basket = {},
  passengers = [],
  segmentColumns = {},
  checkInfants = true,
  checkLargeGroup = false,
  restrictionMap = {},
  currentSeatmap = '',
  largeGroupSize = 4
) {
  if (checkInfants && !validateInfants(basket, passengers, segmentColumns)) {
    return false;
  }

  if (
    checkLargeGroup &&
    !validateLargeGroup(
      basket,
      passengers,
      restrictionMap,
      currentSeatmap,
      largeGroupSize
    )
  ) {
    return false;
  }
  // passes all validations
  return true;
}

function getSegmentsWithSeats(seats) {
  const segmentsWithSeats = new Set();
  seats.forEach(seat => {
    segmentsWithSeats.add(seat.segment_id);
  });
  return segmentsWithSeats;
}

function validateInfants(basket = {}, passengers = [], segmentColumns = {}) {
  const { seats = [] } = basket;
  const noInfants =
    passengers.filter(passenger => passenger.type === 'infant').length === 0;

  if (noInfants) {
    return true;
  }

  const numberOfSeats = seats.length;
  if (numberOfSeats === 0) {
    return true;
  }
  const adultSeats = seats.filter(seat => seat.passenger_type === 'adult');
  const infantSeats = seats.filter(seat => seat.passenger_type === 'infant');

  const segmentsWithSeats = getSegmentsWithSeats(seats);

  let valid = true;
  segmentsWithSeats.forEach(segment => {
    const infants = infantSeats.filter(seat => seat.segment_id === segment);
    const adults = adultSeats.filter(seat => seat.segment_id === segment);
    if (adults.length > 0 && infants.length === 0) {
      valid = false;
    }

    infants.forEach(infant => {
      const infantRow = infant.row;
      const columns = segmentColumns[segment];
      const infantColumn = infant.columns[0];
      const infantColumnsInRow = columns.filter(column =>
        column.includes(infantColumn)
      )[0];
      if (infantColumnsInRow.length <= 1) {
        valid = false;
      }
      const indexOfColumn = infantColumnsInRow.indexOf(infantColumn);
      let validColumnsForAdultToBeIn;
      if (indexOfColumn === 0) {
        // If infant is in the first column, then the adult must be in the second column
        validColumnsForAdultToBeIn = [infantColumnsInRow[1]];
      } else if (indexOfColumn === infantColumnsInRow.length - 1) {
        // If infant is in the last column, then the adult must be in the second to last column.
        validColumnsForAdultToBeIn = [
          infantColumnsInRow[infantColumnsInRow.length - 2]
        ];
      } else {
        // Otherwise, adult must either be left or right of the infant.
        validColumnsForAdultToBeIn = [
          infantColumnsInRow[indexOfColumn - 1],
          infantColumnsInRow[indexOfColumn + 1]
        ];
      }

      const adultsInRow = adults.filter(adult => adult.row === infantRow);
      const adultColumnsInRow = adultsInRow.map(adult => adult.columns[0]);
      if (
        !adultColumnsInRow.some(r => validColumnsForAdultToBeIn.includes(r))
      ) {
        valid = false;
      }
    });
  });
  return valid;
}

function validateLargeGroup(
  basket = {},
  passengers = [],
  restrictionMap,
  currentSeatmap,
  largeGroupSize = 4
) {
  const { seats = [] } = basket;
  const numberOfSeats = seats.length;
  const numberOfPassengers = passengers.length;

  let currentRestriction = {};
  if (
    Object.keys(restrictionMap).length !== 0 &&
    currentSeatmap in restrictionMap
  ) {
    currentRestriction = restrictionMap[currentSeatmap];
  }

  if (
    !currentRestriction.all_or_none ||
    numberOfSeats === 0 ||
    numberOfPassengers < largeGroupSize
  ) {
    return true;
  }

  const segmentsWithSeats = getSegmentsWithSeats(seats);
  let valid = true;
  segmentsWithSeats.forEach(segment => {
    const seatsInSegment = seats.filter(seat => seat.segment_id === segment);
    if (seatsInSegment.length !== numberOfPassengers) {
      valid = false;
    }
  });

  return valid;
}

module.exports = { validateBasket, validateInfants, validateLargeGroup };
