import React, { Component } from 'react';
import { connect } from 'react-redux';

import { Translate } from 'react-localize-redux';

import { ReactComponent as InfantIcon } from '../../svg/infant.svg';
import { ReactComponent as HopperInfantIcon } from '../../svg/hopper-infant.svg';
import { GenericDetails } from './templates/GenericDetails';

export class InfantDetails extends Component {
  render() {
    const {
      selectedSeat,
      passengers = {},
      parentHeight,
      position,
      variants
    } = this.props;
    const { currentPassenger = {} } = passengers;
    const passengerIsInfant = currentPassenger.type === 'infant';
    const passengerHasInfantOnLap = currentPassenger.infant_on_lap;
    const { test_hopper_loader = false, test_uber_theme = false } = variants;

    const infantIcon =
      test_hopper_loader && !test_uber_theme ? (
        <HopperInfantIcon id="hopper-infant-bunny" />
      ) : (
        <InfantIcon />
      );

    const infantPrimary = (
      <>
        {passengerIsInfant &&
          currentPassenger.first_names + ' ' + currentPassenger.surname}
        {passengerHasInfantOnLap && <Translate id="infant_on_lap_header" />}
      </>
    );

    const infantSecondary = (
      <>
        {passengerIsInfant && <Translate id="infants_next_to_adults" />}
        {passengerHasInfantOnLap && <Translate id="infant_on_lap_content" />}
      </>
    );

    if ((passengerIsInfant || passengerHasInfantOnLap) && !selectedSeat) {
      return (
        <GenericDetails
          parentHeight={parentHeight}
          position={position}
          primary={infantPrimary}
          secondary={infantSecondary}
          popupIcon={infantIcon}
          idPrefix="infant"
          mobile={true}
        />
      );
    }
    return null;
  }
}

const mapStateToProps = state => ({
  parentHeight: state.session.parentHeight,
  passengers: state.passengers,
  selectedSeat: state.selectedSeat,
  variants: state.session.variants
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(InfantDetails);
