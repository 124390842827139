import React, { PureComponent } from 'react';

export default class MissingSeatIcon extends PureComponent {
  render() {
    return (
      <svg
        viewBox="0 0 50 50"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className="gr-absolute gr-top-0 gr-w-full gr-h-full"
      >
        <rect width="50" height="50" rx="3" fill="white" fillOpacity="0.01" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M5 7C5 7 8.33025 1 24.5993 1C40.8683 1 44 7 44 7V41H5V7Z"
          fill="url(#not-availabile)"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M5 7C5 7 8.33025 1 24.5993 1C40.8683 1 44 7 44 7V41H5V7Z"
          fill="white"
          fillOpacity="0.3"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M5 19C7.20914 19 9 20.7909 9 23V34C9 35.6569 10.3431 37 12 37H37C38.6569 37 40 35.6569 40 34V23C40 20.7909 41.7909 19 44 19C46.2091 19 48 20.7909 48 23V34C48 40.0751 43.0751 45 37 45H12C5.92487 45 1 40.0751 1 34V23C1 20.7909 2.79086 19 5 19Z"
          fill="#E0EBF1"
        />
        <rect x="13" y="34" width="23" height="14" rx="6" fill="#E0EBF1" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M43.9697 43.0303L6.96967 6.03033L8.03033 4.96967L45.0303 41.9697L43.9697 43.0303Z"
          fill="#B0CDDE"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M3.96967 41.9697L40.9697 4.96967L42.0303 6.03033L5.03033 43.0303L3.96967 41.9697Z"
          fill="#B0CDDE"
        />
        <defs>
          <linearGradient
            id="not-availabile"
            x1="5"
            y1="1"
            x2="5"
            y2="41"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#D4E3EC" />
            <stop offset="1" stopColor="#BFD4E1" />
          </linearGradient>
        </defs>
      </svg>
    );
  }
}
