const allegris = {
  isAllegris: true,
  seats: {
    1: {
      fill: '#2E4293',
      stopColor: '#DDE4FF',
      strokeColor: '#3F61E7'
    },
    2: {
      fill: '#6483FF',
      stopColor: '#DDE4FF',
      strokeColor: '#3F61E7'
    },
    3: {
      fill: '#070707',
      stopColor: '#E7E7E7',
      strokeColor: '#E7E7E7'
    },
    4: {
      fill: '#7E5DDE',
      stopColor: '#E2DDFF',
      strokeColor: '#B9A0FF'
    },
    5: {
      fill: '#FFFFFF',
      stopColor: '#FFFFFF',
      strokeColor: '#000000',
      borderColor: '#000000'
    },
    not_available: {
      fill: '#E7E7E7',
      stopColor: '#F7F7F7',
      strokeColor: '#CFCFCF'
    },
    selected_seat: {
      fill: '#F1BC41',
      stopColor: '#FFF5D0',
      strokeColor: '#FFF5D0'
    }
  }
};

export default allegris;
