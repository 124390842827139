import React, { Component } from 'react';
import { connect } from 'react-redux';
import DefaultLoader from './DefaultLoader';
import HopperLoader from './HopperLoader';

class ThemedLoader extends Component {
  render() {
    const { basketChanging, booleanizedVariants = {}, modal } = this.props;
    const {
      test_loader = false,
      test_hopper_loader = false,
      test_uber_theme = false
    } = booleanizedVariants;
    let active = basketChanging || test_loader;
    // Pre-load the bunny for the Hopper variant
    if (test_hopper_loader === true && test_uber_theme === false)
      return <HopperLoader active={active} />;
    if (!active) return null;
    return <DefaultLoader modal={modal} />;
  }
}

const mapStateToProps = state => ({
  booleanizedVariants: state.session.variants,
  basketChanging: state.session.basketChanging
});

export default connect(mapStateToProps)(ThemedLoader);
