import React from 'react';

function RestrictedRecline() {
  return (
    <div>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        height="16"
        viewBox="0 96 960 960"
        width="18"
      >
        <path d="M620 885H300q-24.75 0-42.375-17.625T240 825V364h60v461h320v60ZM438 338q-34 0-57.5-23.5T357 257q0-34 23.5-57.5T438 176q34 0 57.5 23.5T519 257q0 34-23.5 57.5T438 338Zm222 638V785H415q-30.938 0-52.969-21.737Q340 741.525 340 711V483q0-40.74 28.387-68.87 28.388-28.13 69.5-28.13Q479 386 507.5 414.13T536 483v202h109q30.938 0 52.969 21.737Q720 728.475 720 759v217h-60Z" />
      </svg>
    </div>
  );
}

export default RestrictedRecline;
