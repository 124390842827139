import React, { PureComponent } from 'react';

export default class SeatIcon extends PureComponent {
  render() {
    return (
      <svg
        viewBox="0 0 50 50"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className="gr-absolute gr-top-0 gr-w-full gr-h-full"
        style={{ zIndex: '-1' }}
      >
        <rect width="50" height="50" rx="3" fill="white" fillOpacity="0.01" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M5 7C5 7 8.33025 1 24.5993 1C40.8683 1 44 7 44 7V41H5V7Z"
          style={{ fill: `url(#paint${this.props.id}_linear)` }}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M5 19C7.20914 19 9 20.7909 9 23V34C9 35.6569 10.3431 37 12 37H37C38.6569 37 40 35.6569 40 34V23C40 20.7909 41.7909 19 44 19C46.2091 19 48 20.7909 48 23V34C48 40.0751 43.0751 45 37 45H12C5.92487 45 1 40.0751 1 34V23C1 20.7909 2.79086 19 5 19Z"
          fill={this.props.fill}
        />
        <rect
          x="13"
          y="34"
          width="23"
          height="14"
          rx="6"
          fill={this.props.fill}
        />
        <defs>
          <linearGradient
            id={`paint${this.props.id}_linear`}
            x1="5.00515"
            y1="1.01056"
            x2="5.00515"
            y2="41"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.000263936" stopColor={this.props.fill} />
            <stop offset="1" stopColor={this.props.stopColor} />
          </linearGradient>
        </defs>
      </svg>
    );
  }
}
