import React from 'react';

export function ScrollDot({ active }: { active?: boolean }) {
  return active ? (
    <svg
      width="8"
      height="8"
      viewBox="0 0 8 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="4" cy="4" r="4" fill="#1A1A1A" />
    </svg>
  ) : (
    <svg
      width="8"
      height="8"
      viewBox="0 0 8 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="4" cy="4" r="4" fill="#E1E1E1" />
    </svg>
  );
}
