import Footer from '../../shared/Footer';
import Airline from '../Airline';
import NextFlight from './NextFlight';
import PaySummary from './PaySummary';
import React from 'react';

const SeatmapFooter = ({ brand }) => {
  let seatmapFooterClassname =
    'gr-flex gr-text-sm gr-w-full gr-p-4 sm:gr-p-0 sm:gr-text-base gr-justify-between gr-flex-row-reverse gr-gap-1 gr-items-center';
  return (
    <Footer>
      {brand !== 'uber' ? <Airline /> : null}
      <div className={seatmapFooterClassname}>
        <NextFlight />
        <PaySummary />
      </div>
    </Footer>
  );
};

export default SeatmapFooter;
