const calculateConvenienceFeeVariant = (variant) => {
  const validVariants = ['variant', 'full', 'default'];
  const formattedVariant = variant?.toString().toLowerCase();
  if (!validVariants.includes(formattedVariant)) {
    return 'default';
  }
  return formattedVariant;
};

module.exports = {
  calculateConvenienceFeeVariant,
};
