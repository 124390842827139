import React from 'react';
import { ReactComponent as Close } from '../../svg/close.svg';
import { ReactComponent as BaggageExitModalIcon } from '../../svg/baggage-exit-modal-icon.svg';
import { connect } from 'react-redux';
import { Translate } from 'react-localize-redux';

export function FlightTransitionModal({
  setShowFlightTransitionModal,
  onNextClick,
  theme,
}: {
  setShowFlightTransitionModal: (state: boolean) => void;
  onNextClick: () => void;
  theme: any;
}) {
  const closeModal = () => {
    setShowFlightTransitionModal(false);
  };

  const continueToNextFlight = () => {
    onNextClick();
    closeModal();
  };

  return (
    <div
      className={
        'gr-absolute gr-w-11/12 gr-max-w-lg gr-bg-white gr-rounded-lg gr-shadow-lg gr-z-100'
      }
      id="flight-transition-modal"
      style={{ bottom: 100, left: '50%', transform: 'translate(-50%, 0%)' }}
    >
      <div className="gr-absolute" style={{ right: '16px', top: '16px' }}>
        <button
          className="gr-bg-transparent gr-border-0"
          onClick={closeModal}
          style={{ cursor: 'pointer', fontFamily: 'inherit' }}
        >
          <Close />
        </button>
      </div>
      <div className="gr-flex-col gr-items-center gr-justify-between gr-p-3 gr-py-4">
        <div
          className="gr-inline-grid gr-items-center gr-w-full gr-mb-8 gr-mt-2"
          style={{ gridTemplateColumns: '1fr 3fr 1fr' }}
        >
          <BaggageExitModalIcon className="gr-justify-self-center" />
          <span className="gr-font-semibold gr-py-2 gr-text-center">
            <Translate id={'selection_not_completed'} />
          </span>
        </div>
        <div className="gr-flex gr-justify-center gr-mb-2">
          <button
            className={`gr-cursor-pointer gr-font-medium gr-text-center gr-h-12 gr-w-10/12 gr-text-base gr-border-0`}
            onClick={closeModal}
            style={{
              background: theme?.bags?.primaryButtonColor ?? '#4CA7DE',
              color: theme?.bags?.primaryButtonText ?? '#FFFFFF',
              borderRadius: theme?.bags?.primaryButtonRounding ?? '8px',
              fontFamily: 'inherit',
            }}
          >
            <Translate id={'continue_selecting'}>Continue selecting</Translate>
          </button>
        </div>
        <div className="gr-flex gr-justify-center">
          <button
            className={`gr-cursor-pointer gr-font-medium gr-text-center gr-h-12 gr-w-10/12 gr-text-base gr-border-0`}
            onClick={continueToNextFlight}
            style={{
              background: theme?.bags?.secondaryButtonColor ?? '#8d8d8d',
              color: theme?.bags?.secondaryButtonText ?? '#FFFFFF',
              borderRadius: theme?.bags?.secondaryButtonRounding ?? '8px',
              fontFamily: 'inherit',
            }}
          >
            <Translate id={'no_thanks'}>No, thanks</Translate>
          </button>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state: any) => ({
  theme: state.session.theme,
});

export default connect(mapStateToProps)(FlightTransitionModal);
