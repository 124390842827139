import React, { PureComponent } from 'react';
import { IGenericDetailsProps } from './GenericDetails';
import GenericDetailsContainer from './GenericDetailsContainer';

export interface IGenericValidationPopupProps extends IGenericDetailsProps {
  button: React.ReactNode;
  id: string;
}

class GenericValidationPopup extends PureComponent<
  IGenericValidationPopupProps
> {
  render() {
    const {
      popupIcon,
      id,
      primary,
      secondary,
      mobile,
      parentHeight,
      button
    } = this.props;

    return (
      <GenericDetailsContainer
        mobile={mobile}
        parentHeight={parentHeight}
        height={350} //magic number
        idPrefix="seat-validation"
        {...this.props.position}
      >
        <div
          id={id}
          className="seat-detail gr-flex-col gr-items-center gr-justify-between gr-p-3 gr-py-4"
        >
          <div className="gr-flex gr-flex-row gr-items-center gr-mr-4">
            <div className="gr-flex gr-justify-center gr-w-1/5">
              {popupIcon}
            </div>

            <div className="gr-flex gr-flex-row gr-justify-start gr-w-4/5">
              <div className="gr-flex-col gr-pr-2">
                <span className="gr-flex gr-text-title gr-font-semibold gr-py-2 gr-flex-shrink-0">
                  {primary}
                </span>
                <span className="gr-flex gr-text-secondary-text gr-flex-shrink-0">
                  {secondary}
                </span>
              </div>
            </div>
          </div>
          <div className="gr-flex gr-px-8 gr-py-2 gr-pt-6">{button}</div>
        </div>
      </GenericDetailsContainer>
    );
  }
}

export default GenericValidationPopup;
