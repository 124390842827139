import React, { Component } from 'react';
import { connect } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';

import Columns from './Columns';
import Bulkhead from '../shared/Bulkhead';
import RowGroup from './RowGroup';
import Aisle from '../shared/Aisle';

function CabinHeader({ columns, aisleMarginX }) {
  return (
    <div className="gr-px-1 sm:gr-px-4 gr-py-1 sm:gr-py-2 sm:gr-border-l sm:gr-border-r gr-border-gray-300">
      <Columns columns={columns} aisleMarginX={aisleMarginX}></Columns>
    </div>
  );
}

function RowContainer({ row, children, overWing }) {
  return (
    <div
      className={`row-${row} gr-flex gr-justify-between gr-items-center gr-px-4 sm:gr-border-l sm:gr-border-r gr-border-gray-${
        overWing ? '450' : '300'
      }`}
    >
      {children}
    </div>
  );
}

function CategoryRow({ category, row, children }) {
  return (
    <div
      className={`gr-relative gr-w-full gr-px-1 gr-justify-between gr-border-l-2 gr-border-r-2 ${
        row === category.start_row && row === category.end_row
          ? 'gr-border-2  gr-mb-6 sm:gr-mb-8 gr-rounded-lg '
          : row === category.start_row
            ? 'gr-rounded-tl-lg gr-rounded-tr-lg gr-border-t-2'
            : row === category.end_row
              ? 'gr-rounded-bl-lg gr-rounded-br-lg gr-border-b-2 gr-mb-2 sm:gr-mb-4'
              : ''
      } gr-flex`}
      style={{ borderColor: category.color }}
    >
      {row === category.start_row ? (
        <div
          style={{ top: '-15px', height: '18px', width: 'max-content' }}
          className="gr-text-xxs gr-flex gr-justify-between md:gr-text-sm gr-bg-white gr-mx-auto gr-absolute gr-left-0 gr-right-0 gr-bottom-0 gr-text-center gr-items-center"
        >
          <div
            className="gr-h-4 gr-w-4 gr-mx-2 gr-rounded"
            style={{ backgroundColor: category.color }}
          ></div>
          <p className="gr-text-gray-title">
            {`${category.name} — ${category.short_description}`}
          </p>
        </div>
      ) : null}
      {children}
    </div>
  );
}

export class Row extends Component {
  render() {
    const {
      overWing = false,
      bulkheadFront = false,
      category = false,
      columns = [],
      index,
      last = false,
      useEnhancedDetails,
    } = this.props;

    const { row_groups = [], row = 0 } = this.props.seat_row;
    const groups = row_groups.map((row_group = [], key) => {
      return (
        <RowGroup
          category={category}
          key={key}
          index={key}
          seats={row_group}
          row={row}
          length={row_groups.length}
        />
      );
    });

    const extraAisleSpace = useEnhancedDetails;
    const aisleMarginX = extraAisleSpace ? 'gr-mx-2' : '';
    const groupsWithAisles = groups.reduce((prev, current, index, arr) => {
      prev.push(current);
      if (index + 1 < arr.length) {
        prev.push(
          <Aisle
            key={uuidv4()}
            row={current.props.row}
            first={this.props.index === 0}
            last={last}
            useEnhancedDetails={useEnhancedDetails}
          />,
        );
      }
      return prev;
    }, []);

    return (
      <div>
        {index === 0 ? (
          <CabinHeader columns={columns} aisleMarginX={aisleMarginX} />
        ) : null}
        {index === 0 && bulkheadFront ? (
          <Bulkhead overWing={overWing} columns={this.props.columns} />
        ) : null}

        <RowContainer row={row} overWing={overWing}>
          {category ? (
            <CategoryRow category={category} row={row}>
              {groupsWithAisles}
            </CategoryRow>
          ) : (
            groupsWithAisles
          )}
        </RowContainer>
        {last ? (
          <CabinHeader columns={columns} aisleMarginX={aisleMarginX} />
        ) : null}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  useEnhancedDetails:
    state.itinerary.seatmapsBySegment[state.itinerary.currentSeatmap]
      ?.useEnhancedDetails,
});

export default connect(mapStateToProps)(Row);
