import React from 'react';
import { Translate } from 'react-localize-redux';
import PropTypes from 'prop-types';

export default function Sectionheader({
  isInbound,
  onCopy,
  copyValue,
  setCopy,
}) {
  return (
    <div
      id={`section-header-${isInbound ? 'inbound' : 'outbound'}`}
      className="gr-flex gr-w-full gr-h-14 sm:gr-h-20 gr-mb-8"
      style={{ backgroundColor: '#f2f2f2' }}
    >
      <div className="gr-flex gr-px-12 gr-items-center">
        <div className="gr-flex gr-items-center">
          <div className="gr-text-xl gr-font-bold gr-text-black">
            {isInbound ? (
              <Translate id="common.inbound_flight" />
            ) : (
              <Translate id="common.outbound_flight" />
            )}
          </div>

          {isInbound && onCopy && (
            <div className="gr-mx-8 gr-font-semibold">
              <input
                type="checkbox"
                id="copy-outbound"
                name="copy-outbound"
                checked={copyValue}
                onChange={(e) => {
                  setCopy(e.target.checked);
                  if (e.target.checked) {
                    onCopy();
                  }
                }}
              />{' '}
              Copy from Outbound Flight
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

Sectionheader.propTypes = {
  isInbound: PropTypes.bool,
};
