import React, { PureComponent } from 'react';

export class Bassinet extends PureComponent {
  render() {
    return (
      <svg
        width="26"
        height="16"
        viewBox="0 0 26 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M15.8566 13.4852C15.9487 13.2785 16.0019 13.0356 16.0019 12.7628C16.0019 10.5991 16.0019 8.43341 16.0005 6.26634C16.0005 4.0747 13.0061 4.0747 13.0061 6.26634C13.0061 7.22334 13.0061 8.05277 13 9.01046H7.79981C7.96041 8.76837 8.12101 8.55823 8.2817 8.34797C8.44382 8.13584 8.60603 7.9236 8.76841 7.67831C9.26908 6.92935 9.29637 6.23837 8.98669 5.64152C8.82912 5.33798 8.44509 5.0208 8.06174 4.80252C7.14176 4.26927 6.2216 3.64599 5.30145 3.02272C4.37955 2.39826 3.45766 1.77381 2.53593 1.23989C0.638291 0.140322 -1.06904 3.07273 0.824508 4.16684C1.44276 4.5265 2.06237 4.97575 2.68215 5.42514C3.30484 5.87663 3.9277 6.32825 4.54953 6.68925C3.79989 7.82292 3.04888 8.95455 2.29583 10.0855C2.29096 10.0931 2.27923 10.1088 2.26189 10.1321C2.08496 10.3693 1.3245 11.3889 1.3245 12.537C1.3245 14.3883 2.86812 15.9963 4.71665 15.9963H12.6022C14.1254 15.9963 15.4378 14.8849 15.8566 13.4852ZM17.0219 11.9953C17.0219 14.1057 18.8977 15.9892 21.0115 15.9892C23.1261 15.9892 25.0012 14.1064 25.0012 11.9953C25.0012 9.88071 23.1281 8.00136 21.0115 8.00136C18.899 8.00136 17.0219 9.87934 17.0219 11.9953Z"
          fill="#A9BBC6"
        />
      </svg>
    );
  }
}
