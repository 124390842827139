import React from 'react';
import Select from '../shared/Select';
import SelectArrow from '../shared/SelectArrow';
import { Translate } from 'react-localize-redux';
import MediaQuery from 'react-responsive';
import { isFirefox } from 'react-device-detect';

export default function PassengerSelect(props) {
  const {
    current = {},
    currentSeat = {},
    brand = undefined,
    locale,
    length
  } = props;
  const { index = 0 } = current;
  const { seat = '' } = currentSeat;
  const PASSENGER_NAME_CONDENSE_BARRIER = 880;

  const formatter = new Intl.NumberFormat(locale);
  const outOfText = `${formatter.format(index + 1)} / ${formatter.format(
    length
  )}`;

  return (
    <MediaQuery
      minWidth={PASSENGER_NAME_CONDENSE_BARRIER / props.relativeWidthRatio}
    >
      {matches => {
        return matches ? (
          <Select {...props} selectingPassenger={true}>
            {props.children}
          </Select>
        ) : brand === 'uber' ? (
          UberPassengerSelect(props, seat, outOfText)
        ) : (
          GenericPassengerSelect(props, seat, outOfText)
        );
      }}
    </MediaQuery>
  );
}

function UberPassengerSelect(props, seat, outOfText) {
  return (
    <div
      className="gr-rounded-lg gr-bg-white gr-w-3/6 gr-h-full gr-relative gr-border gr-text-base"
      id={props.id}
    >
      <select
        onChange={event => props.handleChange(event.target.value)}
        className="gr-block gr-appearance-none gr-w-full gr-h-full gr-bg-white gr-font-normal gr-rounded-lg gr-pl-3 gr-pb-4"
        value={props.value}
      >
        {props.children}
      </select>
      <div
        className={`gr-pointer-events-none gr-text-secondary-text gr-text-sm gr-absolute gr-inset-y-0 gr-pl-3 gr-pb-3 gr-mt-10 ${
          isFirefox ? 'gr-ml-1' : ''
        }`}
      >
        {seat ? seat : props.value !== null ? <Translate id="selecting" /> : ''}
      </div>
      <div className="gr-pointer-events-none gr-absolute gr-inset-y-0 gr-right-0 gr-flex gr-items-center gr-px-2">
        <div className="gr-hidden sm:gr-block gr-text-xs  gr-text-secondary-text gr-mr-1 sm:gr-mr-2">
          {outOfText}
        </div>
        <SelectArrow />
      </div>
    </div>
  );
}

function GenericPassengerSelect(props, seat, outOfText) {
  return (
    <div
      className="gordian-select gr-flex-col gr-text-title gr-bg-white gr-w-3/6 gr-h-full gr-relative gr-border gr-text-sm"
      id={props.id}
    >
      <select
        onChange={event => props.handleChange(event.target.value)}
        className="gr-block gr-appearance-none gr-w-full gr-h-full gr-bg-white gr-font-normal gr-rounded-none gr-pl-6 gr-pb-4"
        value={props.value}
      >
        {props.children}
      </select>
      <div
        className={`gr-pointer-events-none gr-text-secondary-text gr-text-sm gr-absolute gr-inset-y-0 gr-pl-6 gr-py-1 gr-mt-10 ${
          isFirefox ? 'gr-ml-1' : ''
        }`}
      >
        {seat ? seat : props.value !== null ? <Translate id="selecting" /> : ''}
      </div>
      <div className="gr-pointer-events-none gr-absolute gr-inset-y-0 gr-right-0 gr-flex gr-items-center gr-px-2">
        <div className="gr-hidden sm:gr-block gr-text-xs  gr-text-secondary-text gr-mr-1 sm:gr-mr-2">
          {outOfText}
        </div>
        <SelectArrow />
      </div>
    </div>
  );
}
