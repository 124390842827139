import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { DesktopBagPresentation } from './DesktopBagPresentation';
import { Bag } from './CommonTypes';
import { ScrollDot } from './ScrollDot';

export function DesktopBagSummaries({
  bagsToDisplay,
}: {
  bagsToDisplay: Array<Bag>;
}) {
  const scrollContainerRef = useRef<HTMLDivElement>(null);
  const bagsContainerRef = useRef<HTMLDivElement>(null);
  const modalShown = useSelector((state: any) => state.session.modalShown);

  const [isScrolling, setIsScrolling] = useState(false);
  const [scrollDirection, setScrollDirection] = useState('left');
  const [showLeftButton, setShowLeftButton] = useState(false);
  const [showRightButton, setShowRightButton] = useState(false);
  const [closestBagIndex, setClosestBagIndex] = useState<number | null>(null);

  const scrollContainer = (direction: string) => {
    const scrollContainer = scrollContainerRef.current;

    if (scrollContainer) {
      const scrollAmount = direction === 'left' ? -10 : 10;
      scrollContainer.scrollBy({
        left: scrollAmount,
        behavior: 'auto',
      });
    }
  };

  const handleMouseUp = () => {
    setIsScrolling(false);
  };

  useEffect(() => {
    document.addEventListener('mouseup', handleMouseUp);
    return () => {
      document.removeEventListener('mouseup', handleMouseUp);
    };
  }, []);

  useEffect(() => {
    let animationFrameId: number;

    const scroll = () => {
      if (isScrolling) {
        scrollContainer(scrollDirection);
        animationFrameId = requestAnimationFrame(scroll);
      }
    };

    if (isScrolling) {
      scroll();
    }

    return () => {
      cancelAnimationFrame(animationFrameId);
    };
  }, [isScrolling, scrollDirection]);

  useEffect(() => {
    const container = scrollContainerRef.current;

    const handleScrollPosition = () => {
      if (container) {
        setShowLeftButton(container.scrollLeft > 0);
        setShowRightButton(
          container.scrollLeft < container.scrollWidth - container.clientWidth,
        );

        // Calculate the index of the bag closest to the middle of the visible section
        const bagsContainer = bagsContainerRef.current;
        if (bagsContainer) {
          const bagsInSlider = Array.from(bagsContainer.children).filter((b) =>
            b.className.includes('bag-details'),
          );

          const visibleWidth = container.clientWidth;
          const middleScroll = container.scrollLeft + visibleWidth / 2;

          let closestIndex = 0;
          let closestDistance = Number.MAX_SAFE_INTEGER;

          bagsInSlider.forEach((bag, index) => {
            // Calculate the position relative to bagsContainer
            const rect = bag.getBoundingClientRect();
            const positionInContainer =
              rect.left - bagsContainer.getBoundingClientRect().left;

            const distance = Math.abs(
              positionInContainer + rect.width / 2 + index * 16 - middleScroll,
            );

            if (distance < closestDistance) {
              closestDistance = distance;
              closestIndex = index;
            }
          });

          setClosestBagIndex(closestIndex);
        }
      }
    };

    container?.addEventListener('scroll', handleScrollPosition);
    handleScrollPosition();

    return () => {
      container?.removeEventListener('scroll', handleScrollPosition);
    };
  }, [showLeftButton, showRightButton, modalShown]);

  return (
    <div
      id="desktop-bag-slider-wrapper"
      className="gr-relative gr-max-w-3xl gr-mx-auto"
      style={{ width: '95%' }}
    >
      <div className="gr-relative gr-flex">
        {showLeftButton ? (
          <>
            <button
              className="left-arrow gr-my-auto"
              style={{
                width: '16px',
                backgroundColor: 'transparent',
                border: 'none',
                cursor: 'pointer',
                outline: 'none',
                zIndex: 3,
                fontFamily: 'inherit',
              }}
              onMouseDown={() => {
                setIsScrolling(true);
                setScrollDirection('left');
              }}
            >
              <svg
                width="39"
                height="50"
                viewBox="-5 0 39 50"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clipPath="url(#clip0_630_1559)">
                  <path
                    d="M21.104 15.4375L11.5623 25L21.104 34.5625L18.1665 37.5L5.6665 25L18.1665 12.5L21.104 15.4375Z"
                    fill="black"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_630_1559">
                    <rect
                      width="50"
                      height="50"
                      fill="white"
                      transform="matrix(0 1 -1 0 39 0)"
                    />
                  </clipPath>
                </defs>
              </svg>
            </button>
            <div
              style={{ left: '16px', zIndex: 2 }}
              className="gr-w-1/3 gr-h-full gr-absolute gr-l-1/3 gr-flex gr-flex-col"
            >
              <div
                className="gr-fade-overlay gr-w-full gr-top-0 gr-left-0 gr-bottom-0 gr-absolute"
                style={{
                  background:
                    'linear-gradient(to left, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1))',
                }}
              ></div>
            </div>
          </>
        ) : (
          <span
            style={{
              paddingLeft: '16px',
            }}
          ></span>
        )}
        <div
          className="gr-relative gr-flex gr-mt-4 gr-overflow-x-hidden gr-overflow-y-hidden gr-mx-auto"
          ref={scrollContainerRef}
        >
          <div
            id={'bags-images-container'}
            ref={bagsContainerRef}
            className="gr-flex gr-items-center gr-gap-8 gr-py-1 gr-mx-auto gr-relative"
          >
            {bagsToDisplay
              .sort(
                (bag1: any, bag2: any) =>
                  bag1.product_details.weight - bag2.product_details.weight,
              )
              .sort((bag: any) =>
                bag.product_details.display_name.toLowerCase().includes('cabin')
                  ? -1
                  : 1,
              )
              .map((bag: any) => (
                <DesktopBagPresentation bag={bag} key={bag.product_id} />
              ))}
          </div>
        </div>
        {showRightButton ? (
          <>
            <div
              style={{ zIndex: 2, right: '16px' }}
              className="gr-w-1/3 gr-h-full gr-absolute gr-l-1/3 gr-flex gr-flex-col gr-top-0"
            >
              <div
                className="gr-fade-overlay gr-w-full gr-top-0 gr-left-0 gr-bottom-0 gr-absolute"
                style={{
                  background:
                    'linear-gradient(to right, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1))',
                }}
              ></div>
            </div>
            <button
              className="right-arrow gr-my-auto gr-relative"
              style={{
                width: '16px',
                right: 0,
                backgroundColor: 'transparent',
                border: 'none',
                cursor: 'pointer',
                outline: 'none',
                zIndex: 3,
                fontFamily: 'inherit',
              }}
              onMouseDown={() => {
                setIsScrolling(true);
                setScrollDirection('right');
              }}
            >
              <svg
                width="39"
                height="50"
                viewBox="5 0 39 50"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clipPath="url(#clip0_627_1555)">
                  <path
                    d="M17.896 34.5625L27.4377 25L17.896 15.4375L20.8335 12.5L33.3335 25L20.8335 37.5L17.896 34.5625Z"
                    fill="black"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_627_1555">
                    <rect
                      width="50"
                      height="50"
                      fill="white"
                      transform="matrix(0 -1 1 0 0 50)"
                    />
                  </clipPath>
                </defs>
              </svg>
            </button>
          </>
        ) : (
          <span
            style={{
              paddingLeft: '16px',
            }}
          ></span>
        )}
      </div>
      {(showRightButton || showLeftButton) && closestBagIndex !== null && (
        <div
          className="gr-relative gr-b-0 gr-mx-auto gr-flex gr-items-start gr-justify-center gr-gap-1 gr-mt-8"
          style={{ width: '10%' }}
        >
          {bagsToDisplay.map((bag: any, index) => (
            <ScrollDot
              active={index === closestBagIndex}
              key={bag.product_id}
            />
          ))}
        </div>
      )}
    </div>
  );
}
