import React from 'react';
import { connect } from 'react-redux';
import {
  clearBasket,
  showModal,
  setShowOnClosePopUp,
} from '../../redux/actions';
import { ReactComponent as Close } from '../../svg/close.svg';
import { notAllSegmentsHaveSeats } from '../../utils/onExitModalPopupValidation';

const closeButton = (props) => {
  const { isOptIn, showModal, clearBasket, disabled, variants } = props;
  const { test_seatmap_on_exit_modal = false } = variants;

  const enableOnClosePopup = false;
  return (
    <button
      id="modal-close-button"
      aria-label="Close and skip seat selection"
      className="gr-cursor-pointer gr-h-full gr-pl-3 gr-pr-3"
      tabIndex={0}
      disabled={disabled}
      onClick={(e) => {
        if (process.env.STORYBOOK_USED) {
          return;
        }

        e.preventDefault();
        if (test_seatmap_on_exit_modal) {
          const { basket, itinerary, passengers, setShowOnClosePopUp } = props;
          if (
            enableOnClosePopup &&
            notAllSegmentsHaveSeats(basket, itinerary, passengers)
          ) {
            setShowOnClosePopUp({
              show: true,
              trigger: 'ON_EXIT',
              opt_in: isOptIn,
            });
            return;
          }
        }
        if (isOptIn) {
          showModal(false);
        } else {
          clearBasket();
        }
      }}
      style={{ margin: 'auto 0', zIndex: 101 }}
    >
      <Close style={{ width: 14, height: 14 }} alt="close" />
    </button>
  );
};

const mapStateToProps = (state) => ({
  variants: state.session.variants,
  basket: state.basket,
  itinerary: state.itinerary,
  passengers: state.passengers.listOfPassenger,
});

const mapDispatchToProps = {
  showModal,
  clearBasket,
  setShowOnClosePopUp,
};

export default connect(mapStateToProps, mapDispatchToProps)(closeButton);
