import React from 'react';
import { connect } from 'react-redux';
import { Theme } from './CommonTypes';

function CabinBag({ theme }: { theme?: Theme }) {
  const bagColor = theme?.bags?.cabinBagColor ?? '#4CA7DE';
  const linearGradient1 = theme?.bags?.cabinBagLinearGradient1 ?? '#56BAF6';
  const linearGradient2 = theme?.bags?.cabinBagLinearGradient2 ?? '#69C6FF';
  const handleAndWheels = theme?.bags?.cabinBagHandleAndWheels ?? '#176491';

  return (
    <svg viewBox="0 0 60 98" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect
        x="10.3756"
        y="93.2252"
        width="4.56779"
        height="4.56779"
        rx="1.4534"
        fill={handleAndWheels}
        stroke="#2674A3"
        strokeWidth="0.415256"
      />
      <rect
        x="45.2584"
        y="93.225"
        width="4.56779"
        height="4.56779"
        rx="1.4534"
        fill={handleAndWheels}
        stroke="#2674A3"
        strokeWidth="0.415256"
      />
      <rect
        x="0.203125"
        y="8.30469"
        width="59.7966"
        height="86.3729"
        rx="7.47461"
        fill={bagColor}
      />
      <rect
        x="10.168"
        y="21.5938"
        width="4.98305"
        height="59.7966"
        rx="2.49153"
        fill="url(#paint0_linear_64_812)"
      />
      <rect
        x="21.7969"
        y="21.5938"
        width="4.98305"
        height="59.7966"
        rx="2.49153"
        fill="url(#paint1_linear_64_812)"
      />
      <rect
        x="33.4219"
        y="21.5938"
        width="4.98305"
        height="59.7966"
        rx="2.49153"
        fill="url(#paint2_linear_64_812)"
      />
      <rect
        x="45.0508"
        y="21.5938"
        width="4.98305"
        height="59.7966"
        rx="2.49153"
        fill="url(#paint3_linear_64_812)"
      />
      <path
        d="M17.6445 3.32203C17.6445 1.48733 19.1319 0 20.9666 0V8.30509H17.6445V3.32203Z"
        fill={handleAndWheels}
      />
      <path
        d="M40.0664 0C41.9011 0 43.3884 1.48733 43.3884 3.32203V8.30509H40.0664V0Z"
        fill={handleAndWheels}
      />
      <rect
        x="19.3048"
        width="22.4237"
        height="3.32203"
        rx="1.66102"
        fill={handleAndWheels}
      />
      <defs>
        <linearGradient
          id="paint0_linear_64_812"
          x1="12.6595"
          y1="21.5938"
          x2="12.6595"
          y2="81.3904"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={linearGradient1} />
          <stop offset="1" stopColor={linearGradient2} stopOpacity="0.11" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_64_812"
          x1="24.2884"
          y1="21.5938"
          x2="24.2884"
          y2="81.3904"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={linearGradient1} />
          <stop offset="1" stopColor={linearGradient2} stopOpacity="0.11" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_64_812"
          x1="35.9134"
          y1="21.5938"
          x2="35.9134"
          y2="81.3904"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={linearGradient1} />
          <stop offset="1" stopColor={linearGradient2} stopOpacity="0.11" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_64_812"
          x1="47.5423"
          y1="21.5938"
          x2="47.5423"
          y2="81.3904"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={linearGradient1} />
          <stop offset="1" stopColor={linearGradient2} stopOpacity="0.11" />
        </linearGradient>
      </defs>
    </svg>
  );
}

const mapStateToProps = (state: any) => ({
  theme: state.session.theme,
});

export default connect(mapStateToProps)(CabinBag);
