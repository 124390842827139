import React from 'react';

export function SubtractButton({
  onClick,
  disabled = false,
  mobile = false,
}: {
  onClick: () => void;
  disabled?: boolean;
  mobile?: boolean;
}) {
  return (
    <div
      onClick={!disabled ? onClick : () => {}}
      className={`${
        !disabled ? 'gr-cursor-pointer' : ''
      } gr-flex substract-btn gr-items-center`}
    >
      <svg
        width={mobile ? '16' : '20'}
        height={mobile ? '16' : '20'}
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle cx="8" cy="8" r="8" fill={disabled ? '#8D8D8D4D' : '#4CA7DE'} />
        <g clipPath="url(#clip0_0_1)">
          <path d="M5 7.5H11V8.5H5V7.5Z" fill="white" />
        </g>
        <defs>
          <clipPath id="clip0_0_1">
            <rect
              width="12"
              height="12"
              fill="white"
              transform="translate(2 2)"
            />
          </clipPath>
        </defs>
      </svg>
    </div>
  );
}
