import React, { PureComponent } from 'react';
import GenericDetailsContainer from './templates/GenericDetailsContainer';

class OnSeatClosePopup extends PureComponent {
  render() {
    const {
      popupIcon,
      id,
      primary,
      secondary,
      mobile,
      parentHeight,
      confirmButton,
      cancelButton,
      closeButton
    } = this.props;

    return (
      <GenericDetailsContainer
        mobile={mobile}
        parentHeight={parentHeight}
        height={150} //magic number
        idPrefix="seat-validation"
        {...this.props.position}
      >
        <div className="gr-float-right gr-m-4">{closeButton}</div>
        <div
          id={id}
          className="seat-detail gr-flex-col gr-items-center gr-justify-between gr-p-3 gr-py-4 gr-z-60"
        >
          <div className="gr-flex gr-flex-row gr-items-center gr-mr-4">
            <div className="gr-flex gr-justify-center gr-w-1/6 gr-mx-3">
              {popupIcon}
            </div>

            <div className="gr-flex gr-flex-row gr-justify-center gr-w-4/5">
              <div className="gr-flex-col gr-pr-2">
                <span className="gr-flex gr-text-title gr-font-semibold gr-py-2 gr-flex-shrink-0">
                  {primary}
                </span>
                <span className="gr-flex gr-text-secondary-text gr-flex-shrink-0">
                  {secondary}
                </span>
              </div>
            </div>
          </div>
          <div className="gr-flex gr-px-8 gr-pt-6">{cancelButton}</div>
          <div className="gr-flex gr-px-8 gr-py-2">{confirmButton}</div>
        </div>
      </GenericDetailsContainer>
    );
  }
}

export default OnSeatClosePopup;
