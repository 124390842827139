import React, { Fragment, useRef, useState } from 'react';
import ItinerarySelect from './ItinerarySelect';
import { connect } from 'react-redux';
import { setCurrentBaggageJourney } from '../../redux/actions';
import { Journey, Segment, Theme } from './CommonTypes';
import { Translate } from 'react-localize-redux';

function RouteArrow({ isMobile, theme }: { isMobile: boolean; theme?: Theme }) {
  return isMobile ? (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="gr-align-middle"
    >
      <g clipPath="url(#clip0_64_779)">
        <path
          d="M10.6746 7.33398H2.66797V8.66732H10.6746V10.6673L13.3346 8.00065L10.6746 5.33398V7.33398Z"
          fill={theme?.bags?.routeArrowMobile ?? 'black'}
        />
      </g>
      <defs>
        <clipPath id="clip0_64_779">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  ) : (
    <svg
      width="24"
      height="13"
      viewBox="0 0 24 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="gr-align-middle gr-pl-2 gr-pr-2"
    >
      <path
        d="M16.8263 12.525L23.2763 6.5L16.8263 0.475V4.975H0.726263V8.025H16.8263V12.525Z"
        fill={theme?.bags?.routeArrowDesktop ?? 'black'}
      />
    </svg>
  );
}

function FlightSelector({
  journeys,
  activeJourney,
  isMobile,
  setCurrentBaggageJourney,
  theme,
}: {
  journeys: Array<Journey>;
  activeJourney: Journey;
  isMobile: boolean;
  setCurrentBaggageJourney: any;
  theme: Theme;
}) {
  const [itinerarySelectOpen, setItinerarySelectOpen] = useState(false);
  const itinerarySelectRef = useRef<any>(null);
  const routeTextStyle = isMobile
    ? 'gr-bag-header-route-text gr-text-base gr-not-italic gr-leading-normal gr-font-bold'
    : 'gr-bag-header-route-text-desktop';

  const route: string[] = [];
  if (activeJourney.from === activeJourney.to) {
    route.push(
      ...(activeJourney?.flights
        ?.flatMap((flight: Segment, i: number) => [
          i === 0
            ? !isMobile && flight.fromCity
              ? `${flight.fromCity} (${flight.from})`
              : flight.from
            : '',
          !isMobile && flight.toCity
            ? `${flight.toCity} (${flight.to})`
            : flight.to,
        ])
        .filter((airport: string) => airport) ?? []),
    );
  } else {
    route.push(
      (!isMobile && activeJourney?.fromCity
        ? `${activeJourney?.fromCity} (${activeJourney?.from})`
        : activeJourney?.from) ?? '',
    );
    route.push(
      (!isMobile && activeJourney?.toCity
        ? `${activeJourney?.toCity} (${activeJourney?.to})`
        : activeJourney?.to) ?? '',
    );
  }

  const handleChange = (option: HTMLOptionElement) => {
    setCurrentBaggageJourney(
      journeys.find((j) => `${j.from}-${j.to}` === option.value) as Journey,
    );
  };

  const handleClick = () => {
    if (itinerarySelectRef.current) {
      if (itinerarySelectOpen) {
        itinerarySelectRef.current.blur();
      } else {
        itinerarySelectRef.current.focus();
      }
    }
  };

  return (
    <>
      <div
        className={`gr-flex gr-flex-col gr-pt-4 gr-pb-4 ${
          journeys.length > 1 ? 'gr-cursor-pointer' : ''
        } ${!isMobile ? 'gr-mx-8' : ''}`}
        id="bag-flight-header"
        onClick={handleClick}
      >
        <div
          className={`gr-flex gr-flex-row gr-items-center ${
            isMobile ? 'gr-mx-4' : ''
          }`}
        >
          <span className="gr-text-base gr-text-gray-900">
            <span
              className={`gr-align-middle ${routeTextStyle}`}
              style={{
                color: isMobile
                  ? theme?.bags?.routeTextMobile ?? '#585858'
                  : theme?.bags?.routeTextDesktop ?? '#022694',
              }}
            >
              {route.shift()}
            </span>
            {route.map((airport, i) => (
              <Fragment key={i}>
                <RouteArrow isMobile={isMobile} theme={theme} />
                <span
                  className={`gr-align-middle ${routeTextStyle}`}
                  style={{
                    color: isMobile
                      ? theme?.bags?.routeTextMobile ?? '#585858'
                      : theme?.bags?.routeTextDesktop ?? '#022694',
                  }}
                >
                  {airport}
                </span>
              </Fragment>
            ))}
          </span>
          <span className="gr-ml-auto">
            {activeJourney.from === activeJourney.to ? (
              <span
                className={`gr-text-xs gr-text-gray-500 ${
                  isMobile ? 'gr-mr-8' : ''
                }`}
              >
                <Translate id={'roundtrip'}>Roundtrip</Translate>
              </span>
            ) : journeys.length > 1 ? (
              <ItinerarySelect
                itinerarySelectRef={itinerarySelectRef}
                direction={activeJourney.direction}
                isMobile={isMobile}
                options={journeys.map((journey: Journey) => ({
                  value: `${journey.from}-${journey.to}`,
                  label: `${journey.from} → ${journey.to}`,
                }))}
                handleChange={handleChange}
                handleOpen={setItinerarySelectOpen}
                activeJourney={activeJourney}
              />
            ) : null}
          </span>
        </div>
      </div>
      <hr
        className="gr-bg-gray-400 gr-border-gray-200 gr-border-0 gr-m-0"
        style={{ minHeight: '1px' }}
      />
    </>
  );
}

const mapStateToProps = (state: any) => ({
  activeJourney: state.itinerary.currentBaggageJourney,
  journeys: state.itinerary.baggageJourneys,
  theme: state.session.theme,
});

export default connect(mapStateToProps, {
  setCurrentBaggageJourney,
})(FlightSelector);
