import React from 'react';
import { v4 as uuidv4 } from 'uuid';

import Items from './Items';

import { connect } from 'react-redux';

function RowGroup({
  seats,
  index: rowIndex,
  category = false,
  length: rowLength,
  variants,
}) {
  const length = seats.reduce((prev, current) => {
    return prev + current.width;
  }, 0);

  const { test_seatmap_zoomout = false } = variants;
  const seatPadding =
    test_seatmap_zoomout === true
      ? 'gr-py-1 sm:gr-px-3'
      : 'gr-py-3px sm:gr-p-2';
  return (
    <div
      className={`row-group gr-flex gr-gap-1 gr-justify-center gr-items-center ${seatPadding}`}
      style={{ minWidth: `${(10 / length) * length}%` }}
    >
      {seats.map((item, index) => {
        return (
          <Items
            hasCategoryHighlight={category}
            key={uuidv4()}
            info={item}
            rowLength={rowLength}
            rowIndex={rowIndex}
            index={index}
            length={seats.length}
          ></Items>
        );
      })}
    </div>
  );
}

const mapStateToProps = (state) => ({
  variants: state.session.variants,
});

export default connect(mapStateToProps)(RowGroup);
