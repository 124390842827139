import React, { Component } from 'react';
import { isMobile } from 'react-device-detect';
export default class Button extends Component {
  state = {
    text: '',
  };

  componentDidUpdate(prevPros) {
    if (prevPros.children !== this.props.children) {
      const { children } = this.props;
      this.setState({ text: children });
    }
  }

  componentDidMount() {
    const { children } = this.props;
    this.setState({ text: children });
  }

  onMouseEnter = () => {
    const { hoverText } = this.props;
    this.setState(({ text }) => ({ text: hoverText ? hoverText : text }));
  };

  onMouseLeave = () => {
    const { children } = this.props;
    this.setState({ text: children });
  };

  render() {
    const { disabled = false, id = '', selected } = this.props;
    const { primary = false } = this.props;
    const { text } = this.state;
    const { style } = this.props;

    const focusable = isMobile ? 'non-focusable-button' : 'focusable-button';
    const classNames =
      `gr-rounded gr-text-sm sm:gr-text-base gr-h-12 gr-action-button ` +
      (!!this.props.standalone ? `gr-pl-8 gr-pr-8 gr-m-4` : `gr-w-full`);
    const backgroundColor = selected
      ? 'gr-bg-red-100 gr-text-button-text gr-border-0'
      : 'gr-border-2 gr-border-gray-400';
    const cssClass = disabled
      ? `${classNames} gr-bg-gray-300 gr-text-gray-500 gr-cursor-not-allowed`
      : `${classNames} gr-bg-white`;
    return (
      <button
        id={id}
        style={style}
        onClick={(e) => {
          e.preventDefault();
          this.props.handleClick();
        }}
        onMouseEnter={() => this.onMouseEnter()}
        onMouseLeave={() => this.onMouseLeave()}
        disabled={disabled}
        className={`${cssClass} ${
          primary && !disabled
            ? 'gr-bg-blue-primary gr-border-blue-primary gr-text-button-text'
            : ''
        } ${backgroundColor} ${focusable}`}
      >
        {text}
      </button>
    );
  }
}
