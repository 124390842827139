import React from 'react';

function BaggageIcon({ scale = 1, fill = '#2990E6' }) {
  const size = `${16 * scale}`;
  return (
    <div>
      <svg
        width={size}
        height={size}
        viewBox={`0 0 32 32`}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect width={size} height={size} fill="white" />
        <path
          d="M29 25.5C29 26.8807 23.1797 28 16 28C8.8203 28 3 26.8807 3 25.5C3 24.1193 8.8203 23 16 23C23.1797 23 29 24.1193 29 25.5Z"
          fill={fill}
          fillOpacity="0.2"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M13 6C11.8954 6 11 6.89543 11 8V9C11 9.55228 11.4477 10 12 10C12.5523 10 13 9.55228 13 9V8H19V9C19 9.55228 19.4477 10 20 10C20.5523 10 21 9.55228 21 9V8C21 6.89543 20.1046 6 19 6H13ZM9 11C7.34315 11 6 12.3431 6 14V22C6 23.6569 7.34315 25 9 25H23C24.6569 25 26 23.6569 26 22V14C26 12.3431 24.6569 11 23 11H9ZM8 14C8 13.4477 8.44772 13 9 13H23C23.5523 13 24 13.4477 24 14V22C24 22.5523 23.5523 23 23 23H9C8.44772 23 8 22.5523 8 22V14Z"
          fill="#495662"
        />
        <path
          d="M10 15C10 14.4477 10.4477 14 11 14C11.5523 14 12 14.4477 12 15V21C12 21.5523 11.5523 22 11 22C10.4477 22 10 21.5523 10 21V15Z"
          fill={fill}
        />
        <path
          d="M20 15C20 14.4477 20.4477 14 21 14C21.5523 14 22 14.4477 22 15V21C22 21.5523 21.5523 22 21 22C20.4477 22 20 21.5523 20 21V15Z"
          fill={fill}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M27.5 9C29.9853 9 32 6.98528 32 4.5C32 2.01472 29.9853 0 27.5 0C25.0147 0 23 2.01472 23 4.5C23 6.98528 25.0147 9 27.5 9ZM27 2.5C27 2.22386 27.2239 2 27.5 2C27.7761 2 28 2.22386 28 2.5V4H29.5C29.7761 4 30 4.22386 30 4.5C30 4.77614 29.7761 5 29.5 5H28V6.5C28 6.77614 27.7761 7 27.5 7C27.2239 7 27 6.77614 27 6.5V5H25.5C25.2239 5 25 4.77614 25 4.5C25 4.22386 25.2239 4 25.5 4L27 4V2.5Z"
          fill={fill}
        />
      </svg>
    </div>
  );
}

export default BaggageIcon;
