import React, { Component } from 'react';
import { isMobile } from 'react-device-detect';
import { connect } from 'react-redux';

class SeatSelectButton extends Component {
  state = {
    text: '',
  };

  componentDidUpdate(prevPros) {
    if (prevPros.children !== this.props.children) {
      const { children } = this.props;
      this.setState({ text: children });
    }
  }

  componentDidMount() {
    const { children } = this.props;
    this.setState({ text: children });
  }

  render() {
    const { selected, id = '', theme = {} } = this.props;
    const { text } = this.state;
    const buttonTheme = theme?.select_seat_button;
    const backgroundColor = buttonTheme?.backgroundColor ?? theme.primary;
    const borderColor = buttonTheme?.borderColor ?? theme.primary;
    const color = buttonTheme?.textColor ?? theme.buttonText;
    const style = {
      backgroundColor,
      borderColor,
      color,
    };

    const focusable = isMobile ? 'non-focusable-button' : 'focusable-button';
    const classNames = `gr-rounded gr-text-sm sm:gr-text-base gr-h-12 gr-px-2 gr-w-full gr-action-button`;
    const backgroundColorOverride = selected
      ? 'gr-bg-red-100 gr-text-button-text gr-border-0'
      : 'gr-border-2';
    const status = this.props.selected;
    const aria_passenger = this.props.aria_passenger;
    const aria_seat = this.props.aria_seat;

    return (
      <button
        aria-label={
          status
            ? `Remove Previously Selected Seat ${aria_seat}`
            : `Select Seat ${aria_seat} for ${aria_passenger}`
        }
        id={id}
        style={style}
        onClick={(e) => {
          e.preventDefault();
          this.props.handleClick();
        }}
        className={`${classNames} ${backgroundColorOverride} ${focusable}`}
      >
        {text}
      </button>
    );
  }
}

const mapStateToProps = (state) => ({
  theme: state.session.theme,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(SeatSelectButton);
