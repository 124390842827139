import React, { Component } from 'react';

import Seat from './Seat';

import { Bathroom } from '../shared/Bathroom';
import { Bassinet } from '../shared/Bassinet';
import { Closet } from '../shared/Closet';
import { Kitchen } from '../shared/Kitchen';
import NonSeatItem from '../shared/NonSeatItem';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error, reason) {
    if (window.GordianDisplay && window.GordianDisplay.handleError) {
      window.GordianDisplay.handleError({ error, reason });
    }
  }

  render() {
    if (this.state.hasError) {
      return <div></div>;
    }

    return this.props.children;
  }
}

export default class Items extends Component {
  state = {
    colors: {},
    popupVisible: false,
    SeatComponent: null,
  };

  render() {
    const { type } = this.props.info;
    const {
      length,
      index,
      rowLength,
      rowIndex,
      hasCategoryHighlight = false,
    } = this.props;
    let item = null;
    switch (type) {
      case 'bassinet':
        item = (
          <NonSeatItem
            info={{ ...this.props.info, identifyingClass: 'basinet' }}
          >
            <Bassinet />
          </NonSeatItem>
        );
        break;
      case 'kitchen':
        item = (
          <NonSeatItem
            info={{ ...this.props.info, ariaLabelText: 'Kitchen' }}
            useZoomout={true}
          >
            <Kitchen />
          </NonSeatItem>
        );
        break;
      case 'bathroom':
        item = (
          <NonSeatItem
            info={{ ...this.props.info, ariaLabelText: 'Bathroom' }}
            useZoomout={true}
          >
            <Bathroom />
          </NonSeatItem>
        );
        break;
      case 'seat':
        item = (
          <Seat
            hasCategoryHighlight={hasCategoryHighlight}
            key={this.props.info.product_id}
            info={this.props.info}
            outsideClickIgnoreClass={'seat-details'}
            length={length}
            rowLength={rowLength}
            rowIndex={rowIndex}
            index={index}
          ></Seat>
        );
        break;
      case 'closet':
        item = (
          <NonSeatItem
            info={{ ...this.props.info, identifyingClass: 'closet' }}
          >
            <Closet />
          </NonSeatItem>
        );
        break;
      case 'missing':
        item = (
          <NonSeatItem
            info={{ ...this.props.info, identifyingClass: 'gordian-mising' }}
            hasBorder={false}
          ></NonSeatItem>
        );
        break;
      default:
        item(
          <div
            className={`gordian-${type} gr-relative gr-z-50 gr-h-8 gr-w-8 sm:h-10 sm:gr-w-10 md:gr-h-12 md:gr-w-12`}
          ></div>,
        );
    }

    return <ErrorBoundary>{item}</ErrorBoundary>;
  }
}
