import React from 'react';
import { Translate } from 'react-localize-redux';

import BaggageIcon from './BaggageIcon';

function MainHeader({ theme = {} }) {
  return (
    <header className="gr-flex-col gr-h-15 gr-w-full gr-pl-6 gr-pb-4">
      <div className="gr-flex gr-w-full gr-items-center">
        <div className="gr-flex gr-mx-6">
          <BaggageIcon fill={theme.primary} scale={4} />
        </div>
        <div className="gr-flex gr-flex-col gr-items-start">
          <h1 className="gr-flex gr-text-black gr-inline gr-my-2">
            <Translate id="baggage.title" />
          </h1>
          <span className="gr-flex gr-text-secondary-text gr-text-left gr-mr-4 gr-hidden sm:gr-block">
            <Translate id="baggage.subtitle" />
          </span>
        </div>
      </div>
    </header>
  );
}

export default MainHeader;
