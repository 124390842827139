import React, { Component } from 'react';
import SectionHeader from './SectionHeader';
import PassengerBaggageRow from '../baggage/PassengerBaggageRow';
import { connect } from 'react-redux';

class JourneySection extends Component {
  JourneyRows(journey_id, journey_index) {
    const is_return = journey_index > 0;
    const section = [];
    section.push(
      <SectionHeader
        key={`section_header_${is_return ? 'inbound' : 'outbound'}`}
        isInbound={is_return}
      />,
    );
    const {
      passengers = [],
      baggageData,
      baggageDisclaimers = {},
    } = this.props;
    passengers.forEach((passenger, index) => {
      const { passenger_id } = passenger;

      const allProducts = baggageData?.results?.products?.bag;
      if (!allProducts || Object.keys(allProducts).length === 0) {
        return;
      }
      const availableProducts = [];
      for (const bagId in allProducts) {
        const { price_and_availability, product_details } = allProducts[bagId];
        const bagJourneyId = product_details.journey_id;
        if (
          journey_id === bagJourneyId &&
          price_and_availability[passenger_id]
        ) {
          availableProducts.push(allProducts[bagId]);
        }
      }
      let disclaimerText;
      if (baggageDisclaimers[journey_index]) {
        disclaimerText = baggageDisclaimers[journey_index][index];
      }

      section.push(
        <div
          className="gr-px-6 sm:gr-px-12"
          key={`baggage_row_${passenger.passenger_id}`}
        >
          <PassengerBaggageRow
            passenger={passenger}
            available_products={availableProducts}
            disclaimer_text={disclaimerText}
          />
        </div>,
      );
      if (index < passengers.length - 1) {
        section.push(
          <hr
            key={`passenger_divider_${passenger.passenger_id}${
              is_return ? '_return' : ''
            }`}
            className="gr-flex gr-border-gray-100 gr-mx-6 sm:gr-mx-12 gr-mb-6"
          />,
        );
      }
    });
    return section;
  }

  render() {
    const { baggageData } = this.props;
    if (!baggageData || Object.keys(baggageData).length === 0) {
      return null;
    }
    const { journeys } = this.props.baggageData?.results?.itineraries?.[0] ?? {
      journeys: [],
    };
    return journeys.map((journey, index) => {
      return this.JourneyRows(journey.journey_id, index);
    });
  }
}

const mapStateToProps = (state) => ({
  baggageData: state.baggageJSON.data,
  baggageDisclaimers: state.session.baggageDisclaimers,
  passengers: state.passengers.listOfBaggagePassengers,
});

export default connect(mapStateToProps, {})(JourneySection);
