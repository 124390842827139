import React from 'react';
import { connect } from 'react-redux';
import { Theme } from './CommonTypes';
import { Translate } from 'react-localize-redux';
function AddButton({
  onClick,
  small = false,
  disabled = false,
  showPrice = false,
  buttonText,
  price,
  mobile = false,
  theme,
  addButtonTextWidth,
}: {
  onClick: () => void;
  small?: boolean;
  disabled?: boolean;
  showPrice?: boolean;
  buttonText?: string;
  price?: string;
  mobile?: boolean;
  theme?: Theme;
  addButtonTextWidth: number;
}) {
  return (
    <div
      onClick={!disabled ? onClick : () => {}}
      className={`${
        !disabled ? 'gr-cursor-pointer' : ''
      } gr-flex add-btn gr-items-center`}
    >
      {showPrice || buttonText ? (
        <button
          className="gr-next-btn gr-cursor-pointer gr-font-medium gr-text-center gr-h-8 gr-text-base gr-border-0 gr-p-0"
          style={{
            background: disabled
              ? '#8D8D8D4D'
              : theme?.bags?.addButtonColor ?? '#4CA7DE',
            color: theme?.bags?.addButtonText ?? '#FFFFFF',
            borderRadius: theme?.bags?.addButtonRounding ?? '8px',
            width: `${addButtonTextWidth + 20}px`,
            fontFamily: 'inherit',
          }}
        >
          {disabled && buttonText ? (
            <Translate id={buttonText.toLowerCase()}>{buttonText}</Translate>
          ) : (
            ''
          )}
          {disabled && !buttonText ? '+' : ''}
          {!disabled && buttonText ? (
            <Translate id={buttonText.toLowerCase()}>{buttonText}</Translate>
          ) : (
            ''
          )}
          {!disabled && buttonText && showPrice && price ? ' ' : ''}
          {!disabled && showPrice && price ? '+' + price : ''}
        </button>
      ) : small ? (
        <svg
          width={mobile ? '16' : '20'}
          height={mobile ? '16' : '20'}
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle
            cx="8"
            cy="8"
            r="8"
            fill={
              disabled ? '#8D8D8D4D' : theme?.bags?.addButtonColor ?? '#4CA7DE'
            }
          />
          <g clipPath="url(#clip0_0_1)">
            <path
              d="M11.5 8.5H8.5V11.5H7.5V8.5H4.5V7.5H7.5V4.5H8.5V7.5H11.5V8.5Z"
              fill="white"
            />
          </g>
          <defs>
            <clipPath id="clip0_0_1">
              <rect
                width="12"
                height="12"
                fill="white"
                transform="translate(2 2)"
              />
            </clipPath>
          </defs>
        </svg>
      ) : (
        <svg
          width={mobile ? '26' : '34'}
          height={mobile ? '24' : '32'}
          viewBox={'0 0 26 24'}
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect
            x="0"
            y="0"
            width="26"
            height="24"
            fill={
              disabled ? '#8D8D8D4D' : theme?.bags?.addButtonColor ?? '#4CA7DE'
            }
            rx={theme?.bags?.addButtonRounding ?? 9}
            ry={theme?.bags?.addButtonRounding ?? 9}
          />
          <path
            d="M11.4007 17.54V12.668H6.64869V10.556H11.4007V5.828H13.6087V10.556H18.3607V12.668H13.6087V17.54H11.4007Z"
            fill="white"
          />
        </svg>
      )}
    </div>
  );
}

const mapStateToProps = (state: any) => ({
  theme: state.session.theme,
});

export default connect(mapStateToProps)(AddButton);
