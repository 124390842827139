/*
 * Either the intro screen or the seatmap
 */

import React from 'react';
import { connect } from 'react-redux';
import Introduction from '../../widgets/Introduction';
import SeatMapStep from './SeatMapStep';

const leadInPriceForCategory = category => {
  // If the child prices start at $2 and the adult prices start at $50, we want to display $50
  let mostExpensive = null;
  for (const priceRange of Object.values(category.price_range)) {
    if (
      mostExpensive === null ||
      priceRange.start_price.total > mostExpensive.total
    ) {
      mostExpensive = priceRange.start_price;
    }
  }
  return mostExpensive;
};

const cheapestPrice = seatmapsBySegment => {
  if (Object.keys(seatmapsBySegment).length > 0) {
    let cheapest = null;
    Object.values(seatmapsBySegment).forEach(seatmap => {
      if (seatmap.available && !!seatmap.seat_categories) {
        seatmap.seat_categories.forEach(category => {
          let leadInPrice = leadInPriceForCategory(category);
          if (cheapest === null || leadInPrice.total < cheapest.total) {
            cheapest = leadInPrice;
          }
        });
      }
    });
    return {
      amount: cheapest.total,
      decimalPlaces: cheapest.decimal_places,
      currency: cheapest.currency
    };
  }
};

const SeatMapStepSelector = ({
  seatMapStep,
  variants,
  modal,
  isOptIn,
  seatmapsBySegment
}) => {
  let leadInPrice = !seatmapsBySegment
    ? null
    : cheapestPrice(seatmapsBySegment);
  if (
    variants.test_introduction === true &&
    seatMapStep === 'introduction' &&
    !isOptIn &&
    (leadInPrice === undefined || leadInPrice.amount > 0)
  ) {
    return <Introduction leadInPrice={leadInPrice} />;
  } else {
    return <SeatMapStep modal={modal} isOptIn={isOptIn} />;
  }
};

const mapStateToProps = state => ({
  seatMapStep: state.session.seatMapStep,
  variants: state.session.variants,
  seatmapsBySegment: state.itinerary.seatmapsBySegment
});

export default connect(mapStateToProps, {})(SeatMapStepSelector);
