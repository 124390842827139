import React, { Component } from 'react';
import GenericValidationPopup from './templates/GenericValidationPopup';
import { ReactComponent as GroupIcon } from '../../svg/large-group-error.svg';
import Button from '../shared/Button';
import { Translate } from 'react-localize-redux';
import { setShowSeatmapValidateGroup } from '../../redux/actions';
import { connect, ConnectedProps } from 'react-redux';
import FocusTrap from 'focus-trap-react';
import MediaQuery from 'react-responsive';

const mapStateToProps = () => ({});

const mapDispatchToProps = {
  setShowSeatmapValidateGroup,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

interface SeatGroupValidationPopupProps extends PropsFromRedux {
  mobile?: boolean;
  parentHeight: number;
  position: any;
  exitModal: boolean;
}

export class SeatGroupValidationPopup extends Component<SeatGroupValidationPopupProps> {
  render() {
    const { mobile = false } = this.props;

    const popupIcon = <GroupIcon />;
    const primary = <Translate id="large_group" />;
    const secondary = <Translate id="large_group_validation" />;
    const button = (
      <Button
        primary={true}
        handleClick={() => this.props.setShowSeatmapValidateGroup(false)}
      >
        <Translate id="change_seats" />
      </Button>
    );

    return (
      <MediaQuery minWidth={650}>
        {(matches) => (
          <FocusTrap
            focusTrapOptions={{ initialFocus: '#seat-validation-container' }}
            active={!mobile && matches && !this.props.exitModal}
          >
            <GenericValidationPopup
              mobile={mobile}
              popupIcon={popupIcon}
              primary={primary}
              secondary={secondary}
              button={button}
              id="group-details"
              parentHeight={this.props.parentHeight}
              {...this.props.position}
            />
          </FocusTrap>
        )}
      </MediaQuery>
    );
  }
}

export default connector(SeatGroupValidationPopup);
