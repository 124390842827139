import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getDisplayPrice } from '../../utils/getDisplayPrice';
import { SeatLoader } from '../shared/Loaders';
import { doOpen } from './UpsellUtils';
import { calculateConvenienceFeeVariant } from '../../utils/calculateConvenienceFeeVariant';
import party from 'party-js';

import {
  updateSelectedSeat,
  updateSeatDetailsPosition,
  showExitModal,
  showUpsellModal,
  setInFocusCompartment,
  acceptSeat,
  confirmBasket,
  nextPassenger,
  removeSeatFromBasket,
  setCurrentPassenger,
} from '../../redux/actions';

import MissingSeatIcon from '../shared/MissingSeatIcon';
import SeatIcon from '../shared/SeatIcon';
import Price from './Price';
import ExitRow from '../shared/ExitRow';
import { UnavailableIcon } from './Category';

import { isMobile } from 'react-device-detect';
import MediaQuery from 'react-responsive';
import PremiumSeatIcon from './PremiumSeatIcon';

export class Seat extends Component {
  state = {
    clicked: false,
    seatDesign: {
      fill: '#AADB72',
      stopColor: '#8EBD59',
    },
  };

  componentDidMount() {
    const {
      categories = PageTransitionEvent,
      currentSeatmap,
      info,
    } = this.props;

    if (Object.keys(categories ?? {}).length > 0) {
      const seatmapCategories = categories[currentSeatmap];
      const { seat_category_id } = info;
      if (seatmapCategories[seat_category_id]) {
        const {
          stopColor = '',
          fill = '',
          labelColor,
          borderColor,
          rank,
        } = seatmapCategories[seat_category_id];
        this.setState({
          seatDesign: { stopColor, fill, labelColor, borderColor },
          cat_helper: seatmapCategories[seat_category_id]['display_name'],
          rank,
        });
      }
    }
  }

  selectSeat = (
    event,
    selected,
    test_seatmap_one_click,
    useEnhancedSeatIcon
  ) => {
    const { left_exit, right_exit, characteristics = [] } = this.props.info;
    const {
      showExitModal,
      acceptedExitRegulation,
      currentSeatmap,
      currentPassenger,
      nextPassenger,
      removeSeatFromBasket,
      setCurrentPassenger,
      updateSelectedSeat,
      updateSeatDetailsPosition,
      selectedProduct,
      acceptSeat,
      variants,
      confirmBasket,
    } = this.props;

    const { test_confirm_on_click = false } = variants;

    const clicked = test_seatmap_one_click ? false : this.state.clicked;

    this.handleUpsell();

    const position = this.getSeatDetailPosition(event, useEnhancedSeatIcon); // Look at SeatDetails.js for exact display position
    const shouldPromptExitRegulation =
      (left_exit || right_exit || characteristics.includes('exit_row')) &&
      !acceptedExitRegulation;
    if (shouldPromptExitRegulation) {
      updateSelectedSeat(this.props.info);
      updateSeatDetailsPosition(position);
      showExitModal(true);
    }

    if (clicked && !selected) {
      updateSelectedSeat(null);
      this.setState({ clicked: false });
      return;
    } else if (selected) {
      // Unassign seat to passenger
      this.setState({ clicked: false });

      if (test_seatmap_one_click) {
        if (!shouldPromptExitRegulation) {
          removeSeatFromBasket({
            selectedSeat: this.props.info,
            currentSeatmap,
          });
          test_confirm_on_click && confirmBasket(); // Confirm basket when seat deselected
          // Get passenger of unselected seat
          const { passenger_id } =
            this.props.basket.seats.find(
              (seat) => seat.product_id === this.props.info.product_id
            ) || {};
          const passengerForSelectedSeat =
            this.props.passengers.listOfPassenger.find(
              (passenger) => passenger.passenger_id === passenger_id
            );
          setCurrentPassenger(passengerForSelectedSeat); // Allow this passenger to select a new seat
          updateSelectedSeat(null);
        }
      } else {
        updateSelectedSeat(this.props.info);
        updateSeatDetailsPosition(position);
      }
    } else {
      // Assign seat to passenger
      if (test_seatmap_one_click) {
        if (!shouldPromptExitRegulation) {
          acceptSeat({
            currentSeatmap,
            selectedSeat: this.props.info,
            currentPassenger,
            selectedProduct,
          });
          test_confirm_on_click && confirmBasket(); // Confirm basket if embedded version
          nextPassenger(currentPassenger);
        }
      } else {
        this.setState({ clicked: !clicked });
      }

      updateSelectedSeat(this.props.info);
      updateSeatDetailsPosition(position);
    }
  };

  handleMouseEnter = (event, isMobileSize, useEnhancedSeatIcons) => {
    // For one-click variant, this will display seat details dialog on hover
    const { variants } = this.props;

    const { test_seatmap_one_click_desktop = true } = variants;

    if (test_seatmap_one_click_desktop && !isMobileSize && !isMobile) {
      const position = this.getSeatDetailPosition(event, useEnhancedSeatIcons); // Look at SeatDetails.js for exact display position
      this.props.updateSeatDetailsPosition(position);
      this.props.updateSelectedSeat(this.props.info);
    }
  };

  handleMouseLeave = (event, isMobileSize) => {
    // For one-click variant, this will hide the seat details dialog
    const { variants } = this.props;
    const { test_seatmap_one_click_desktop = true } = variants;
    let exitReg = document.getElementById('exit-regulations-overlay'); // Check if exit overly cause leave
    if (
      test_seatmap_one_click_desktop &&
      !exitReg &&
      !isMobileSize &&
      !isMobile
    ) {
      this.props.updateSelectedSeat(null);
    }
  };

  // Returns position data of the seat details floating prompt
  getSeatDetailPosition = (event, useEnhancedSeatIcons) => {
    const fullOverlay = document.getElementById('gordian-overlay');
    const overlayRect = fullOverlay?.getBoundingClientRect();
    const compartment = document.getElementById('gordian-compartments');
    const compartmentRect = compartment.getBoundingClientRect();
    const scrollingSeatMap = document.getElementById('seat-map');
    const seatMapRect = scrollingSeatMap.getBoundingClientRect();
    const shadowedMargin =
      ((overlayRect?.width ?? seatMapRect.width) - seatMapRect.width) / 2;
    let marginLeft = parseInt(
      window.getComputedStyle(compartment).marginLeft,
      10
    );
    let seatRect = event.currentTarget.getBoundingClientRect();
    let seatOffsetLeft = event.currentTarget.offsetLeft;
    let seatOffsetTop = event.currentTarget.offsetTop;
    let seatHeight = seatRect.height;
    let seatWidth = seatRect.width;

    if (useEnhancedSeatIcons) {
      const seatIcon = event.currentTarget.firstElementChild;
      const seatIconRect = seatIcon.getBoundingClientRect();
      seatOffsetLeft = seatOffsetLeft + (seatIconRect.x - seatRect.x);
      seatOffsetTop = seatOffsetTop + (seatIconRect.y - seatRect.y);
      seatHeight = seatIconRect.height;
      seatWidth = seatIconRect.width;
    }
    const initialOffsetParent = event.currentTarget.offsetParent;
    let currentOffsetParent = initialOffsetParent;
    while (
      currentOffsetParent.id !== 'seat-map' &&
      currentOffsetParent.id !== 'gordian-compartments'
    ) {
      seatOffsetLeft += currentOffsetParent.offsetLeft;
      currentOffsetParent = currentOffsetParent.offsetParent;
    }
    currentOffsetParent = initialOffsetParent;
    while (currentOffsetParent.id !== 'seat-map') {
      seatOffsetTop += currentOffsetParent.offsetTop;
      currentOffsetParent = currentOffsetParent.offsetParent;
    }
    const maxHeight = scrollingSeatMap // seat-map used bc y position is relative to current view
      .getBoundingClientRect().height;
    //because firefox adds a margin chrome does not we forcibly set it to zero when the legend  is hidden due to reduced width
    marginLeft =
      initialOffsetParent.id === 'gordian-compartments' ||
      initialOffsetParent.offsetParent.id === 'gordian-compartments'
        ? 0
        : marginLeft;
    let horizontalSpaceRight =
      compartmentRect.x +
      marginLeft +
      compartmentRect.width -
      shadowedMargin -
      seatOffsetLeft;
    let verticalSpaceBelow =
      maxHeight - seatOffsetTop + scrollingSeatMap.scrollTop - seatHeight;
    return {
      horizontalSpaceRight,
      verticalSpaceBelow,
      leftDisplacement: seatOffsetLeft,
      ySpace: maxHeight,
      scrolledY: scrollingSeatMap.scrollTop,
      seatHeight,
      seatWidth,
    };
  };

  handleClickOutside = (event) => {
    if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
      if (
        this.state.clicked &&
        !event.target.className.includes('gr-exit-regulations') &&
        event.target.id !== 'accept_exit_regulations'
      ) {
        this.setState({ clicked: false });
      }
    }
  };

  handleKeyboardInput = (event) => {
    if (
      event.code === 'Space' ||
      event.code === 'Escape' ||
      event.code === 'Enter'
    ) {
      this.setState({ clicked: false });
    }
  };

  setWrapperRef = (node) => {
    this.wrapperRef = node;
  };

  shouldComponentUpdate(nextProps, nextState) {
    return (
      // Refresh the component if the basket changes with this seat as part of the change
      nextProps.basket !== this.props.basket ||
      nextState !== this.state ||
      nextProps.categories !== this.props.categories ||
      nextProps.currentPassenger !== this.props.currentPassenger ||
      nextProps.selectedSeat === this.props.info ||
      (nextProps.basket?.removedSeat != null &&
        nextProps.basket.removedSeat.seat === this.props.info.seat)
    );
  }

  handleUpsell() {
    const {
      hasCategoryHighlight = false,
      upsellOptionsMap,
      currentSeatmap,
      currentCompartments,
      showUpsellModal,
      setInFocusCompartment,
    } = this.props;
    if (!hasCategoryHighlight) {
      return;
    }

    const name = hasCategoryHighlight.display_name;
    const possibleCompartments = upsellOptionsMap[currentSeatmap];
    const selectedCompartment = possibleCompartments[name];

    // validate we should open upsell container.
    if (doOpen(selectedCompartment, currentCompartments[currentSeatmap])) {
      // selectedCompartment is used in UpsellDisclaimer to get the seat benefits associated with a compartment.
      setInFocusCompartment(selectedCompartment);
      showUpsellModal(true);
    }
  }

  render() {
    const {
      info,
      selectedProduct,
      currentPassenger,
      index,
      length,
      rowIndex,
      theme,
      hidePrice,
      rowLength,
      variants = {},
      hasCategoryHighlight = false,
      relativeWidthRatio,
      useEnhancedSeatmap,
      currentSeatmap,
      categories,
      priceAdjustment = {},
    } = this.props;
    const { seatDesign } = this.state;
    const {
      seat = '',
      characteristics = [],
      bookable_seat,
      row,
      columns,
    } = info;
    const { price_and_availability = {} } = selectedProduct;

    const key = `${this.props.info.product_id}${this.props.info.seat_category_id}${this.props.info.type}`;

    const {
      test_mobile_simple = true,
      test_seatmap_zoomout = false,
      test_seatmap_one_click_desktop = true,
      test_seatmap_one_click_mobile = false,
      test_seatmap_confetti = false,
    } = variants;
    const simpleVariant = test_mobile_simple;
    const clicked =
      (!isMobile && test_seatmap_one_click_desktop) ||
      (isMobile && test_seatmap_one_click_mobile)
        ? false
        : this.state.clicked;

    const priceFactor = priceAdjustment.factor ?? 1.0;

    const seatCategory = categories?.[currentSeatmap]?.[info.seat_category_id];
    const exitRow = characteristics.includes('exit_row');

    let brand = undefined;
    if (variants.test_uber_theme) {
      brand = 'uber';
    }

    const seatZoomoutStyle = !(test_seatmap_zoomout === true)
      ? 'md:gr-h-12 md:gr-w-12'
      : 'md:gr-mx-1';

    const seatFocusStyle = isMobile
      ? 'non-focusable-button'
      : 'focusable-button';

    const seatContainerDisplay = useEnhancedSeatmap
      ? 'gr-premium-seat-container'
      : '';

    const seatBorderColor = brand === 'uber' ? 'gr-border' : 'gr-border-white';
    if (!bookable_seat) {
      const seatLocationDescription =
        columns.length === 1
          ? `Row ${row}, Column ${columns[0]}`
          : `Row ${row}`;
      const unavailableSeatAriaLabel = `Unavailable Seat at ${seatLocationDescription}`;

      let brand = undefined;
      if (variants.test_uber_theme) brand = 'uber';

      return (
        <MediaQuery maxWidth={620 / relativeWidthRatio}>
          {(isMobileSize) => {
            const isMobileLike = isMobile || isMobileSize;
            const useDesktopEnhancedSeatmap =
              useEnhancedSeatmap && !isMobileLike;
            return (
              <div
                aria-label={unavailableSeatAriaLabel}
                style={{
                  margin: theme.margin,
                }}
                ref={(node) => (this.node = node)}
                className={`missing-seat gr-border gr-rounded gr-relative gr-z-50 gr-text-white gr-h-8 gr-w-8 sm:gr-h-10 sm:gr-w-10 ${seatZoomoutStyle} ${seatFocusStyle} ${seatBorderColor} ${seatContainerDisplay}`}
              >
                {exitRow && index === 0 && rowIndex === 0 ? (
                  <ExitRow highlight={hasCategoryHighlight} side={'left'} />
                ) : null}
                {exitRow &&
                index === length - 1 &&
                rowIndex === rowLength - 1 ? (
                  <ExitRow highlight={hasCategoryHighlight} side={'right'} />
                ) : null}
                {!simpleVariant ? (
                  <MissingSeatIcon />
                ) : useDesktopEnhancedSeatmap ? (
                  <PremiumSeatIcon
                    rotation={info.rotation}
                    seatCategory={seatCategory}
                    unavailable={true}
                    theme={theme}
                  ></PremiumSeatIcon>
                ) : (
                  <UnavailableIcon
                    stretch={true}
                    bgColor={theme.seats['not_available'].fill}
                    strokeColor={theme.seats['not_available'].strokeColor}
                    isUber={brand === 'uber'}
                  />
                )}
              </div>
            );
          }}
        </MediaQuery>
      );
    }

    const available =
      price_and_availability &&
      price_and_availability[currentPassenger.passenger_id]?.available;

    /// The seats have UUIDs, so we really only need to check that.
    const selected = this.props.basket.seats.some(
      (seat) => seat.product_id === this.props.info.product_id
    );

    const addListeners = (clicked, selected) => {
      if (clicked) {
        document.addEventListener('mousedown', this.handleClickOutside);
        document.addEventListener('keypress', this.handleKeyboardInput);
      }

      if (selected) {
        document.removeEventListener('mousedown', this.handleClickOutside);
        document.removeEventListener('keypress', this.handleKeyboardInput);
      }

      return (theme && theme.seats && theme.seats['selected_seat']) || {};
    };

    let seatColors = seatDesign;

    if (clicked || selected) {
      seatColors = addListeners(clicked, selected);
    }

    const { price = {} } =
      price_and_availability[currentPassenger.passenger_id] ?? {};
    const { total = {}, base = {} } = price;
    const { decimal_places, currency } = total;

    const seatBg = (
      simpleVariant,
      isClicked,
      isSelected,
      useDesktopEnhancedSeatmap
    ) => {
      if (simpleVariant && !useDesktopEnhancedSeatmap) {
        if (this.props.theme && this.props.theme.selectedSeat && isClicked) {
          return this.props.theme.selectedSeat.background;
        } else if (
          this.props.theme &&
          this.props.theme.confirmedSeat &&
          isSelected
        ) {
          return this.props.theme.confirmedSeat.background;
        } else {
          return this.props.theme.isAllegris
            ? seatColors.fill
            : seatColors.stopColor;
        }
      } else {
        return null;
      }
    };

    const seatColor = (simpleVariant, isClicked, isSelected) => {
      if (simpleVariant) {
        if (this.props.theme && this.props.theme.selectedSeat && isClicked) {
          return this.props.theme.selectedSeat.color;
        } else if (
          this.props.theme &&
          this.props.theme.confirmedSeat &&
          isSelected
        ) {
          return this.props.theme.confirmedSeat.color;
        } else {
          return seatColors.labelColor ? seatColors.labelColor : 'white';
        }
      } else {
        return 'white';
      }
    };

    if (this.props.loading) {
      return (
        <div
          className={`gr-relative gr-h-8 gr-w-8 sm:gr-h-10 sm:gr-w-10 ${seatZoomoutStyle}`}
        >
          <SeatLoader />
        </div>
      );
    } else if (clicked || selected || available) {
      // ARIA
      let selectedAria = '';
      let passengerForSelectedSeat = null;
      if (selected) {
        const { passenger_id } =
          this.props.basket.seats.find(
            (seat) => seat.product_id === this.props.info.product_id
          ) || {};
        passengerForSelectedSeat = this.props.passengers.listOfPassenger.find(
          (passenger) => passenger.passenger_id === passenger_id
        );
        if (passengerForSelectedSeat) {
          selectedAria = `. Seat is selected for ${passengerForSelectedSeat.first_names} ${passengerForSelectedSeat.surname}`;
        } else {
          selectedAria = `. Seat is selected`;
        }
      }

      const seatLabel = passengerForSelectedSeat
        ? 'P' + (passengerForSelectedSeat.index + 1)
        : seat;

      let seat_aria = this.props.selectedProduct.display_name.split(',')[0];
      if (exitRow) seat_aria = `Exit Row ${seat_aria}`;
      const cat_aria = this.state.cat_helper || 'Standard Seat';
      const price_aria =
        currency + String(total.amount / Math.pow(10, total.decimal_places));
      const seatLocationDescription =
        columns.length === 1
          ? `Row ${row}, Column ${columns[0]}`
          : `Row ${row}`;

      const ariaLabel =
        seat_aria +
        ' at ' +
        seatLocationDescription +
        ' with a Category of ' +
        cat_aria +
        ' and a price of ' +
        price_aria +
        selectedAria;

      return (
        <MediaQuery maxWidth={620 / relativeWidthRatio}>
          {(isMobileSize) => {
            const isMobileLike = isMobile || isMobileSize;
            const useDesktopEnhancedSeatmap =
              useEnhancedSeatmap && !isMobileLike;
            const test_seatmap_one_click = isMobileLike
              ? test_seatmap_one_click_mobile
              : test_seatmap_one_click_desktop;
            const clicked = test_seatmap_one_click ? false : this.state.clicked;
            const seatFocusStyle =
              isMobileSize || useDesktopEnhancedSeatmap
                ? 'non-focusable-button'
                : 'focusable-button';

            const seatBorder = !useDesktopEnhancedSeatmap ? 'gr-border' : '';

            if (clicked || selected) {
              seatColors = addListeners(clicked, selected);
            }
            let convenienceFeeDisplayVariant = calculateConvenienceFeeVariant(
              variants['convenience_fee_display_variant']
            );

            const baseConfettiCount = 20 + this.state.rank * 10;
            return (
              <button
                aria-label={ariaLabel}
                ref={this.setWrapperRef}
                style={{
                  backgroundColor: seatBg(
                    simpleVariant,
                    clicked,
                    selected,
                    useDesktopEnhancedSeatmap
                  ),
                  color: seatColor(simpleVariant, clicked, selected),
                  margin: theme.margin,
                  borderColor: seatColors.borderColor,
                  outline: 'none',
                }}
                key={key}
                className={`gordian-seat ${seatBorder} gr-rounded gr-cursor-pointer gr-relative gr-z-50 gr-text-seat sm:gr-text-xs gr-h-8 gr-w-8 sm:gr-h-10 sm:gr-w-10 ${seatZoomoutStyle} ${seatFocusStyle} ${seatContainerDisplay}`}
                onClick={(event) => {
                  event.preventDefault();
                  this.selectSeat(
                    event,
                    selected,
                    test_seatmap_one_click,
                    useDesktopEnhancedSeatmap
                  );
                  !selected &&
                    test_seatmap_confetti &&
                    party.confetti(event.target, {
                      count: party.variation.range(
                        baseConfettiCount - 5,
                        baseConfettiCount + 5
                      ),
                      size: party.variation.range(0.5, 0.7),
                    });
                }}
                onMouseEnter={(event) =>
                  this.handleMouseEnter(
                    event,
                    isMobileSize,
                    useDesktopEnhancedSeatmap
                  )
                } // One-click variant: show seat details
                onMouseLeave={(event) =>
                  this.handleMouseLeave(event, isMobileSize)
                } // One-click variant: hide seat details
              >
                {exitRow && index === 0 && rowIndex === 0 ? (
                  <ExitRow highlight={hasCategoryHighlight} side={'left'} />
                ) : null}
                {exitRow &&
                index === length - 1 &&
                rowIndex === rowLength - 1 ? (
                  <ExitRow highlight={hasCategoryHighlight} side={'right'} />
                ) : null}
                {!simpleVariant ? (
                  <SeatIcon id={key} key={key} {...seatColors} />
                ) : null}

                {useDesktopEnhancedSeatmap ? (
                  <PremiumSeatIcon
                    rotation={info.rotation}
                    seatCategory={seatCategory}
                    theme={theme}
                    selected={selected}
                    seat={seat}
                  />
                ) : null}

                {(selected || clicked || hidePrice) &&
                !useDesktopEnhancedSeatmap ? (
                  seatLabel
                ) : simpleVariant && !useDesktopEnhancedSeatmap ? (
                  <Price
                    currency={currency}
                    amount={getDisplayPrice(
                      convenienceFeeDisplayVariant,
                      base.amount * priceFactor,
                      total.amount * priceFactor
                    )}
                    decimalPlaces={decimal_places}
                    simple={true}
                    isSeat={true}
                    abbreviatePrice={this.props.abbreviatePrice}
                    hideFree={this.props.theme.hideZeroPrices}
                  />
                ) : null}
              </button>
            );
          }}
        </MediaQuery>
      );
    } else {
      return (
        <div
          aria-label={'Unavailable Seat'}
          style={{
            backgroundColor: simpleVariant
              ? theme.seats['not_available'].stopColor
              : null,
            margin: theme.margin,
          }}
          ref={(node) => (this.node = node)}
          className={`missing-seat gr-border gr-rounded gr-border-white gr-relative gr-z-50 gr-text-white gr-h-8 gr-w-8 sm:gr-h-10 sm:gr-w-10 ${seatZoomoutStyle} ${seatFocusStyle}`}
        >
          {exitRow && index === 0 && rowIndex === 0 ? (
            <ExitRow highlight={hasCategoryHighlight} side={'left'} />
          ) : null}
          {exitRow && index === length - 1 && rowIndex === rowLength - 1 ? (
            <ExitRow highlight={hasCategoryHighlight} side={'right'} />
          ) : null}
          {!simpleVariant ? <MissingSeatIcon /> : null}
        </div>
      );
    }
  }
}

const mapStateToProps = (state, ownProps) => ({
  basket: state.basket,
  loading: state.session.loading,
  categories: state.categories,
  mobile: state.session.mobile,
  theme: state.session.theme,
  variants: state.session.variants,
  passengers: state.passengers,
  currentPassenger: state.passengers.currentPassenger,
  currentSeatmap: state.itinerary.currentSeatmap,
  useEnhancedSeatmap:
    state.itinerary.seatmapsBySegment[state.itinerary.currentSeatmap]
      ?.useEnhancedSeatmap,
  seatmapsBySegment: state.itinerary.seatmapsBySegment,
  selectedSeat:
    state.selectedSeat /* Note for one-click selected seat used to get info about seat 
                                     and not actually select the seat anymore (seats are confirmed only using acceptSeat) */,
  selectedProduct:
    state.products?.products?.seat?.[ownProps.info?.product_id] || {},
  acceptedExitRegulation: state.session.acceptedExitRegulation,
  hidePrice: state.session.shouldHidePrice,
  abbreviatePrice: state.session.shouldAbbreviateSeatPrice,
  upsellOptionsMap: state.itinerary.upsellOptionsMap,
  currentCompartments: state.session.currentCompartments,
  relativeWidthRatio: state.session.relativeWidthRatio,
  priceAdjustment: state.session.priceAdjustment,
});

const mapDispatchToProps = {
  updateSelectedSeat,
  updateSeatDetailsPosition,
  showExitModal,
  showUpsellModal,
  setInFocusCompartment,
  acceptSeat,
  nextPassenger,
  removeSeatFromBasket,
  setCurrentPassenger,
  confirmBasket,
};

var clickOutsideConfig = {
  excludeScrollbar: true,
};

export default connect(mapStateToProps, mapDispatchToProps)(
  Seat,
  clickOutsideConfig
);
