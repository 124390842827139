import React, { Component } from 'react';
import { connect } from 'react-redux';
import { rtlLangs } from '../../translations/rtl-langs';
class Footer extends Component {
  render() {
    return (
      <div
        id="standard-footer"
        className="gr-absolute gr-bottom-0 gr-h-20 gr-border gr-bg-white gr-w-full gr-z-100"
        dir={rtlLangs.includes(this.props.locale) ? 'rtl' : 'ltr'}
      >
        <div className="gr-flex gr-items-center gr-justify-between gr-flex-row-reverse sm:gr-flex-row gr-h-full gr-w-full sm:gr-px-3 gr-overflow-hidden">
          {this.props.children}
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  locale: state.session.locale
});

export default connect(mapStateToProps, {})(Footer);
