import { handleAction } from 'redux-actions';
import { SET_SEAT_CATEGORIES } from '../actions';

const HOST = process.env
  ? (process.env?.REACT_APP_STATIC_URL ?? 'https://static.gordiansoftware.com')
  : 'https://static.gordiansoftware.com';

const SEAT_IMAGES = {
  VY: {
    'Space One Seat': {
      start_row: 1,
      end_row: 1,
      name: 'Space One',
      short_description: '20% more space & priority boarding',
      image: `${HOST}/images/space-one.png`,
    },
    'Space Plus Seat': {
      start_row: 2,
      end_row: 4,
      name: 'Space Plus',
      short_description: 'With priority boarding',
      image: `${HOST}/images/space-plus.png`,
    },
    'Space Seat': {
      name: 'Space Seat',
      image: `${HOST}/images/space.png`,
    },
    'Standard Seat': {
      name: 'Standard Seat',
      image: `${HOST}/images/standard.png`,
    },
  },
};

export const categories = handleAction(
  SET_SEAT_CATEGORIES,
  (state, action) => {
    const { payload } = action;
    const { legs = [], colors, seatmapsBySegment } = payload;
    const categories = legs.reduce((prev, current) => {
      const {
        segment_id,
        marketing_airline = '',
        operating_airline = '',
      } = current;
      const airline = operating_airline || marketing_airline;
      const seatmap = seatmapsBySegment[segment_id];
      const { seat_categories = [] } = seatmap;

      const colorCategories = seat_categories.reduce((prev, current) => {
        let image_url = null;
        const categoriesColors = colors[current.rank];
        let name = current.display_name;

        let start_row = current?.start_row;
        let end_row = current?.end_row;
        let short_description = current?.short_description;

        if (airline in SEAT_IMAGES) {
          if (current.display_name in SEAT_IMAGES[airline]) {
            ({
              image: image_url,
              start_row,
              end_row,
              short_description,
              name,
            } = SEAT_IMAGES[airline][current.display_name]);
          }
        }

        prev[current.seat_category_id] = {
          ...current,
          ...categoriesColors,
          image_url,
          start_row,
          end_row,
          name,
          short_description,
        };

        return prev;
      }, {});

      prev[segment_id] = colorCategories;
      return prev;
    }, {});
    return {
      ...state,
      ...categories,
    };
  },
  {}
);
