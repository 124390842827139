const uber = {
  textPrimary: '#000000',
  textSecondary: '#000000',
  title: '#505050',
  primary: '#000000',
  secondary: '#7FD4F1',
  alert: '#DF374B',
  actionButtonBorderRadius: '8px',
  buttonBorderRadius: '8px',
  seats: {
    1: {
      // Standard
      stopColor: '#BBBBBB',
      labelColor: '#000000',
    },
    2: {
      // Preferred
      stopColor: '#A9C9FF',
      labelColor: '#000000',
    },
    3: {
      // Extra Legroom
      stopColor: '#7FD99A',
      labelColor: '#000000',
    },
    4: {
      // Exit Row
      stopColor: '#FFB48C',
      labelColor: '#000000',
    },
    5: {
      // Front Seat
      stopColor: '#DDB9FF',
      labelColor: '#000000',
    },
    not_available: {
      fill: '#F3F3F3',
      strokeColor: '#A6A6A6',
    },
    selected_seat: {
      stopColor: '#000000',
      labelColor: '#FFFFFF',
    },
  },
};

export default uber;
